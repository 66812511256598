import SwipeableViews from "react-swipeable-views"

import { useTheme } from "@mui/material"

interface ResponseViewerTabsProps {
	children?: React.ReactNode
	onViewChange: (index: number) => void
	viewIndex: number
}

export const ResponseViewerSwipeableViews = ({
	children,
	viewIndex,
	onViewChange,
}: ResponseViewerTabsProps) => {
	const theme = useTheme()

	return (
		<SwipeableViews
			axis={theme.direction === "rtl" ? "x-reverse" : "x"}
			index={viewIndex}
			onChangeIndex={onViewChange}
		>
			{children}
		</SwipeableViews>
	)
}
