import { GraphDatasetProps } from "../@types/graphs/dataset"

export const colors = ["#187063", "#1f4077", "#7A7CC3", "#886E8C", "#309587", "#B076BA", "#10444c"]

export const handleDatasetColor = (crrIndex: number) => colors?.[crrIndex % 4]

export const mountGraphOptions = (title?: string) => ({
	maintainAspectRatio: false,
	plugins: {
		legend: {
			display: false,
		},
		title: {
			display: !!title,
			text: title,
		},
		datalabels: {
			display: false,
		},
	},
})

export const mountLineGraphOptions = (title?: string) => ({
	interaction: {
		mode: "index" as const,
		intersect: false,
	},
	plugins: {
		title: {
			display: !!title,
			text: title,
		},
		legend: {
			display: false,
		},
	},
})
// 50% of transparency
const makeColorsTransparent = (colors: string[]) => colors.map((c) => c + "CC")

export const mountDatasetGraph = (data: any, transparent?: boolean) =>
	({
		labels: Object.entries(data).map(([key]) => key),
		datasets: [
			{
				data: Object.entries(data).map(([_, value]) => value),
				backgroundColor: transparent ? makeColorsTransparent(colors) : colors,
				label: "Consultas",
				borderWidth: 2,
			},
		],
	}) as GraphDatasetProps
