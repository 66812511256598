export enum BillingRecorrence {
	MONTHLY = "monthly",
	LOOSE = "loose",
}

export enum ServiceCostRangeModel {
	FIXED = "fixed",
	REQUEST = "request",
}

export enum ServiceBillingType {
	SUCCESS = "success",
	PROCESSING = "processing",
}

export type Organization = {
	name: string
	cnpj: string
	status: string
	imageUrl?: string
	addressId?: string
	billingEmail: string
	billingRecorrence: BillingRecorrence
	contractExpiresAt?: Date | null
	address: Address
	services?: OrganizationService | never[]
}

export type OrganizationServiceCostRange = {
	id?: string
	usageStart: number
	usageEnd: number
	cost: number
}

export type OrganizationService = {
	id?: string
	name: string
	billingType: ServiceBillingType
	costRangeModel: ServiceCostRangeModel
	unlimited: boolean
	usageLimit: number
	costRanges: OrganizationServiceCostRange[]
	successBillingOptions?: any[]
}

type Address = {
	street: string
	neighborhood: string
	city: string
	state: string
	country: string
	number: string
	zipCode: string
	complement: string
}

type BillingTypes = "monthly" | "loose"

export type Usage = {
	usageStart: string
	usageEnd: string
	cost: string
}

export type ProductRangeValue = {
	serviceId: string
	billingType: BillingTypes
	usage: Usage[]
}
