import { z } from "zod"

import { isValidIP, isValidIPRange } from "../../../utils/ip"

export const UnrefinedCreateIPLiberationSchema = z.object({
	organizationId: z.string().uuid({ message: "O ID da organização não é válido" }),
	startIp: z.string().refine((value) => isValidIP(value), {
		message: "O IP não é válido",
	}),
	endIp: z.string().refine((value) => isValidIP(value), {
		message: "O IP não é válido",
	}),
})

export const ipLiberationRefiner = <
	O extends typeof UnrefinedCreateIPLiberationSchema,
	T extends z.ZodType<O, T, I>,
	I,
>(
	schema: typeof UnrefinedCreateIPLiberationSchema,
) => {
	return schema.partial().refine(
		(data) => {
			if (data.startIp && data.endIp) {
				return isValidIPRange(data.startIp, data.endIp)
			}
		},
		{ message: "Deve ser igual ou superior ao incial", path: ["endIp"] },
	)
}

export const CreateIPLiberationSchema = ipLiberationRefiner(UnrefinedCreateIPLiberationSchema)

export type CreateIPLiberationData = z.infer<typeof CreateIPLiberationSchema>
