import { useMemo, useState } from "react"
import { useTranslation } from "react-i18next"

import { InvoicingData } from "../../../../apis/invoicing"
import { Table } from "../../../../components/table/table"
import { DialogIncoingDetails } from "../dialog-edit-invoicing-details/dialog-edit-invoicing-details"
import { tablePrintStyles } from "../invoicing-details.styles"

interface InvoicingMonthlyDetailsProps {
	invoicing: InvoicingData | null
	refreshInvoicingData: () => void
	isPrinting?: boolean
}

export function InvoicingMonthlyDetails({
	invoicing,
	refreshInvoicingData,
	isPrinting,
}: InvoicingMonthlyDetailsProps) {
	const { t } = useTranslation()

	const [paginationQueries, setPaginationQueries] = useState({
		rowsPerPage: 10,
		pageNumber: 1,
	})

	const [invoicingEdit, setinvoicingEdit] = useState<InvoicingData | null>(null)

	const rows = useMemo(() => {
		return invoicing?.monthlyData.services.flatMap((service) => {
			return service.teams.flatMap((team) => {
				return (
					team?.users?.flatMap((user) => {
						return Object.entries(user.byProcessingType).flatMap(([processingType]) => ({
							userName: user.name,
							teamName: team.name,
							userMail: user.mail,
							serviceName: service.name,
							processingType,
							totalUsage: user.total,
							detailCost: +service.pricePerUnit * user.total,
							cost: service.pricePerUnit,
						}))
					}) || []
				)
			})
		})
	}, [invoicing])

	const handleChangeRowsPerPage = (rowsPerPage: number) =>
		setPaginationQueries((prevState) => ({ ...prevState, rowsPerPage }))

	const handleChangePage = (crrPage: number) =>
		setPaginationQueries((prevState) => ({ ...prevState, pageNumber: crrPage++ }))

	const keys = useMemo(
		() => [
			"teamName",
			"userMail",
			"serviceName",
			"processingType",
			"totalUsage",
			{ isMoney: true, value: "cost", id: "cost" },
			{ isMoney: true, value: "detailCost", id: "detailCost" },
		],
		[rows, invoicing],
	)

	const columns = useMemo(
		() => [
			{
				label: t("columns.team"),
				id: "teamName",
			},

			{
				label: t("columns.user"),
				id: "userMail",
			},
			{
				label: t("consumption.invoicing.consumptionDetailsByUser.columns.product"),
				id: "serviceName",
			},
			{
				label: t("consumption.invoicing.consumptionDetailsByUser.columns.typeConsumption"),
				id: "processingType",
			},
			{
				label: t("consumption.invoicing.consumptionDetailsByUser.columns.amountConsumption"),
				id: "totalUsage",
			},
			{
				label: t("consumption.invoicing.consumptionDetailsByUser.columns.cost"),
				id: "cost",
			},
			{
				label: t("consumption.invoicing.consumptionDetailsByUser.columns.total"),
				id: "detailCost",
			},
		],
		[rows, t],
	)

	return (
		<>
			<DialogIncoingDetails
				refreshInvoicingData={refreshInvoicingData}
				setInvoicing={setinvoicingEdit}
				invoicing={invoicingEdit}
			/>
			<Table
				changeRowsPerPage={handleChangeRowsPerPage}
				page={paginationQueries.pageNumber - 1}
				changePage={handleChangePage}
				onDelete={() => null}
				columns={columns}
				keys={keys}
				rows={rows as []}
				onEdit={() => {}}
				isEditVisible={false}
				hidePagination={isPrinting}
				rowsPerPage={isPrinting ? 100 : paginationQueries.rowsPerPage}
				titleStyles={isPrinting ? tablePrintStyles : {}}
				cellStyles={isPrinting ? tablePrintStyles : {}}
			/>
		</>
	)
}
