import axios from "./config/axios"

export const createAdminApi = async (admin: any) => await axios.post("/admins", admin)

export const listAllAdmins = async ({ currentPage, pageSize, filter, active }: any) =>
	await axios.post("/admins/search", { currentPage, pageSize, filter, active })

export const searchSpecificAdmin = async (adminId: any) => await axios.get(`/admins/${adminId}`)

export const updateAdmin = async (adminId: any, adminData: any) =>
	await axios.put(`/admins/${adminId}`, adminData)

export const disableAdmins = async (admins: Array<string>) =>
	await axios.put(`/admins/disable`, { userIds: admins })
