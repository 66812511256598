import axios, { AxiosError, InternalAxiosRequestConfig } from "axios"

import handleError from "../../helpers/error-handler"
import { AuthStorage } from "../../storage/auth-type"
import { getItem } from "../../storage/local-storage"

const axiosInstance = axios.create({
	baseURL: process.env.REACT_APP_SERVICE_URL,
	headers: {
		"Content-Type": "application/json",
	},
})

axiosInstance.interceptors.request.use(
	(config: InternalAxiosRequestConfig) => {
		const userToken = getItem(AuthStorage.userToken)
		if (userToken) {
			config.headers.Authorization = `Bearer ${userToken.access_token}`
		}

		return config
	},
	(error: AxiosError) => {
		return handleError(error)
	},
)

axiosInstance.interceptors.response.use(
	(response) => response,
	(error: AxiosError) => {
		return handleError(error)
	},
)

export default axiosInstance
