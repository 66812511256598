import { useState } from "react"

export const useDialog = () => {
	const [dialog, setDialog] = useState<boolean>(false)
	const [dialogTitle, setDialogTitle] = useState<any>("")
	const [dialogDescription, setDialogDescription] = useState<any>("")

	return {
		dialog,
		setDialog,
		dialogTitle,
		setDialogTitle,
		dialogDescription,
		setDialogDescription,
	}
}
