import styled from "styled-components"

export const Code = styled.div`
	padding: 8px 0;
	box-sizing: border-box;
`

export const ContainerActions = styled.div`
	width: 100%;
	background: #1e1e1e;
	display: flex;
	align-items: center;
	box-sizing: border-box;
	justify-content: space-between;
	padding: 8px 8px;
`
