import { Dispatch, useEffect, useState } from "react"
import { Bar } from "react-chartjs-2"
import { useTranslation } from "react-i18next"

import { CloudDownload, Refresh } from "@mui/icons-material"
import { Grid, IconButton, SelectChangeEvent } from "@mui/material"
import {
	BarElement,
	CategoryScale,
	Chart as ChartJS,
	Legend,
	LineElement,
	LinearScale,
	PointElement,
	RadialLinearScale,
	Title,
	Tooltip,
} from "chart.js"
import ChartDataLabels from "chartjs-plugin-datalabels"
import { enqueueSnackbar } from "notistack"

import {
	MultiSelectData,
	downloadReport,
	searchClassifiedOrganizationConsumption,
	searchDailyOrganizationProductConsumption,
	searchOrganizationEvolutiveClientConsumption,
	searchProcessingTypeConsumption,
	searchRequestsByProduct,
	searchServiceNamesByOrganization,
} from "../../apis/dashboard"
import { listOrganizationsWithClientUsage } from "../../apis/organization"
import { BodyPage, ContainerPage } from "../../components/container/container.styles"
import { HeaderPage } from "../../components/header/page/header-page"
import { HeaderPageTopButton } from "../../components/header/page/header-page-top-button"
import { Loading } from "../../components/loading/loading"
import { SnackBar } from "../../components/snackbar/snackbar"
import { useAuthentication } from "../../hooks/app/useAuthentication"
import {
	FormattedUsageData,
	formatChartDataWithLocaleDates,
	formatClassifiedConsumption,
	formatEvolutiveClientConsumption,
	formatProductConsumptionRelationByClient,
	formatReports,
	getCurrentDate,
	getCurrentLocaleDate,
	openPDF,
} from "../../utils/format"
import { handleApiExceptions } from "../../utils/handle-errors"
import { ConsumptionTable } from "./consumption-table"

ChartJS.register(
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend,
	PointElement,
	LineElement,
	RadialLinearScale,
	ChartDataLabels,
)

type BarData = React.ComponentProps<typeof Bar>["data"]
type BarOptions = React.ComponentProps<typeof Bar>["options"]

export const Consumption = () => {
	const { t } = useTranslation()

	const [loading, setLoading] = useState<boolean>(false)

	const [filter, setFilter] = useState<string>("byTeamServices")
	const [serviceNames, setServiceNames] = useState<MultiSelectData["data"]>([])
	const [selectedServices, setSelectedServices] = useState<string[]>([])

	const [requestsByProductData, setRequestsByProductData] = useState<BarData>()
	const [processingTypeData, setProcessingTypeData] = useState<BarData>()
	const [dailyProductConsumptionData, setDailyProductConsumptionData] = useState<BarData>()
	const [evolutiveClientConsumptionData, setEvolutiveClientConsumptionData] = useState<BarData>()
	const [productConsumptionRelationByClientData, setProductConsumptionRelationByClientData] =
		useState<any>(null)
	const [classifiedOrganizationConsumptionData, setClassifiedOrganizationConsumptionData] =
		useState<BarData>()

	const [date, setDate] = useState<string>(getCurrentDate())
	const [endDate, setEndDate] = useState<string>(getCurrentDate())
	const [localeStartDate, setLocaleStartDate] = useState<string>(getCurrentLocaleDate())
	const [localeEndDate, setLocaleEndDate] = useState<string>(getCurrentLocaleDate())
	const [productsChartOptions, setProductsChartOptions] = useState<BarOptions>()
	const [processingTypeChartOptions, setProcessingTypeChartOptions] = useState<BarOptions>()
	const [dailyProductConsumptionChartOptions, setDailyProductConsumptionChartOptions] =
		useState<BarOptions>()
	const [evolutiveClientConsumptionOptions, setEvolutiveClientConsumptionOptions] =
		useState<BarOptions>()
	const [classifiedOrganizationConsumptionOptions, setClassifiedOrganizationConsumptionOptions] =
		useState<BarOptions>()

	const [organizations, setOrganizations] = useState<MultiSelectData["data"]>([])
	const [selectedOrganizations, setSelectedOrganizations] = useState<string[]>([])

	const [isFilterConsumptionShow, setIsFilterConsumptionShow] = useState<boolean>(true)

	const [productsChartIsVisible, setProductsChartIsVisible] = useState<boolean>(false)
	const [processingTypeChartIsVisible, setProcessingTypeChartIsVisible] = useState<boolean>(false)
	const [dailyProductConsumptionChartIsVisible, setDailyProductConsumptionChartIsVisible] =
		useState<boolean>(false)
	const [evolutiveClientConsumptionChartIsVisible, setEvolutiveClientConsumptionChartIsVisible] =
		useState<boolean>(false)
	const [
		classifiedOrganizationConsumptionChartIsVisible,
		setClassifiedOrganizationConsumptionChartIsVisible,
	] = useState<boolean>(false)

	const [showError, setShowError] = useState<boolean>(false)

	const [invalidEndDate, setInvalidEndDate] = useState<boolean>(false)

	const {
		user: { role, organizationId },
		isAdmin,
	} = useAuthentication()

	useEffect(() => {
		setLoading(true)

		async function fetchRequiredAdminData() {
			setIsFilterConsumptionShow(false)
			await getOrganization()
			const services = await fetchServices()
			if (services) setServiceNames(services)
			await Promise.all([fetchReports(localeStartDate, localeEndDate)])
		}

		async function fetchRequiredUserData() {
			const [services] = await Promise.all([
				fetchServices(),
				fetchReports(localeStartDate, localeEndDate),
			])
			if (services) setServiceNames(services)
		}

		try {
			isAdmin ? fetchRequiredAdminData() : fetchRequiredUserData()
		} catch (error) {}

		setLoading(false)
	}, [role, selectedOrganizations, localeStartDate, localeEndDate, selectedServices])

	const reportStyles = {
		marginBottom: "2rem",
	}

	const buildBarChart =
		(
			title: string,
			dataSetter: Dispatch<any>,
			optionsSetter: Dispatch<BarOptions>,
			labelKey: string,
		) =>
		(labels: string[], data: { usedLimit: number; [k: string]: any }[]) => {
			try {
				const newOptions: BarOptions = {
					plugins: {
						legend: {
							labels: {
								boxWidth: 20,
								padding: 20,
							},
						},
						title: {
							display: true,
							text: title,
						},
					},
					responsive: true,
					scales: {
						x: {
							stacked: true,
							ticks: {
								autoSkip: true,
								maxRotation: 90,
								minRotation: 45,
							},
						},
						y: {
							stacked: true,
							ticks: {
								padding: 10, // Add padding to the y-axis ticks to prevent overflow
								font: {
									size: 10, // Reduce the font size if necessary
								},
							},
						},
					},
					indexAxis: "y",
				}

				const colors = ["#187063", "#309587", "#1f4077", "#10444c"]

				const datasets = labels.map((label, index) => {
					const chartData = data
						.filter((dataPoint) => dataPoint[labelKey] === label)
						.map((dataPoint) => dataPoint.usedLimit)

					return {
						label: label,
						data: chartData,
						fill: false,
						borderColor: colors[index % colors.length],
						backgroundColor: colors[index % colors.length],
						tension: 0.1,
					}
				})

				const finalLabels = data
					.filter(
						(dataPoint, index, self) =>
							index === self.findIndex((t) => t[labelKey] === dataPoint[labelKey]),
					)
					.map((dataPoint) => dataPoint[labelKey])

				const newData: BarData = {
					labels: finalLabels,
					datasets,
				}
				dataSetter(newData)
				optionsSetter(newOptions)
			} catch (error) {}
		}

	const buildChartDailyProductConsumption = (
		serviceNames: string[],
		dailyProductConsumptionData: FormattedUsageData,
	) => {
		const newOptions: BarOptions = {
			responsive: true,
			interaction: {
				mode: "index",
				intersect: false,
			},
			plugins: {
				title: {
					display: true,
					text: "Consumo diário por produto",
				},
			},
			scales: {
				x: {
					stacked: true,
				},
				y: {
					stacked: true,
				},
			},
		}
		const colors = ["#187063", "#309587", "#1f4077", "#10444c"]

		const datasets = serviceNames.map((serviceName, index) => {
			const data = dailyProductConsumptionData
				.filter((dataPoint) => dataPoint.serviceName === serviceName)
				.map((dataPoint) => dataPoint.value[serviceName])

			return {
				label: serviceName,
				data,
				fill: false,
				borderColor: colors[index % colors.length],
				backgroundColor: colors[index % colors.length],
				tension: 0.1,
			}
		})

		const labels = dailyProductConsumptionData
			.filter(
				(dataPoint, index, self) => index === self.findIndex((t) => t.date === dataPoint.date),
			)
			.map((dataPoint) => dataPoint.date)

		const newData = {
			labels,
			datasets,
		}

		setDailyProductConsumptionChartOptions(newOptions)
		setDailyProductConsumptionData(newData)
		setDailyProductConsumptionChartIsVisible(true)
	}

	const buildChartEvolutiveClientConsumption = (
		clientNames: string[],
		evolutiveClientConsumptionData: ReturnType<typeof formatEvolutiveClientConsumption>["data"],
	) => {
		const newOptions: BarOptions = {
			responsive: true,
			interaction: {
				mode: "index",
				intersect: false,
			},
			plugins: {
				title: {
					display: true,
					text: "Evolutivo de consumo mensal",
				},
			},
			scales: {
				x: {
					stacked: true,
				},
				y: {
					stacked: true,
				},
			},
		}
		const colors = ["#187063", "#309587", "#1f4077", "#10444c"]

		const datasets = clientNames.map((clientName, index) => {
			const data = evolutiveClientConsumptionData
				.filter((dataPoint) => dataPoint.clientName === clientName)
				.flatMap((dataPoint) => Object.values(dataPoint.services).map((el) => el.usageLimit))

			return {
				label: clientName,
				data,
				fill: false,
				borderColor: colors[index % colors.length],
				backgroundColor: colors[index % colors.length],
				tension: 0.1,
			}
		})

		const labels = evolutiveClientConsumptionData
			.filter(
				(dataPoint, index, self) => index === self.findIndex((t) => t.date === dataPoint.date),
			)
			.map((dataPoint) => dataPoint.date)

		const newData: BarData = {
			labels,
			datasets,
		}

		setEvolutiveClientConsumptionOptions(newOptions)
		setEvolutiveClientConsumptionData(newData)
		setEvolutiveClientConsumptionChartIsVisible(true)
	}

	const buildClassifiedConsumptionChart = (
		serviceClassifications: string[],
		classifiedConsumptionData: ReturnType<typeof formatClassifiedConsumption>["data"],
	) => {
		const newOptions: BarOptions = {
			responsive: true,
			interaction: {
				mode: "index",
				intersect: false,
			},
			plugins: {
				title: {
					display: true,
					text: "Consumo por classificação do serviço",
				},
			},
			scales: {
				x: {
					stacked: true,
				},
				y: {
					stacked: true,
				},
			},
		}
		const colors = ["#187063", "#309587", "#1f4077", "#10444c"]

		const datasets = serviceClassifications.map((serviceClassification, index) => {
			const data = classifiedConsumptionData
				.filter((dataPoint) => dataPoint.classification === serviceClassification)
				.map((dataPoint) => Number(dataPoint.usedLimit))

			return {
				label: serviceClassification,
				data,
				fill: false,
				borderColor: colors[index % colors.length],
				backgroundColor: colors[index % colors.length],
				tension: 0.1,
			}
		})
		const labels = classifiedConsumptionData
			.map((dataPoint) => dataPoint.serviceName)
			.filter((value, index, self) => self.indexOf(value) === index)

		const newData = {
			labels,
			datasets,
		}

		setClassifiedOrganizationConsumptionData(newData)
		setClassifiedOrganizationConsumptionOptions(newOptions)
		setClassifiedOrganizationConsumptionChartIsVisible(true)
	}

	const fetchRequestsByProductData = async (
		organizationIds: string[],
		startDate: string = new Date().toISOString(),
		endDate: string = new Date().toISOString(),
		services: (string | undefined)[],
	) => {
		return searchRequestsByProduct(organizationIds, startDate, endDate, services)
	}

	const fetchProcessingTypeConsumptionData = async (
		organizationIds: string[],
		startDate: string = new Date().toISOString(),
		endDate: string = new Date().toISOString(),
		services: (string | undefined)[],
	) => {
		return searchProcessingTypeConsumption(organizationIds, startDate, endDate, services)
	}

	const fetchDailyProductConsumption = async (
		organizationIds: string[],
		startDate: string = new Date().toISOString(),
		endDate: string = new Date().toISOString(),
		services: (string | undefined)[],
	) => searchDailyOrganizationProductConsumption(organizationIds, startDate, endDate, services)

	const fetchEvolutiveClientConsumption = async (
		organizationIds: string[],
		year: string = new Date().getFullYear().toString(),
		yearEnd: string = (new Date().getFullYear() + 1).toString(),
		services: (string | undefined)[],
	) => {
		return searchOrganizationEvolutiveClientConsumption(organizationIds, year, yearEnd, services)
	}

	const fetchClassifiedOrganizationConsumption = async (
		organizationIds: string[],
		startDate: string = new Date().toISOString(),
		endDate: string = new Date().toISOString(),
		services: (string | undefined)[],
	) => {
		return searchClassifiedOrganizationConsumption(organizationIds, startDate, endDate, services)
	}

	const filterChange = (e: any) => {
		setFilter(e.target.value)
	}

	const handleDate = (e: any) => {
		setInvalidEndDate(false)
		const newDate = new Date(e)
		const localeEndDateState = new Date(localeEndDate)

		const localeUsDate = newDate.toLocaleDateString("en-US", {
			month: "2-digit",
			day: "2-digit",
			year: "numeric",
		})

		if (
			!newDate.getDate().toString()?.length ||
			!newDate.getMonth().toString()?.length ||
			!newDate.getFullYear().toString()?.length
		)
			return

		if (new Date(localeUsDate) <= localeEndDateState) {
			const month = newDate.getMonth() + 1
			const year = newDate.getFullYear()

			const dateFormat = month + "-" + year

			setDate(dateFormat)
			setLocaleStartDate(localeUsDate)
			fetchReports(localeUsDate, localeEndDate)
		} else {
			setInvalidEndDate(true)
		}
	}

	const handleEndDate = (e: any) => {
		setInvalidEndDate(false)
		const newDate = new Date(e)
		const localeStartDateState = new Date(localeStartDate)

		const localeUsDate = newDate.toLocaleDateString("en-US", {
			month: "2-digit",
			day: "2-digit",
			year: "numeric",
		})
		if (
			!newDate.getDate().toString()?.length ||
			!newDate.getMonth().toString()?.length ||
			!newDate.getFullYear().toString()?.length
		)
			return

		if (new Date(localeUsDate) >= localeStartDateState) {
			const month = newDate.getMonth() + 1
			const year = newDate.getFullYear()

			const dateFormat = month + "-" + year

			setEndDate(dateFormat)
			setLocaleEndDate(localeUsDate)
			fetchReports(localeStartDateState.toISOString(), localeUsDate)
		} else {
			setInvalidEndDate(true)
		}
	}

	const fetchReports = async (startDate: string, endDate: string) => {
		if (!selectedOrganizations.length && !organizationId) return
		const orgs = isAdmin ? [...selectedOrganizations, organizationId] : [organizationId]

		const organizationsToFetch = orgs
			.map((organization) => {
				const foundOrganization = organizations.find(({ name }) => name === organization)
				return foundOrganization?.id
			})
			.filter(Boolean) as string[]

		const servicesToFetch = selectedServices
			.map((service) => {
				const foundService = serviceNames.find(({ name }) => name === service)
				return foundService?.id
			})
			.filter(Boolean)

		const currentOrganizations = organizationsToFetch?.length ? organizationsToFetch : orgs

		const reportFetchers = [
			fetchRequestsByProductData(currentOrganizations, startDate, endDate, servicesToFetch),
			fetchProcessingTypeConsumptionData(currentOrganizations, startDate, endDate, servicesToFetch),
			fetchDailyProductConsumption(currentOrganizations, startDate, endDate, servicesToFetch),
			fetchEvolutiveClientConsumption(
				currentOrganizations,
				new Date(startDate).getFullYear().toString(),
				new Date(endDate).getFullYear().toString(),
				servicesToFetch,
			),
		]

		if (servicesToFetch?.length) {
			reportFetchers.push(
				fetchClassifiedOrganizationConsumption(
					currentOrganizations,
					startDate,
					endDate,
					servicesToFetch,
				),
			)
		}

		const [
			requestsByProduct,
			processingTypeConsumption,
			dailyProductConsumption,
			evolutiveClientConsumption,
			classifiedOrganizationConsumption,
		] = await Promise.all(reportFetchers)

		try {
			const responses = [
				{
					response: requestsByProduct.data.data,
					chartSetter: setProductsChartIsVisible,
					chartBuilder: buildBarChart(
						"Qtde de consultas por Produto",
						setRequestsByProductData,
						setProductsChartOptions,
						"serviceName",
					),
					formatter: formatReports("serviceName"),
				},
				{
					response: processingTypeConsumption.data.data,
					chartSetter: setProcessingTypeChartIsVisible,
					chartBuilder: buildBarChart(
						"Qtde de consultas por Tipo de Processamento",
						setProcessingTypeData,
						setProcessingTypeChartOptions,
						"tipoProcessamento",
					),
					formatter: formatReports("tipoProcessamento"),
				},
				{
					response: dailyProductConsumption.data.data,
					chartSetter: setDailyProductConsumptionChartIsVisible,
					chartBuilder: buildChartDailyProductConsumption,
					formatter: formatChartDataWithLocaleDates,
				},
				{
					response: evolutiveClientConsumption.data.data,
					chartSetter: setEvolutiveClientConsumptionChartIsVisible,
					chartBuilder: buildChartEvolutiveClientConsumption,
					formatter: formatEvolutiveClientConsumption,
				},
				{
					response: classifiedOrganizationConsumption.data.data,
					chartSetter: setClassifiedOrganizationConsumptionChartIsVisible,
					chartBuilder: buildClassifiedConsumptionChart,
					formatter: formatClassifiedConsumption,
				},
			]

			responses.forEach(({ response, chartSetter, chartBuilder, formatter }) => {
				if (response?.length) {
					const { labels, data } = formatter(response)
					chartBuilder(labels, data)
					chartSetter(true)
				} else {
					chartSetter(false)
				}
			})

			if (evolutiveClientConsumption.data.data?.length) {
				const formattedProductConsumptionRelationByClient =
					formatProductConsumptionRelationByClient(evolutiveClientConsumption.data.data)
				setProductConsumptionRelationByClientData(formattedProductConsumptionRelationByClient)
			}
		} catch (error) {}
	}

	const fetchServices = async () => {
		try {
			const orgs = []

			if (isAdmin) orgs.push(...selectedOrganizations)
			if (organizationId) orgs.push(organizationId)

			if (!orgs.length) return []

			const organizationsToFetch = orgs
				.map((organization) => {
					const foundOrganization = organizations.find(({ name }) => name === organization)
					return foundOrganization?.id
				})
				.filter(Boolean) as string[]
			const {
				data: { data: services },
			} = await searchServiceNamesByOrganization(
				organizationsToFetch?.length ? organizationsToFetch : orgs,
			)

			return services
		} catch (error) {
			handleApiExceptions(error)
		}
	}

	const onRefreshPage = () => {
		setLoading(true)
		fetchReports(localeStartDate, localeEndDate)
		setLoading(false)
	}

	const getOrganization = async () => {
		try {
			const {
				data: { data },
			} = await listOrganizationsWithClientUsage()
			setOrganizations(data)

			if (data.length) {
				fetchReports(localeStartDate, localeEndDate)
			} else {
				enqueueSnackbar("Nenhuma organização com consumo para as datas escolhidas", {
					variant: "warning",
					preventDuplicate: true,
				})
			}
		} catch (error) {}
	}

	const onChangeMultiselectOrganization = (e: SelectChangeEvent) => {
		const values = e.target.value as unknown as string[]
		const option = organizations.find((organization) => {
			const found = values.find((value) => {
				return value === organization.name
			})
			return found
		})

		if (!option) return

		setSelectedOrganizations(e.target.value as unknown as string[])
	}

	const downloadExportDocument = async () => {
		setLoading(true)

		try {
			const organizationsToFetch = selectedOrganizations
				.map((organization) => {
					const foundOrganization = organizations.find(({ name }) => name === organization)
					return foundOrganization?.id
				})
				.filter(Boolean)

			const servicesToFetch = selectedServices
				.map((service) => {
					const foundService = serviceNames.find(({ name }) => name === service)
					return foundService?.id
				})
				.filter(Boolean)

			if (!organizationsToFetch?.length || !servicesToFetch?.length) {
				setShowError(true)
			}

			const { data } = await downloadReport(
				organizationsToFetch as string[],
				date,
				localeStartDate,
				localeEndDate,
				servicesToFetch,
			)

			openPDF(data, t("consumption.popup_window_alert"))
		} catch (error) {
			console.error("🚀 ~ downloadExportDocument ~ error:", error)
		} finally {
			setLoading(false)
		}
	}

	const onChangeMultiselect = (e: SelectChangeEvent) => {
		setSelectedServices(e.target.value as unknown as string[])
	}

	const onDeleteMultiselect = (value: any) => {
		const newSelectedServices = selectedServices.filter((item) => item !== value)
		setSelectedServices(newSelectedServices)
		if (!newSelectedServices?.length) {
			setClassifiedOrganizationConsumptionChartIsVisible(false)
		}
	}

	const onDeleteMultiselectOrganization = (value: any) => {
		const newSelectedOrganizations =
			selectedOrganizations?.filter((item: any) => item !== value) || []
		setSelectedOrganizations(newSelectedOrganizations)
		if (!newSelectedOrganizations?.length) {
			setClassifiedOrganizationConsumptionChartIsVisible(false)
		}
	}

	if (loading) return <Loading />

	const isAvaiable = [
		productsChartIsVisible,
		processingTypeChartIsVisible,
		dailyProductConsumptionChartIsVisible,
		evolutiveClientConsumptionChartIsVisible,
		classifiedOrganizationConsumptionChartIsVisible,
	]

	return (
		<ContainerPage
			style={{
				overflow: "auto",
			}}
		>
			<div>
				<div>
					<HeaderPage
						title={t("consumption.title.dashboard")}
						isChartVisible={true}
						onChangeDate={handleDate}
						onChangeFilterConsumption={(e) => filterChange(e)}
						valueFilterConsumption={filter}
						hasFirstLineMultiselect={true}
						firstLineMultiselectItems={organizations}
						onFirstLineMultiSelectChange={onChangeMultiselectOrganization}
						onDeleteFirstLineMultiselect={onDeleteMultiselectOrganization}
						firstLineMultiSelectTitle="Organizações"
						selectedFirstLineItems={selectedOrganizations}
						isFilterConsumptionShow={isFilterConsumptionShow}
						hasEndDate
						onChangeEndDate={handleEndDate}
						hasMultiselect
						multiSelectData={serviceNames}
						onChangeMultiselect={onChangeMultiselect}
						onDeleteMultiselect={onDeleteMultiselect}
						selectedNames={selectedServices}
						multiSelectTitle={t("consumption.multiSelectTitle") as string}
						customTitleStyles={{ marginBottom: "2rem" }}
						showDownloadButton
						topButton={
							<div>
								<IconButton
									onClick={onRefreshPage}
									color="secondary"
									style={{ marginRight: "1rem" }}
								>
									<Refresh />
								</IconButton>
								<HeaderPageTopButton onClick={downloadExportDocument} icon={<CloudDownload />}>
									{t("headerPage.export_as_file")}
								</HeaderPageTopButton>
							</div>
						}
					/>
				</div>
				<BodyPage style={{ width: "100%", overflow: "hidden" }}>
					{isAvaiable.some(Boolean) ? (
						<>
							<Grid
								container
								paddingTop={"1rem"}
								rowSpacing={1}
								columnSpacing={{ xs: 3, sm: 3, md: 4 }}
							>
								{productsChartIsVisible && requestsByProductData && (
									<Grid item xs={5}>
										<Bar
											style={reportStyles}
											options={productsChartOptions}
											data={requestsByProductData}
										/>
									</Grid>
								)}

								{processingTypeChartIsVisible && processingTypeData && (
									<Grid item xs={5}>
										<Bar
											style={reportStyles}
											options={processingTypeChartOptions}
											data={processingTypeData}
										/>
									</Grid>
								)}
								{dailyProductConsumptionChartIsVisible && dailyProductConsumptionData && (
									<Grid
										item
										xs={10}
										sx={{
											height: "30rem",
											display: "flex",
											alignItems: "center",
											justifyContent: "center",
										}}
									>
										<Bar
											style={reportStyles}
											datasetIdKey="serviceName"
											options={dailyProductConsumptionChartOptions}
											data={dailyProductConsumptionData}
										/>
									</Grid>
								)}
								{evolutiveClientConsumptionChartIsVisible && evolutiveClientConsumptionData && (
									<Grid item xs={5}>
										<Bar
											style={reportStyles}
											datasetIdKey="serviceName"
											options={evolutiveClientConsumptionOptions}
											data={evolutiveClientConsumptionData}
										/>
									</Grid>
								)}
								{classifiedOrganizationConsumptionChartIsVisible &&
									classifiedOrganizationConsumptionData && (
										<Grid item xs={5}>
											<Bar
												style={reportStyles}
												datasetIdKey="serviceName"
												options={classifiedOrganizationConsumptionOptions}
												data={classifiedOrganizationConsumptionData}
											/>
										</Grid>
									)}
							</Grid>

							{evolutiveClientConsumptionChartIsVisible && (
								<ConsumptionTable
									data={productConsumptionRelationByClientData}
									title="Relação de consumo dos produtos por usuário"
								/>
							)}
						</>
					) : null}
				</BodyPage>
				{invalidEndDate && (
					<SnackBar
						open={invalidEndDate}
						type={"error"}
						message={"End date must be greater than or equal start date"}
						handleClose={() => setInvalidEndDate(false)}
						style={{
							paddingBottom: "5rem",
							paddingRight: "2rem",
						}}
					/>
				)}
				{showError && (
					<SnackBar
						open={showError}
						type={"error"}
						message={"Informe ambos serviços e organizações para gerar o PDF"}
						handleClose={() => setShowError(false)}
						style={{
							paddingBottom: "5rem",
							paddingRight: "2rem",
						}}
					/>
				)}
			</div>
		</ContainerPage>
	)
}
