import { ReactNode } from "react"

import CloseIcon from "@mui/icons-material/Close"
import { DialogContent, DialogTitle, IconButton } from "@mui/material"

import { Dialog } from "./modal.styles"

export type ModalProps = {
	onClose: () => any
	children?: ReactNode
	title: string
}

export const Modal = ({ onClose, children, title }: ModalProps) => {
	return (
		<Dialog
			sx={{
				padding: 2,
				width: "100%",
			}}
			open
			onClose={onClose}
		>
			<DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
				{title}
			</DialogTitle>
			<IconButton
				aria-label="close"
				onClick={onClose}
				sx={{
					position: "absolute",
					right: 8,
					top: 8,
					color: (theme) => theme.palette.grey[500],
				}}
			>
				<CloseIcon />
			</IconButton>

			<DialogContent dividers>{children}</DialogContent>
		</Dialog>
	)
}
