import { useState } from "react"
import { useFieldArray, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"

import { zodResolver } from "@hookform/resolvers/zod"

import { createAPIKey, findApiKey, resetApiKey, updateAPIKey } from "../../apis/apiKey"
import { ApiKeyData, apiKeySchema } from "../../libs/zod/api-key"
import { ServiceData } from "../../libs/zod/shared"
import { pathNames } from "../../routes/paths"
import { createErrorMessage } from "../../utils/format"

export const useAPIKey = () => {
	const [active, setActive] = useState<boolean>(true)
	const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false)
	const [loading, setLoading] = useState<boolean>(false)
	const [snackbar, setSnackbar] = useState<boolean>(false)
	const [snackbarMessage, setSnackbarMessage] = useState<any>()
	const [snackbarType, setSnackbarType] = useState<"success" | "error">("success")
	const [dialog, setDialog] = useState<boolean>(false)
	const [dialogTitle, setDialogTitle] = useState<any>("")
	const [dialogDescription, setDialogDescription] = useState<any>("")

	const [isError, setIsError] = useState<boolean>(false)
	const [dialogApiKey, setDialogApiKey] = useState<boolean>(false)
	const [showDialogHistoric, setShowDialogHistoric] = useState<boolean>(false)
	const [typeValue, setTypeValue] = useState<"trial" | "client">("trial")
	const { t } = useTranslation()
	const navigate = useNavigate()

	const methods = useForm<ApiKeyData>({
		resolver: zodResolver(apiKeySchema),
	})
	const {
		fields: products,
		append,
		remove,
		update,
	} = useFieldArray({
		control: methods.control,
		name: "services",
	})

	const fetchApiKey = async (apiKeyId: string) => {
		try {
			const {
				data: { data },
			} = await findApiKey(apiKeyId)
			methods.reset(data)
		} catch (error) {
			console.error(error)
		}
	}

	const handleResetApiKey = async () => {
		const formValues = methods.getValues()
		if (!formValues?.key) return
		try {
			const {
				data: { data },
			} = await resetApiKey(formValues?.key)
			methods.reset({ ...formValues, key: data?.key })
			setSnackbarMessage(t("profile.dialog_reset_success"))
			setSnackbarType("success")
		} catch (error) {
			setSnackbarMessage(t("request_messages.500"))
			setSnackbarType("error")
		} finally {
			setSnackbar(true)
		}
	}

	const onUpdateApiKey = async (data: ApiKeyData) => {
		try {
			await updateAPIKey(data.id, data)
			setDialogTitle(t("request_messages.edit_success_title", { name: "Chave de API" }))
			setDialogDescription(t("request_messages.edit_success_description", { name: "Chave de API" }))
		} catch (error: any) {
			setIsError(true)
			setDialogTitle(t("request_messages.500"))
			if (error?.response?.data?.messages) {
				setDialogDescription(createErrorMessage(error?.response?.data?.messages))
			} else {
				setDialogDescription(t("request_messages.400"))
			}
		} finally {
			setDialog(true)
			setLoading(false)
		}
	}

	const onCreateApiKey = async (data: ApiKeyData) => {
		setLoading(true)
		setIsError(false)
		try {
			await createAPIKey(data)

			setDialogTitle(t("request_messages.create_success_title", { name: "Chave de API" }))
			setDialogDescription(t("request_messages.success_description", { name: "Chave de API" }))
		} catch (error: any) {
			setIsError(true)
			setDialogTitle(t("request_messages.500"))
			if (error?.response?.data?.messages) {
				setDialogDescription(createErrorMessage(error?.response?.data?.messages))
			} else {
				setDialogDescription(t("request_messages.400"))
			}
		} finally {
			setDialog(true)
			setLoading(false)
		}
	}

	const handleAddProduct = (service: ServiceData) => {
		append(service)
	}

	const handleDeleteProduct = (service: ServiceData) => {
		const index = products.findIndex((item) => item.serviceId === service.serviceId)
		update(index, { ...service, deleted: true })
	}

	const handleUpdateValue = (services: ServiceData[]) => {
		methods.setValue("services", services)
	}

	const handleAccept = () => {
		setDialog(false)
		if (!isError) {
			navigate(pathNames.apiKey.list)
		}
	}

	return {
		methods,
		loading,
		dialog,
		isError,
		snackbar,
		dialogApiKey,
		dialogTitle,
		dialogDescription,
		snackbarMessage,
		snackbarType,
		showDialogHistoric,
		showDeleteDialog,
		active,
		typeValue,
		products,
		setDialogTitle,
		setDialogDescription,
		setTypeValue,
		setActive,
		setShowDeleteDialog,
		setShowDialogHistoric,
		setIsError,
		setDialog,
		setLoading,
		fetchApiKey,
		handleResetApiKey,
		setDialogApiKey,
		setSnackbar,
		t,
		handleUpdateOrganization: methods.handleSubmit(onUpdateApiKey),
		handleCreateOrganization: methods.handleSubmit(onCreateApiKey),
		handleAddProduct,
		handleDeleteProduct,
		handleUpdateValue,
		handleAccept,
		navigate,
	}
}
