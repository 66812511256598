import { z } from "zod"

import { UnrefinedCreateIPLiberationSchema, ipLiberationRefiner } from "./create-ip-liberation"

export const UnrefinedEditIPLiberationSchema = z
	.object({
		active: z.boolean(),
		id: z.string().uuid(),
	})
	.merge(UnrefinedCreateIPLiberationSchema)

export const EditIPLiberationSchema = ipLiberationRefiner(UnrefinedEditIPLiberationSchema)

export type EditIPLiberationData = z.infer<
	typeof EditIPLiberationSchema & typeof UnrefinedEditIPLiberationSchema
>
