import { useContext } from "react"
import { useTranslation } from "react-i18next"

import { GlobalContext } from "../../../contexts/global-provider"
import { ACTIONS_TYPES } from "../../../contexts/reducer"
import countries from "../../../data/countries.json"
import { Country } from "../../../models/Country"
import { Select } from "./select-country.styles"

export const SelectCountry = () => {
	const { dispatch } = useContext(GlobalContext)

	const { i18n } = useTranslation()

	const changeLanguage = (lng: any) => {
		i18n.changeLanguage(lng)
		dispatch({ type: ACTIONS_TYPES.SET_LANGUAGE, value: lng })
	}

	const handleChange = (e: any) => {
		const codeLanguage = e.target.value
		changeLanguage(codeLanguage)
	}

	return (
		<Select onChange={handleChange}>
			{countries.map((country: Country) => (
				<option key={country.code} value={country.code}>
					{country.symbol}
				</option>
			))}
		</Select>
	)
}
