import { useFieldArray, useFormContext } from "react-hook-form"
import { useTranslation } from "react-i18next"

import { Grid, Typography } from "@mui/material"

import { ContainerAddiction } from "../../../components/batch-fields/batch-fields.styles"

export const SuccessBillingOptionsProps = {}

export const SuccessBillingOptions = () => {
	const { t } = useTranslation()
	const { control } = useFormContext<any>()
	const { fields } = useFieldArray({
		control,
		name: "successBillingOptions",
		keyName: "key",
	})

	return (
		<>
			{fields.length > 0 && (
				<>
					<ContainerAddiction>
						<Grid margin={"0 0 8px 0"}>
							<Typography color={"secondary"} variant={"h6"}>
								{t("service.success_billing_options")}
								<span style={{ fontSize: "13px", opacity: "0.75" }}> ({fields.length})</span>
							</Typography>
							<Typography color="textPrimary" variant={"body2"}>
								{t("service.success_billing_options_description")}
							</Typography>
						</Grid>
					</ContainerAddiction>
					<ContainerAddiction>
						<ul
							style={{
								listStyleType: "none",
								margin: "10px 0 0 0",
								padding: 0,
							}}
						>
							{fields.map((field: any, index: number) => (
								<li
									key={field.key}
									style={{ display: "flex", alignItems: "center", marginBottom: "8px" }}
								>
									<div
										style={{
											borderRadius: "50%",
											padding: "4px",
											marginRight: "8px",
											display: "flex",
											width: "16px",
											height: "16px",
											border: "3px solid #e9e9e9",
											color: "#a1a1a1",
											justifyContent: "center",
											alignItems: "center",
											fontSize: "12px",
										}}
									>
										{index + 1}
									</div>
									<Typography color="textPrimary" fontSize={14}>
										{field.title}
									</Typography>
								</li>
							))}
						</ul>
					</ContainerAddiction>
				</>
			)}
		</>
	)
}
