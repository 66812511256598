export default (state: any, action: any) => {
	switch (action.type) {
		case ACTIONS_TYPES.SET_USER_AUTH:
			return {
				...state,
				userAuth: action.value,
			}

		case ACTIONS_TYPES.SET_MODE:
			return {
				...state,
				light: action.value,
			}

		case ACTIONS_TYPES.SET_HOME_PRODUCT_SHOW:
			return {
				...state,
				productIdShow: action.value,
			}

		case ACTIONS_TYPES.SET_LANGUAGE:
			return {
				...state,
				language: action.value,
			}

		default:
			return state
	}
}

export enum ACTIONS_TYPES {
	SET_USER_AUTH = "SET_USER_AUTH",
	SET_MODE = "SET_MODE",
	SET_HOME_PRODUCT_SHOW = "SET_HOME_PRODUCT_SHOW",
	SET_LANGUAGE = "SET_LANGUAGE",
}
