import { Typography } from "@mui/material"
import styled from "styled-components"

export const Header = styled.div`
	width: 100%;
	margin-bottom: 16px;
`

export const Content = styled.div`
	display: flex;
	justify-content: space-between;
`

export const Title = styled(Typography)`
	font-weight: 900;
`

export const Description = styled(Typography)`
	margin-top: 16px !important;
`
