import { Fragment, useContext, useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"

import AddIcon from "@mui/icons-material/Add"
import DeleteIcon from "@mui/icons-material/Delete"
import HistoryIcon from "@mui/icons-material/History"
import {
	Autocomplete,
	Button,
	Checkbox,
	Chip,
	FormControl,
	FormControlLabel,
	FormGroup,
	ListItem,
	ListItemText,
	TextField,
	Typography,
} from "@mui/material"

import { listProductsWithLimit } from "../../apis/products"
import { listServicesToSelect } from "../../apis/services"
import { GlobalContext } from "../../contexts/global-provider"
import { OrganizationService } from "../../models/Organization"
import { SnackBar } from "../snackbar/snackbar"
import {
	Container,
	ContainerAddiction,
	ContainerList,
	Grid,
	Link,
	RoundDelete,
} from "./product-select.styles"

type ProductSelectProps = {
	dataProducts?: Array<any>
	handleAddProduct: (product: any) => void
	handleDeleteProduct: (product: any) => void
	isHistoricVisible?: boolean
	onClickHistoric?: () => void
	organizationId?: string
	readonly?: boolean
	handleUpdateValue: (data: any) => void
	isEditableUnlimited?: boolean
	isPriceVisible?: boolean
	isButtonEditValues?: boolean
	onClickEditValues?: (service: OrganizationService, index: number) => void
	errors?: any
}

export const ProductSelect = ({
	dataProducts = [],
	handleUpdateValue,
	handleAddProduct,
	handleDeleteProduct,
	isHistoricVisible,
	onClickHistoric,
	organizationId,
	readonly = false,
	isEditableUnlimited = true,
	isPriceVisible = false,
	isButtonEditValues = false,
	onClickEditValues,
	errors,
}: ProductSelectProps) => {
	const { t } = useTranslation()

	const { light } = useContext(GlobalContext)

	const [allProducts, setAllProducts] = useState<any[]>([])
	const [clickProduct, setClickProduct] = useState<any>()

	const [productExists, setProductExists] = useState<boolean>(false)

	useEffect(() => {
		if (organizationId) getProductLimit()
		else getProducts()
	}, [organizationId])

	const getProductLimit = async () => {
		try {
			const {
				data: { data },
			} = await listProductsWithLimit(organizationId)
			setAllProducts(data)
		} catch (error) {
			console.log(error)
		}
	}

	const handleDeleteFetch = (element: any) => {
		handleDeleteProduct(element)
		if (organizationId) getProductLimit()
	}

	const getProducts = async () => {
		try {
			const {
				data: { data },
			} = await listServicesToSelect()
			setAllProducts(data)
		} catch (error) {
		} finally {
		}
	}

	const addProduct = () => {
		setProductExists(false)

		const verifyProduct = dataProducts.find(
			(o: any) => o.id === clickProduct || o.serviceId === clickProduct,
		)

		if (verifyProduct) {
			setProductExists(true)
			return
		}

		if (!clickProduct) return

		const productName = allProducts.find(
			(o: any) => o.id === clickProduct || o.serviceId === clickProduct,
		)
		const productObject = {
			usageLimit: 0,
			serviceId: clickProduct,
			name: productName?.name,
			costRangeModel: "request",
			billingType: "request",
			unlimited: false,
			costRanges: [
				{
					usageStart: 0,
					usageEnd: 0,
					cost: "0",
				},
			],
		}

		handleAddProduct(productObject)
	}

	const handleOnChangeSelect = (e: any, option: any) => {
		setClickProduct(option?.id)
	}

	const onChangeValueTable = (event: any, id: string) => {
		const updatedInputValues = dataProducts.map((prt: any) => {
			if (prt.serviceId === id) {
				return { ...prt, usageLimit: Number(event.target.value) } // type Costs
			}
			return prt
		})

		handleUpdateValue(updatedInputValues)
	}

	const onChangeUnlimitedValue = (event: any, id: string) => {
		const updatedInputValues = dataProducts.map((prt: any) => {
			if (prt.serviceId === id) {
				return { ...prt, unlimited: event.target.checked }
			}
			return prt
		})

		handleUpdateValue(updatedInputValues)
	}

	const addProductWOrganization = () => {
		setProductExists(false)

		const verifyProduct = dataProducts.find((o: any) => o.serviceId === clickProduct)

		if (verifyProduct) {
			setProductExists(true)
			return
		}

		if (!clickProduct) return

		const productName = allProducts.find(
			(o: any) => o.serviceId === clickProduct || o.id === clickProduct,
		)

		const productObject = {
			serviceId: clickProduct,
			usageLimit: 0,
			freeUsageLimit: productName?.freeUsageLimit,
			name: productName?.name,
		}

		handleAddProduct(productObject)
	}

	const servicesUnlimited = useMemo(() => {
		return allProducts.reduce((acc, product) => {
			if (product?.unlimited) {
				acc[product.id] = true
			}
			return acc
		}, {})
	}, [allProducts])

	return (
		<Container>
			<ContainerAddiction>
				<Grid margin={"0 0 8px 0"}>
					<Typography color={"secondary"} variant={"h6"}>
						{t("product_select.title")}
					</Typography>
					{!readonly && (
						<Typography color="textPrimary" variant={"body2"}>
							{t("product_select.description")}
						</Typography>
					)}
				</Grid>
				{!readonly && (
					<Grid direction={"row"}>
						<Grid flex={1.5}>
							<FormControl
								style={styles.formControl}
								variant="filled"
								sx={{ m: 1, flexDirection: "column" }}
							>
								{!organizationId ? (
									<Autocomplete
										disablePortal
										size={"small"}
										options={allProducts}
										getOptionLabel={(option: any) => option.name || ""}
										onChange={handleOnChangeSelect}
										renderInput={(params) => (
											<TextField
												{...params}
												variant={"filled"}
												color={"secondary"}
												label={t("product_select.products")}
											/>
										)}
									/>
								) : (
									<Autocomplete
										disablePortal
										size={"small"}
										value={""}
										options={allProducts}
										getOptionLabel={(option: any) => option.name || ""}
										onChange={handleOnChangeSelect}
										renderInput={(params) => (
											<TextField
												{...params}
												variant={"filled"}
												color={"secondary"}
												label={t("product_select.products")}
											/>
										)}
									/>
								)}
							</FormControl>
						</Grid>
						<Grid flex={1}>
							<Button
								disabled={!clickProduct}
								color={"secondary"}
								onClick={!organizationId ? addProduct : addProductWOrganization}
								style={styles.button}
								variant={"outlined"}
							>
								<AddIcon style={{ marginRight: 8 }} />
								{t("product_select.add_product")}
							</Button>
						</Grid>
					</Grid>
				)}
			</ContainerAddiction>
			<ContainerList>
				{dataProducts
					.filter((element) => !element?.deleted)
					.map((element: any, index: number) => (
						<Fragment key={index}>
							<ListItem
								style={{ borderBottom: "1px solid #9393935a", width: "100%" }}
								component="div"
								disablePadding
							>
								<ListItemText
									style={{ width: "70%", color: light ? "black" : "white" }}
									primary={element?.name}
								/>
								<FormGroup>
									<FormControlLabel
										disabled={
											organizationId ? !servicesUnlimited[element.serviceId] : !isEditableUnlimited
										}
										checked={element?.unlimited}
										control={
											<Checkbox
												onChange={(e) => onChangeUnlimitedValue(e, element.serviceId)}
												color={"secondary"}
											/>
										}
										label={<Typography color={"textPrimary"}>{t("service.unlimited")}</Typography>}
									/>
								</FormGroup>
								<TextField
									style={styles.inputAmount}
									key={index}
									variant="standard"
									label={t("service.usage_limit")}
									type="number"
									color="secondary"
									helperText={
										!servicesUnlimited[element.serviceId] &&
										element?.usageLimit > element?.freeUsageLimit
											? `O limite desse serviço é ${element?.freeUsageLimit}`
											: ""
									}
									error={
										!servicesUnlimited[element.serviceId] &&
										element?.usageLimit > element?.freeUsageLimit &&
										element.freeUsageLimit !== 0
											? true
											: false
									}
									disabled={readonly || element?.unlimited}
									value={Number(element?.usageLimit)}
									onChange={(e) => onChangeValueTable(e, element.serviceId)}
								/>
								{isPriceVisible && (
									<TextField
										style={styles.inputPrice}
										key={index}
										variant="standard"
										label={t("product_select.tier_price")}
										type={"text"}
										color="secondary"
										placeholder={"Digite o preço"}
										value={""}
									/>
								)}
								{isButtonEditValues && onClickEditValues && (
									<Chip
										style={{ margin: "0 8px" }}
										label={t("product_select.edit_ranges")}
										color={"secondary"}
										variant="outlined"
										onClick={() => onClickEditValues(element, index)}
									/>
								)}
								{!readonly && (
									<RoundDelete>
										<DeleteIcon
											onClick={() => handleDeleteFetch(element)}
											style={{ cursor: "pointer" }}
											color={"error"}
										/>
									</RoundDelete>
								)}
							</ListItem>
							{errors?.[`$${index}`] && (
								<span
									style={{
										color: "red",
										fontSize: 12,
										marginTop: 4,
									}}
								>
									{errors?.[`$${index}`]?.message}
								</span>
							)}
						</Fragment>
					))}

				{isHistoricVisible && (
					<Link
						onClick={onClickHistoric}
						style={{
							display: "flex",
							alignItems: "center",
							marginTop: dataProducts?.length ? "1rem" : "",
							gap: "0.3rem",
						}}
					>
						<HistoryIcon color="secondary" />
						<Typography color={"secondary"}>{t("product_select.historic_title")}</Typography>
					</Link>
				)}
			</ContainerList>
			<SnackBar
				open={productExists}
				type={"error"}
				message={t("product_select.product_already_exists")}
				handleClose={() => setProductExists(false)}
			/>
		</Container>
	)
}

const styles = {
	chip: { minWidth: 200 },
	button: { height: 50, margin: 8 },
	typography: { marginBottom: "-16px" },
	inputAmount: { width: 200 },
	formControl: { margin: "8px 8px 8px 0" },
	inputPrice: { margin: "0 8px" },
}
