import { Box, useTheme } from "@mui/material"

interface ResponseViewerTabsPanelProps {
	children: React.ReactNode
	value?: number | string
	show: boolean
}

export const ResponseViewerTabsPanel = ({
	children,
	show,
	value,
	...props
}: ResponseViewerTabsPanelProps) => {
	const theme = useTheme()

	return (
		<section
			dir={theme.direction === "rtl" ? "x-reverse" : "x"}
			role="tabpanel"
			hidden={!show}
			id={`full-width-tabpanel-${value}`}
			aria-labelledby={`full-width-tab-${value}`}
			{...props}
		>
			{show && <Box sx={{ p: 3 }}>{children}</Box>}
		</section>
	)
}
