import { useEffect, useState } from "react"
import { useForm, FormProvider } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"

import {
	Autocomplete,
	DialogContentText,
	FormControl,
	Grid,
	InputLabel,
	MenuItem,
	Select,
	TextField,
} from "@mui/material"

import { createClient } from "../../apis/customers"
import { MultiSelectData } from "../../apis/dashboard"
import { listOptionsOrganizations } from "../../apis/organization"
import { BodyPage, ContainerPage } from "../../components/container/container.styles"
import DialogAlert from "../../components/dialog/dialog"
import { HeaderPage } from "../../components/header/page/header-page"
import { Loading } from "../../components/loading/loading"
import { ControlledTextField } from "../../components/text-field/controlled-text-field"
import { Client, Roles } from "../../models/Client"
import { pathNames } from "../../routes/paths"
import { styles } from "../../styles/common"
import { createErrorMessage } from "../../utils/format"

export const CreateClientAdmin = () => {
	const [loading, setLoading] = useState<boolean>(false)

	const [dialog, setDialog] = useState<boolean>(false)
	const [dialogTitle, setDialogTitle] = useState<any>("")
	const [dialogDescription, setDialogDescription] = useState<any>("")
	const [isError, setIsError] = useState<boolean>(false)

	const [organizations, setOrganizations] = useState<MultiSelectData["data"]>([])
	const [organizationSelected, setOrganizationSelected] = useState<any>(null)

	const { t } = useTranslation()

	const navigate = useNavigate()

	const methods = useForm<Client>()
	const {
		control,
		getValues,
		formState: { isValid },
	} = methods

	useEffect(() => {
		getOrganizations()
	}, [])

	const getOrganizations = async () => {
		try {
			const {
				data: { data },
			} = await listOptionsOrganizations()
			setOrganizations(data)
		} catch (error) {
		} finally {
		}
	}

	const handleSubmit = async () => {
		const formValues = getValues()

		const objectClient = {
			...formValues,
			organizationId: organizationSelected?.id,
			role: Roles.Client,
		}

		if (isValid) {
			setLoading(true)
			setIsError(false)
			try {
				await createClient(objectClient)
				setDialogTitle(t("request_messages.create_success_title", { name: "Cliente" }))
				setDialogDescription(t("request_messages.success_description", { name: "cliente" }))
				setOrganizationSelected(null)
			} catch (error: any) {
				setIsError(true)
				setDialogTitle(t("request_messages.500"))
				if (error?.response?.data?.messages) {
					setDialogDescription(createErrorMessage(error?.response?.data?.messages))
				} else {
					setDialogDescription(t("request_messages.400"))
				}
			} finally {
				setDialog(true)
				setLoading(false)
			}
		}
	}

	const handleAccept = () => {
		setDialog(false)
		if (!isError) {
			navigate(pathNames.customer.list)
			methods.reset()
		}
	}

	const handleAutoComplete = (event: any, option: any) => {
		setOrganizationSelected(option)
	}

	if (loading) return <Loading />

	return (
		<ContainerPage>
			<HeaderPage
				disabledButton={!isValid || !organizationSelected}
				onClickSubmit={handleSubmit}
				onClickTopButton={() => navigate(pathNames.customer.list)}
				description={t("customer.create_user_admin_description")}
				isEdit={true}
				title={t("customer.create_user_admin_title")}
			/>
			<BodyPage>
				<FormProvider {...methods}>
					<Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
						<Grid item xs={12}>
							<ControlledTextField
								id={"name"}
								style={styles.fullWidth}
								label={t("customer.label_name")}
								control={control}
								rules={{ required: true }}
							/>
						</Grid>
						<Grid item xs={12}>
							<ControlledTextField
								id={"email"}
								style={styles.textFieldMarginTop}
								label={t("customer.label_email")}
								control={control}
								rules={{ required: true }}
							/>
						</Grid>
						<Grid item xs={6}>
							<ControlledTextField
								id={"phone"}
								style={styles.textFieldMarginTop}
								label={t("customer.label_phone")}
								control={control}
								rules={{ required: true }}
								mask={"(99) 99999-9999"}
							/>
						</Grid>
						<Grid item xs={6}>
							{organizations.length > 0 && (
								<Autocomplete
									style={styles.textFieldMarginTop}
									disablePortal
									size={"small"}
									options={organizations}
									defaultValue={organizationSelected}
									getOptionLabel={(option: any) => option.name || ""}
									onChange={handleAutoComplete}
									renderInput={(params) => (
										<TextField
											{...params}
											variant={"filled"}
											color={"secondary"}
											label={t("customer.label_organization")}
										/>
									)}
								/>
							)}
						</Grid>
					</Grid>
				</FormProvider>
			</BodyPage>
			<DialogAlert
				props={!isError ? <DialogContent /> : null}
				onlyConfirm={true}
				show={dialog}
				accept={handleAccept}
				title={dialogTitle}
				description={dialogDescription}
			/>
		</ContainerPage>
	)
}

const DialogContent = () => {
	const { t } = useTranslation()

	return (
		<>
			<br />
			<DialogContentText>
				<b>{t("modal.description_email_steps_1")}</b>
			</DialogContentText>
			<DialogContentText>
				<b>{t("modal.description_email_steps_2")}</b>
			</DialogContentText>
			<br />
			<DialogContentText>{t("modal.description_email_steps_3")}</DialogContentText>
			<img alt="email-example" width={400} src={require("../../assets/images/email-example.png")} />
		</>
	)
}
