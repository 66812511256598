import { useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"

import { searchDailyOrganizationProductConsumption } from "../../../../apis/dashboard"
import { GraphLineProps, Line } from "../../../../components/graphs/multi-axis/multi-axis"
import { Table } from "../../../../components/table/table"
import { sortByProperty } from "../../../../utils/array"
import { parseToUSDateFormat } from "../../../../utils/date"
import { handleApiExceptions } from "../../../../utils/handle-errors"
import { QueryInvoicingProps } from "../invoice-details"
import { tablePrintStyles } from "../invoicing-details.styles"

interface MonthlyEvolutionProps {
	queryInvoicing: QueryInvoicingProps
	month: number
	year: number
	isPrinting?: boolean
}

export function MonthlyEvolution({
	queryInvoicing,
	month,
	year,
	isPrinting,
}: MonthlyEvolutionProps) {
	const { t } = useTranslation()

	const [rows] = useState<any[]>([])

	const [queriesByProduct, setQueriesByProduct] = useState<any[]>([])

	const [paginationQueries, setPaginationQueries] = useState({
		rowsPerPage: 5,
		pageNumber: 1,
	})

	async function tryDearchDailyOrganizationProductConsumption() {
		try {
			const lastDayOfMonth = parseToUSDateFormat(new Date(year, month, 0))
			const twelveMonthsAgo = parseToUSDateFormat(new Date(year, month - 12, 0))
			const { data } = await searchDailyOrganizationProductConsumption(
				queryInvoicing.orgazizationId,
				twelveMonthsAgo,
				lastDayOfMonth,
				[],
			)
			if (data.data) {
				const s = data.data.map((consumition) => {
					const usageData = Object.entries(consumition.usageData).reduce(
						(acc: any, [key, value]: any) => {
							const days = key.split("-")
							if (days.length) {
								const mm = days?.[1]
								const yyyy = days?.[2]
								const month = t(`month.${+mm}`)
								return {
									...acc,
									[`${month} - ${yyyy}`]: (acc[`${month} - ${yyyy}`] ?? 0) + value,
								}
							}
							return acc
						},
						{},
					)
					return {
						serviceName: consumition.serviceName,
						usageData,
					}
				})
				setQueriesByProduct(s)
			}
		} catch (error) {
			handleApiExceptions(error)
		}
	}

	const handleChangeRowsPerPage = (rowsPerPage: number) =>
		setPaginationQueries((prevState) => ({ ...prevState, rowsPerPage }))

	const handleChangePage = (crrPage: number) =>
		setPaginationQueries((prevState) => ({ ...prevState, pageNumber: crrPage++ }))

	const consultationDataset = useMemo(() => {
		return queriesByProduct.map(({ usageData, serviceName }) => ({
			data: Object.entries(usageData).map((object) => object),
			label: serviceName,
		})) as GraphLineProps[]
	}, [queriesByProduct])

	const consultationDatasetTable = useMemo(() => {
		const data = consultationDataset.map(({ data, ...props }) => ({
			...props,
			data: data.filter(([_, value]) => Boolean(value)),
		}))
		const months = t("month", { returnObjects: true })
		const monthInName = Object.entries(months).reduce(
			(acc: any, [key, value]: any) => ({ ...acc, [value]: key }),
			{},
		)
		const rows: any = []
		data.forEach(({ data, ...props }) => {
			rows.push(
				...data.map(([month, value]) => ({
					...props,
					month,
					value,
					order: `${month.split(" - ")[1]}-${monthInName[month.split(" - ")[0]]}-01`,
				})),
			)
		})
		return sortByProperty(rows, "order")
	}, [consultationDataset])

	const consultationLegend = useMemo(
		() =>
			consultationDataset.map(({ label, data }) => [
				label,
				`${data.reduce((acc, [_, value]) => acc + Number(value), 0).toString()} consultas`,
			]),
		[consultationDataset],
	)

	const keys = useMemo(() => ["label", "month", "value"], [rows])

	const columns = useMemo(
		() => [
			{
				label: t("consumption.invoicing.monthlyEvolution.columns.serviceName"),
				id: "month",
			},
			{
				label: t("consumption.invoicing.monthlyEvolution.columns.consultation"),
				id: "consultation_qty",
			},
			{
				label: t("consumption.invoicing.monthlyEvolution.columns.value"),
				id: "value",
			},
		],
		[rows, t],
	)

	searchDailyOrganizationProductConsumption

	useEffect(() => {
		tryDearchDailyOrganizationProductConsumption()
	}, [])

	useEffect(() => {
		if (queryInvoicing.orgazizationId.length) {
			Promise.all([tryDearchDailyOrganizationProductConsumption()]).finally(() => {})
		}
	}, [queryInvoicing])

	return (
		<>
			<Line
				title={t("consumption.invoicing.monthlyEvolution.consumptionMonth") as string}
				legend={consultationLegend}
				data={consultationDataset}
			/>
			<Table
				changeRowsPerPage={handleChangeRowsPerPage}
				page={paginationQueries.pageNumber - 1}
				rows={consultationDatasetTable}
				changePage={handleChangePage}
				onDelete={() => null}
				isEditVisible={false}
				onEdit={() => null}
				columns={columns}
				keys={keys}
				rowsPerPageOptions={[5, 10, 15]}
				rowsPerPage={isPrinting ? 100 : paginationQueries.rowsPerPage}
				titleStyles={isPrinting ? tablePrintStyles : {}}
				cellStyles={isPrinting ? tablePrintStyles : {}}
				tableContainerStyles={isPrinting ? { overflowX: "initial" } : {}}
				hidePagination={isPrinting}
			/>
		</>
	)
}
