export const flattenObject = (obj: object, parentKey = "") => {
	let result = {}

	for (const key in obj) {
		if (obj.hasOwnProperty(key)) {
			const keyName = parentKey ? `${parentKey}.${key}` : key
			/**
			 * should flatten too if the value is an array of objects
			 */
			if (Array.isArray(obj[key as keyof object])) {
				const curentItem = obj[key as keyof object] as object[]
				curentItem.forEach((item: object, index: number) => {
					result = { ...result, ...flattenObject(item, `${keyName}.[${index}]`) }
				})
				continue
			}

			if (typeof obj[key as keyof object] === "object") {
				result = { ...result, ...flattenObject(obj[key as keyof object], keyName) }
			} else {
				result[keyName as keyof object] = obj[key as keyof object]
			}
		}
	}
	return result
}
