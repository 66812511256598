import styled from "styled-components"

export const Clipboard = styled.div`
	border: 1px solid #d9d9d9;
	padding: 5px;
	display: flex;
	justify-content: space-around;
	align-items: center;
	align-content: center;
	border-radius: 5px;

	& svg:hover {
		color: ${(props) => props.theme.palette.primary.main};
		cursor: pointer;
	}
`
