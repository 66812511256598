import { Box, Typography } from "@mui/material"
import styled from "styled-components"

export const GraphSectionContainer = styled.section<{ mt?: string; mb?: string }>`
	margin-bottom: ${(props) => props.mb ?? 0};
	margin-top: ${(props) => props.mt ?? 0};
	flex-direction: column;
	display: flex;
	gap: 1px;
	page-break-inside: avoid;
`

export const GraphSectionTitle = styled(Typography)`
	margin: 0 !important;
	color: ${({ theme: { palette } }) => (palette.mode === "dark" ? "#fff" : "#000")};
`

export const GraphTitleDivider = styled.span`
	background: #187063;
	display: block;
	opacity: 0.5;
	height: 2px;
	width: 99%;
	margin-bottom: 10px;
`
export const Center = styled(Box)`
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
`

export const tablePrintStyles = {
	fontSize: "11px",
	padding: "10px",
	wordWrap: "break-word",
	overflowWrap: "break-word",
	wordBreak: "break-word",
	whiteSpace: "normal",
	overflow: "hidden",
	textOverflow: "ellipsis",
}
