import { Options } from "../../@types/options"

const labels = new Map<string, string>([["monthly", "Mensal"]])

const getLabel = (status: string): string => labels.get(status)!

const getOption = (status: string): Options => ({
	label: getLabel(status),
	value: status,
})

const options: Options[] = Array.from(labels).map(([key, value]) => ({
	label: value,
	value: key,
}))

export const invoiceBillingOptions = {
	getOption,
	getLabel,
	options,
}
