import { useFieldArray, useFormContext } from "react-hook-form"
import { useTranslation } from "react-i18next"

import { Alert } from "@mui/material"
import Checkbox from "@mui/material/Checkbox"
import FormControl from "@mui/material/FormControl"
import InputLabel from "@mui/material/InputLabel"
import ListItemText from "@mui/material/ListItemText"
import MenuItem from "@mui/material/MenuItem"
import OutlinedInput from "@mui/material/OutlinedInput"
import Select, { SelectChangeEvent } from "@mui/material/Select"

import { CreateOrganizationData } from "../../../libs/zod/organization/create-organization"
import { SuccessBillingOption } from "../../../libs/zod/shared"

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 250,
		},
	},
}

type SeccessOptionsSelectorProps = {
	serviceIndex: number
	options: Array<SuccessBillingOption> | null
}

export default function SeccessOptionsSelector({
	serviceIndex,
	options,
}: SeccessOptionsSelectorProps) {
	const { control, setValue } = useFormContext<CreateOrganizationData>()
	const { t } = useTranslation()

	const { fields } = useFieldArray({
		control,
		name: `services.${serviceIndex}.successBillingOptions`,
		keyName: undefined,
	})

	const handleChange = (event: SelectChangeEvent<string[]>) => {
		const {
			target: { value },
		} = event

		if (typeof value === "string") {
			setValue(
				`services.${serviceIndex}.successBillingOptions`,
				options
					?.filter((option) => option.title === value)
					?.map((option) => ({ ...option, key: undefined })),
			)
		} else {
			setValue(
				`services.${serviceIndex}.successBillingOptions`,
				options
					?.filter((option) => value.includes(option.title))
					?.map((option) => ({ ...option, key: undefined })),
			)
		}
	}

	return (
		<FormControl sx={{ width: "100%" }}>
			<InputLabel id="success-options-label" color="secondary">
				{t("service.success_billing_options")}
			</InputLabel>
			<Select
				labelId="success-options-label"
				id="success-options-checkbox"
				multiple
				disabled={!options || options.length === 0}
				value={fields.map((field) => field.title)}
				onChange={handleChange}
				input={<OutlinedInput label={t("service.success_billing_options")} color="secondary" />}
				renderValue={(selected) => selected.join(", ")}
				MenuProps={MenuProps}
				color="secondary"
			>
				{options?.map((option, index) => (
					<MenuItem key={index} value={option.title}>
						<Checkbox checked={fields.some((f) => f.title === option.title)} />
						<ListItemText primary={option.title} />
					</MenuItem>
				))}
			</Select>

			{options && options?.length === 0 ? (
				<Alert severity="warning" style={{ marginTop: 8 }}>
					{t("service.no_success_billing_options_abaiable")}
				</Alert>
			) : null}
		</FormControl>
	)
}
