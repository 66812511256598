import { useEffect, useState } from "react"
import { FormProvider, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"

import SyncIcon from "@mui/icons-material/Sync"
import {
	Autocomplete,
	Button,
	Checkbox,
	Divider,
	FormControlLabel,
	Grid,
	TextField,
	Tooltip,
} from "@mui/material"

import { listProjectsToSelect } from "../../apis/projects"
import { createService } from "../../apis/services"
import { BatchFields } from "../../components/batch-fields/batch-fields"
import { BodyPage, FormContainerPage } from "../../components/container/container.styles"
import DialogAlert from "../../components/dialog/dialog"
import { HeaderPage } from "../../components/header/page/header-page"
import { Loading } from "../../components/loading/loading"
import { ControlledTextField } from "../../components/text-field/controlled-text-field"
import { useManifest } from "../../hooks/app/service/useManifest"
import { pathNames } from "../../routes/paths"
import { styles } from "../../styles/common"
import { createErrorMessage } from "../../utils/format"
import { validateUrl } from "../../utils/validate"
import { ExecutionOptions } from "./components/execution-options"
import { SuccessBillingOptions } from "./components/success-billing-options"

export const CreateService = () => {
	const [loading, setLoading] = useState<boolean>(false)

	const [dialog, setDialog] = useState<boolean>(false)
	const [dialogTitle, setDialogTitle] = useState<any>("")
	const [dialogDescription, setDialogDescription] = useState<any>("")
	const [isError, setIsError] = useState<boolean>(false)

	const [projectSelected, setProjectSelected] = useState<any>("")
	const [projects, setProjects] = useState<[]>([])

	const { t } = useTranslation()

	const navigate = useNavigate()

	const methods = useForm<any>()
	const {
		control,
		getValues,
		formState: { isValid, errors },
		register,
		watch,
	} = methods

	const handleSubmit = async () => {
		const formValues = getValues()

		const objectClient = {
			...formValues,
			projectId: projectSelected?.id,
		}

		if (isValid && validateUrl(getValues("url"))) {
			setLoading(true)
			setIsError(false)
			try {
				await createService(objectClient)
				setDialogTitle(t("request_messages.create_success_title", { name: "Serviço" }))
				setDialogDescription(t("request_messages.success_description", { name: "serviço" }))
			} catch (error: any) {
				setIsError(true)
				setDialogTitle(t("request_messages.500"))
				if (error?.response?.data?.messages) {
					setDialogDescription(createErrorMessage(error?.response?.data?.messages))
				} else {
					setDialogDescription(t("request_messages.400"))
				}
			} finally {
				setDialog(true)
				setLoading(false)
			}
		}
	}

	const { fetch } = useManifest({ methods })

	useEffect(() => {
		listProjects()
	}, [])

	const listProjects = async () => {
		try {
			const {
				data: { data },
			} = await listProjectsToSelect()
			setProjects(data)
		} catch (error) {}
	}

	const handleAccept = () => {
		setDialog(false)
		if (!isError) {
			navigate(pathNames.service.list)
			methods.reset()
		}
	}

	const handleAutoComplete = (event: any, option: any) => {
		setProjectSelected(option)
	}

	if (loading) return <Loading />

	return (
		<FormContainerPage>
			<HeaderPage
				disabledButton={!isValid}
				onClickSubmit={handleSubmit}
				onClickTopButton={() => navigate(pathNames.service.list)}
				description={t("service.description_create")}
				isEdit={true}
				title={t("service.create_title")}
				buttonAttrs={{ type: "button" }}
			/>
			<BodyPage>
				<FormProvider {...methods}>
					<Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
						<Grid item xs={12} sm={6}>
							<ControlledTextField
								id={"name"}
								style={styles.fullWidth}
								label={t("service.name")}
								control={control}
								rules={{ required: true }}
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<Autocomplete
								disablePortal
								size={"small"}
								options={projects}
								getOptionLabel={(option: any) => option.name || ""}
								onChange={handleAutoComplete}
								renderInput={(params) => (
									<TextField
										{...params}
										variant={"filled"}
										color={"secondary"}
										label={t("service.project")}
									/>
								)}
							/>
						</Grid>
						<Grid item xs={12}>
							<ControlledTextField
								id={"description"}
								style={styles.textFieldMarginTop}
								label={t("service.description")}
								control={control}
								rules={{ required: true }}
							/>
						</Grid>
						<Grid item xs={8} md={9} lg={10}>
							<ControlledTextField
								id={"url"}
								validChars={
									/^(https?:\/\/)?(www\.)?([a-zA-Z\d-]+\.){0,}[a-zA-Z]{2,}(\S*)?$|^(\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3})$/
								}
								style={styles.textFieldMarginTop}
								label={t("service.url")}
								control={control}
								rules={{ required: true }}
							/>
						</Grid>

						<Grid item xs={4} md={3} lg={2} style={{ display: "flex", alignItems: "center" }}>
							<FormControlLabel
								control={
									<Checkbox
										{...register(`usePrivateNetwork`)}
										checked={watch(`usePrivateNetwork`)}
									/>
								}
								label={
									<Tooltip title="Utilizar acesso direto via rede interna nos casos em que esta conexão é possível (sem uso do API Gateway)">
										<span style={{ fontSize: "15px" }}>Usar URL interna</span>
									</Tooltip>
								}
							/>
						</Grid>
						<Grid item xs={8} md={9} lg={10}>
							<ControlledTextField
								id={"manifestUrl"}
								validChars={
									/^(https?:\/\/)?(www\.)?([a-zA-Z\d-]+\.){0,}[a-zA-Z]{2,}(\S*)?$|^(\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3})$/
								}
								style={styles.textFieldMarginTop}
								label={t("service.manifestUrl")}
								placeholder={watch("url") + "/manifest"}
								control={control}
							/>
						</Grid>

						<Grid item xs={4} md={3} lg={2} style={styles.active}>
							<Button
								variant="outlined"
								startIcon={<SyncIcon />}
								color={"secondary"}
								style={styles.button}
								onClick={fetch}
							>
								Atualizar
							</Button>
						</Grid>
					</Grid>

					<Grid item xs={12} marginTop={3}>
						<Divider style={styles.divider} />
					</Grid>

					<BatchFields readonly />

					<ExecutionOptions />

					<SuccessBillingOptions />
				</FormProvider>
			</BodyPage>
			<DialogAlert
				onlyConfirm={true}
				show={dialog}
				accept={handleAccept}
				title={dialogTitle}
				description={dialogDescription}
			/>
		</FormContainerPage>
	)
}
