import { useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

import { GlobalContext } from "../../../contexts/global-provider"
import { getServicesDataByLanguage } from "../../../data/services"
import social_links from "../../../data/social-medias.json"
import { ContainerImage, Description, Information, Title, Button } from "../default/banner.styles"
import { Container, ContainerButtons, SubTitle, SvgJulia } from "./content-banner.styles"

export const ContentBanner = () => {
	const { t, i18n } = useTranslation()

	const [serviceData, setServiceData] = useState<any>()

	const { productIdShow } = useContext(GlobalContext)

	useEffect(() => {
		if (productIdShow) setServiceData(filterProductsById(productIdShow))
	}, [productIdShow])

	const filterProductsById = (id: string) => {
		return getServicesDataByLanguage(i18n.language).find((element) => element.id === id)
	}

	const openLink = (link: string) => {
		window.open(link, "_blank")
	}

	return (
		<Container>
			<Information>
				<Title>{serviceData?.name}</Title>
				<SubTitle>{serviceData?.slogan}</SubTitle>
				<Description>{serviceData?.description}</Description>
				<ContainerButtons>
					<Button onClick={() => openLink(social_links.whatsapp)}>{t("home.explore_plans")}</Button>
					{/* <ButtonLine>{t('home.free_test')} <IconPlayCircle style={{marginLeft: 3}} /> </ButtonLine> */}
				</ContainerButtons>
			</Information>
			<ContainerImage>
				<SvgJulia />
			</ContainerImage>
		</Container>
	)
}
