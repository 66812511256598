import { Client } from "../models/Client"
import axios from "./config/axios"

export const listAllCustomers = async ({
	currentPage,
	pageSize,
	filter,
	admin,
	active = true,
}: any) => await axios.post("/clients/search", { currentPage, pageSize, filter, admin, active })

export const createClient = async (client: Client) => await axios.post("/clients", client)

export const deleteClient = async (clientId: string) => await axios.delete(`/clients/${clientId}`)

export const searchClient = async (clientId: any) => await axios.get(`/clients/${clientId}`)

export const updateClient = async (clientId: any, customerData: any) =>
	await axios.put(`/clients/${clientId}`, customerData)

export const disableClients = async (clients: Array<string>) =>
	await axios.put(`/clients/disable`, { clientIds: clients })
