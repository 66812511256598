import { Dialog as MuiDialog } from "@mui/material"
import styled from "styled-components"

export const Dialog = styled(MuiDialog)(({ theme }) => ({
	"& .MuiDialogContent-root": {
		padding: theme.spacing(2),
	},
	"& .MuiDialogActions-root": {
		padding: theme.spacing(1),
	},
	"& .MuiPaper-root": {
		maxWidth: "80%",
	},
}))
