import { HelpCenterRounded } from "@mui/icons-material"
import styled from "styled-components"

export const HelpButton = styled(HelpCenterRounded)`
	color: ${(props) => props.theme.palette.primary.main};
	cursor: pointer;
	&:hover {
		color: ${(props) => props.theme.palette.primary.dark};
	}
`
