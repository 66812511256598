import { Box } from "@mui/material"
import styled from "styled-components"

export const FooterContainer = styled(Box)`
	display: flex;
	width: 100%;
	justify-content: space-between;
	align-items: center;
	padding: 0 20px;
`

export const ActionContainer = styled(Box)`
	width: 100%;
	display: flex;
	justify-content: flex-end;
	align-items: center;
	gap: 5px;
`
