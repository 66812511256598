import { AxiosResponse } from "axios"

import { CreateOrganizationData } from "../libs/zod/organization/create-organization"
import axiosInstance from "./config/axios"

export const createOrganization = async (organization: CreateOrganizationData) =>
	axiosInstance.post("/organizations", organization)

export const listAllOrganization = async ({
	currentPage,
	pageSize,
	filter = "",
	active = true,
}: any) =>
	await axiosInstance.post("/organizations/search", { currentPage, pageSize, filter, active })

export const listOptionsOrganizations = async () => await axiosInstance.get("/organizations")

export const listOrganizationsWithClientUsage = async () =>
	await axiosInstance.get("/organizations/custom/withClientUsage")

export const listSpecificOrganization = async (
	organizationId: string | undefined,
): Promise<AxiosResponse<{ data: CreateOrganizationData }>> =>
	await axiosInstance.get(`/organizations/${organizationId}`)

export const updateOrganization = async (organizationId: any, organization: any) =>
	await axiosInstance.put(`/organizations/${organizationId}`, organization)

export const deleteOrganization = async (organizationId: string) =>
	await axiosInstance.delete(`/organizations/${organizationId}`)

export const listSegments = async () => await axiosInstance.get("/segments")

export const disableOrganizations = async (organizations: Array<string>) =>
	await axiosInstance.put(`/organizations/disable`, { organizationIds: organizations })
