import { useEffect, useState } from "react"
import { FormProvider, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"

import {
	Alert,
	AlertTitle,
	Divider,
	FormControlLabel,
	FormGroup,
	Grid,
	Switch,
} from "@mui/material"

import { listSpecificOrganization } from "../../apis/organization"
import { BodyPage, ContainerPageFull } from "../../components/container/container.styles"
import { DialogHistoric } from "../../components/dialog-historic/dialog-historic"
import { HeaderPage } from "../../components/header/page/header-page"
import { ProductSelect } from "../../components/product-select/product-select"
import { ControlledTextField } from "../../components/text-field/controlled-text-field"
import { AuthStorage } from "../../storage/auth-type"
import { getItem } from "../../storage/local-storage"
import { styles } from "../../styles/common"

export const ProfileOrganization = () => {
	const { t } = useTranslation()

	const [showDialogHistoric, setShowDialogHistoric] = useState<boolean>(false)
	const [active, setActive] = useState<boolean>(true)
	const [products, setProducts] = useState<any>([])

	const methods = useForm()
	const { control, reset } = methods

	const user = getItem(AuthStorage.userInformation)

	useEffect(() => {
		fetchData()
		// eslint-disable-next-line
	}, [])

	const fetchData = async () => {
		try {
			const {
				data: { data },
			} = await listSpecificOrganization(user?.organizationId)
			setProducts(data?.services)
			setActive(data?.active)
			reset(data)
		} catch (error) {}
	}

	const handleUpdateValue = (data: any) => {
		return null
	}

	const handleAddProduct = (product: any) => {
		return null
	}

	const handleDeleteProduct = (product: any) => {
		return null
	}

	return (
		<ContainerPageFull>
			<HeaderPage
				isCreateButtonVisible={false}
				title={t("profileOrganization.title")}
				isEdit={false}
				searchHide={true}
				description={t("profileOrganization.description")}
			/>
			<BodyPage>
				<FormProvider {...methods}>
					<Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
						<Grid item xs={10}>
							<ControlledTextField
								id={"name"}
								style={styles.fullWidth}
								label={t("organizations.label_name_organization")}
								control={control}
								readOnly={true}
								rules={{ required: true }}
							/>
						</Grid>
						<Grid style={styles.active} item xs={2}>
							<FormGroup>
								<FormControlLabel
									control={<Switch disabled={true} color={"secondary"} checked={active} />}
									label={active ? t("status.active") : t("status.inactive")}
								/>
							</FormGroup>
						</Grid>
						<Grid item xs={6}>
							<ControlledTextField
								id={"cnpj"}
								style={styles.textFieldMarginTop}
								label={t("organizations.label_cnpj")}
								control={control}
								mask={"99.999.999/9999-99"}
								readOnly={true}
								rules={{ required: true }}
							/>
						</Grid>
						<Grid item xs={6}>
							<ControlledTextField
								id={"billingEmail"}
								label={t("organizations.label_billing_email")}
								control={control}
								rules={{ required: true }}
								type={"email"}
								readOnly={true}
								// eslint-disable-next-line
								validChars={
									/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
								}
								style={styles.textFieldMarginTop}
							/>
						</Grid>
						<Grid item xs={12}>
							<ControlledTextField
								id={"imageUrl"}
								label={t("organizations.label_organization_logo")}
								control={control}
								rules={{ required: true }}
								readOnly={true}
								style={styles.textFieldMarginTop}
							/>
						</Grid>
					</Grid>
					<Divider style={styles.divider} />
					<Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
						<Grid item xs={6}>
							<ControlledTextField
								id={"address.zipCode"}
								label={t("organizations.label_cep")}
								control={control}
								rules={{ required: true }}
								readOnly={true}
								mask={"99999-999"}
								style={styles.fullWidth}
							/>
						</Grid>
						<Grid item xs={6}>
							<ControlledTextField
								id={"address.country"}
								label={t("organizations.label_country")}
								control={control}
								rules={{ required: true }}
								readOnly={true}
								style={styles.fullWidth}
							/>
						</Grid>
						<Grid item xs={6}>
							<ControlledTextField
								id={"address.street"}
								label={t("organizations.label_address")}
								control={control}
								rules={{ required: true }}
								readOnly={true}
								style={styles.textFieldMarginTop}
							/>
						</Grid>
						<Grid item xs={2}>
							<ControlledTextField
								id={"address.number"}
								label={t("organizations.label_address_number")}
								control={control}
								rules={{ required: true }}
								readOnly={true}
								style={styles.textFieldMarginTop}
							/>
						</Grid>
						<Grid item xs={4}>
							<ControlledTextField
								id={"address.complement"}
								label={t("organizations.label_address_complement")}
								control={control}
								rules={{ required: true }}
								readOnly={true}
								style={styles.textFieldMarginTop}
							/>
						</Grid>
						<Grid item xs={4}>
							<ControlledTextField
								id={"address.neighborhood"}
								label={t("organizations.label_address_neighborhood")}
								control={control}
								rules={{ required: true }}
								readOnly={true}
								style={styles.textFieldMarginTop}
							/>
						</Grid>
						<Grid item xs={4}>
							<ControlledTextField
								id={"address.city"}
								label={t("organizations.label_address_city")}
								control={control}
								rules={{ required: true }}
								readOnly={true}
								style={styles.textFieldMarginTop}
							/>
						</Grid>
						<Grid item xs={4}>
							<ControlledTextField
								id={"address.state"}
								label={t("organizations.label_address_state")}
								control={control}
								rules={{ required: true }}
								readOnly={true}
								style={styles.textFieldMarginTop}
							/>
						</Grid>
					</Grid>
					<Divider style={styles.divider} />
					<ProductSelect
						handleUpdateValue={handleUpdateValue}
						readonly={true}
						onClickHistoric={() => setShowDialogHistoric(true)}
						isHistoricVisible={true}
						dataProducts={products}
						handleAddProduct={handleAddProduct}
						handleDeleteProduct={handleDeleteProduct}
					/>
				</FormProvider>
				<Alert style={styles.divider} severity={"warning"}>
					<AlertTitle>{t("profile.alert_title")}</AlertTitle>
					{t("profileOrganization.alert_description")}
				</Alert>
			</BodyPage>
			<DialogHistoric
				id={user?.organizationId}
				handleClose={() => setShowDialogHistoric(false)}
				open={showDialogHistoric}
			/>
		</ContainerPageFull>
	)
}
