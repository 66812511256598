import { Button } from "@mui/material"

interface HeaderPageTopButtonProps {
	onClick: () => void
	disabled?: boolean
	children?: React.ReactNode
	icon?: React.ReactNode
}
export const HeaderPageTopButton = ({
	onClick,
	disabled,
	children,
	icon,
}: HeaderPageTopButtonProps) => {
	return (
		<Button
			color="secondary"
			style={{
				textTransform: "initial",
			}}
			disabled={disabled}
			variant={"outlined"}
			startIcon={icon}
			onClick={onClick}
		>
			{children}
		</Button>
	)
}
