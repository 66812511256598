import { UseFormReturn } from "react-hook-form"

import axios from "axios"
import { t } from "i18next"
import { enqueueSnackbar } from "notistack"

import { keepOldOptionsSettings } from "../../../pages/service/execution-options-utils"

type TUseManifestProps = {
	methods: UseFormReturn<any, any, undefined>
}

type TManifestRequestField = {
	name: string
	example: string
	description: string
	required: boolean
}

export const useManifest = ({ methods }: TUseManifestProps) => {
	function keepChangedFields(
		currentFields: TManifestRequestField[],
		manifestoFields: TManifestRequestField[],
	) {
		return manifestoFields.map((field) => {
			const currentField = currentFields.find((mf) => mf.name === field.name)
			if (
				currentField &&
				(currentField.example !== field.example ||
					currentField.description !== field.description ||
					currentField.required !== field.required)
			) {
				return currentField
			}
			return field
		})
	}

	const fetch = async () => {
		try {
			const { url, name, description, manifestUrl, executionOptions, batchFields } =
				methods.getValues()
			const { data } = await axios.get(manifestUrl ?? `${url}/manifest`)
			if (!name) methods.setValue("name", data.name)
			if (!description)
				methods.setValue("description", `${data.author} - ${data.description} - v${data.version}`)

			const deletedBatchFields = batchFields
				.filter((field: any) => {
					return !data.fields?.request?.some((batchField: any) => batchField.name === field.name)
				})
				.map((field: any) => ({ ...field, deletedAt: new Date().toISOString() }))

			const fields = keepChangedFields(batchFields, data.fields.request)

			methods.setValue("batchFields", [...fields, ...deletedBatchFields])
			methods.setValue("successBillingOptions", data.successBillingOptions)
			methods.setValue("responseFields", data.fields.response)

			if (data?.executionOptions?.type && executionOptions) {
				data.executionOptions = keepOldOptionsSettings[
					data.executionOptions.type as keyof typeof keepOldOptionsSettings
				](data.executionOptions, executionOptions)
			}

			methods.setValue("executionOptions", data.executionOptions)

			enqueueSnackbar(t("service.manifest_fetch_success") as string, { variant: "success" })
		} catch (error) {
			enqueueSnackbar(t("request_messages.manifest_error") as string, { variant: "error" })
		}
	}

	return { fetch }
}
