import { useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"

import { GetAllCustomerInvoicingParams, getAllCustomerInvoicing } from "../../../apis/invoicing"
import { BodyPage, ContainerPage } from "../../../components/container/container.styles"
import { HeaderPage } from "../../../components/header/page/header-page"
import { Table } from "../../../components/table/table"
import { invoiceStatusOptions } from "../../../constants/options/invoice-status"
import { pathNames } from "../../../routes/paths"
import { handleApiExceptions } from "../../../utils/handle-errors"
import { InvoicingFilter, InvoicingFilterDataProps } from "./filter"

type DataTableInvoiceProps = {
	organizationName: string
	consultationQty: string
	organizationId: string
	referenceDate: string
	invoiceId: string
	status: string
	cost: number
}

export function InvoicingPage() {
	const navigate = useNavigate()

	const { t } = useTranslation()

	const [rows, setRows] = useState<DataTableInvoiceProps[]>([])

	const [isSeachingData, setIsSeachingData] = useState<boolean>(true)

	const [isOpenModalFilter, setIsOpenModalFilter] = useState<boolean>(false)

	const [paginationQueries, setPaginationQueries] = useState<GetAllCustomerInvoicingParams>({
		currentPage: 1,
		pageSize: 10,
	})

	const keys = useMemo(
		() => [
			{
				isLink: true,
				value: "organizationName",
				id: "organizationId",
				path: pathNames.organization.edit,
				backPath: pathNames.consumption.invoicing,
			},
			"referenceDate",
			"consultationQty",
			{ isMoney: true, value: "cost", id: "cost" },
			{ isPill: true, value: "status", id: "status" },
		],
		[rows],
	)

	const columns = useMemo(
		() => [
			{
				label: t("columns.customer"),
				id: "organizationName",
			},
			{
				label: t("columns.month_reference"),
				id: "referenceDate",
			},
			{
				label: t("columns.search_amount"),
				id: "consultationQty",
			},
			{
				label: t("columns.billing_value"),
				id: "cost",
			},
			{
				label: t("columns.status"),
				id: "status",
			},
		],
		[rows, t],
	)

	const statusColors = {
		INVOICED: "warning",
		APPROVED_OPERATIONAL: "info",
		APPROVED_FINANCE: "success",
		APPROVED: "success",
	}

	async function tryGetAllCustomerInvoicing() {
		try {
			setIsSeachingData(true)
			const { data } = await getAllCustomerInvoicing(paginationQueries)
			if (data.list.length) {
				const response = data.list.map((invoice) => ({
					consultationQty: new Intl.NumberFormat("pt-BR").format(invoice.monthlyData.total),
					cost: +invoice.monthlyData.price,
					status: invoiceStatusOptions.getLabel(invoice.status),
					statusPillColor: statusColors[invoice.status as keyof typeof statusColors],
					organizationName: invoice.organization.name,
					organizationId: invoice.organizationId,
					referenceDate: `${t(`month.${invoice.month}`)}/${invoice.year}`,
					invoiceId: invoice.id,
				}))
				setPaginationQueries((prevState) => ({ ...prevState, totalItems: data.total }))
				setRows(() => response)
			} else setRows(() => [])
		} catch (error) {
			handleApiExceptions(error)
		} finally {
			setIsSeachingData(false)
		}
	}

	const handleChangeRowsPerPage = (pageSize: number) =>
		setPaginationQueries((prevState) => ({ ...prevState, pageSize }))

	const handleChangePage = (currentPage: number) =>
		setPaginationQueries((prevState) => ({ ...prevState, currentPage: currentPage + 1 }))

	const handleCloseFilterModal = () => setIsOpenModalFilter((isOpen) => !isOpen)

	const handleEditInvoice = ({ invoiceId }: DataTableInvoiceProps) => navigate(invoiceId)

	const handleFilterChanges = (filters: InvoicingFilterDataProps) => {
		let parsedFilters = {}
		if (filters.organizationId) parsedFilters = { organizationIds: filters.organizationId }
		if (filters.date) {
			const mm = new Date(filters.date).getMonth() + 1
			const yyyy = new Date(filters.date).getFullYear()
			const lastMonthDay = new Date(Number(yyyy), mm, 0).getDate()
			parsedFilters = {
				...parsedFilters,
				startDate: `${String(mm).padStart(2, "0")}/01/${yyyy}`,
				endDate: `${String(mm).padStart(2, "0")}/${String(lastMonthDay).padStart(2, "0")}/${yyyy}`,
			}
		}
		setPaginationQueries((prevState) => ({ ...prevState, ...parsedFilters }))
	}

	const handleClearFilter = () => {
		setPaginationQueries((prevState) => ({
			...prevState,
			organizationIds: undefined,
			startDate: undefined,
			endDate: undefined,
		}))
	}

	useEffect(() => {
		tryGetAllCustomerInvoicing()
	}, [
		paginationQueries.organizationIds,
		paginationQueries.currentPage,
		paginationQueries.startDate,
		paginationQueries.pageSize,
		paginationQueries.endDate,
	])

	return (
		<ContainerPage>
			<HeaderPage
				title={t("consumption.title.invoicing")}
				onOpenFilter={handleCloseFilterModal}
				isCreateButtonVisible={false}
				hasFilterHeader
				searchHide
			/>
			<BodyPage>
				<Table
					page={(paginationQueries.currentPage ?? 0) - 1}
					changeRowsPerPage={handleChangeRowsPerPage}
					rowsPerPage={paginationQueries.pageSize}
					pageCount={paginationQueries.totalItems}
					changePage={handleChangePage}
					isLoading={isSeachingData}
					onEdit={handleEditInvoice}
					onDelete={() => null}
					columns={columns}
					keys={keys}
					rows={rows}
				/>
			</BodyPage>
			<InvoicingFilter
				cancel={handleCloseFilterModal}
				clearFilter={handleClearFilter}
				apply={handleFilterChanges}
				show={isOpenModalFilter}
			/>
		</ContainerPage>
	)
}
