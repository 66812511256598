import Grid from "@mui/material/Grid"
import styled, { css } from "styled-components"

import { maxDeviceSizeForMediaQueries } from "../../styles/devices-medias"

export const Container = styled.div`
	width: 100%;
	height: calc(100% - 80px);
	display: flex;
	margin-top: 80px;
	overflow: hidden;
	background-color: ${(props) => props.theme.palette.background.default};
	@media ${maxDeviceSizeForMediaQueries.tablet} {
		flex-direction: column;
	}
`

const containerStyles = css`
	width: 100%;
	height: 100%;
	margin-left: 280px;
	margin-top: 80px;
	overflow-x: hidden;
	padding: 32px;
	box-sizing: border-box;
	background-color: ${(props) => props.theme.palette.background.default};
	@media ${maxDeviceSizeForMediaQueries.tablet} {
		margin-left: 0px;
		padding: 16px;
	}
`
export const ContainerPage = styled.div`
	${containerStyles}
`

export const FormContainerPage = styled.form`
	${containerStyles}
`

export const ContainerPagLab = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: row;
	margin-left: 280px;
	margin-top: 80px;
	overflow: hidden;
	padding: 0;
	box-sizing: border-box;
	background-color: ${(props) => props.theme.palette.background.default};
	@media ${maxDeviceSizeForMediaQueries.tablet} {
		margin-left: 0px;
		padding: 16px;
		flex-direction: column;
		overflow: scroll;
	}
`

export const ContainerPageFull = styled.div`
	width: 100%;
	height: 100%;
	margin-top: 80px;
	overflow: hidden;
	padding: 32px 48px;
	box-sizing: border-box;
	background-color: ${(props) => props.theme.palette.background.default};
	@media ${maxDeviceSizeForMediaQueries.tablet} {
		margin-left: 0px;
		padding: 16px;
	}
`

export const Box = styled(Grid)`
	height: 90%;
`

export const BodyPage = styled.div`
	width: 100%;
`

export const BodyPageFull = styled.div`
	width: 100%;
	height: 90%;
`

export const BoxCenter = styled.div`
	width: 100%;
	height: 100%;
	align-items: center;
	justify-content: center;
	display: flex;
	flex-direction: column;
`
