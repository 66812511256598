import { PaginationProps } from "../@types/pagination"
import { ServiceBatch } from "../models/Service"
import { handleFilterParams } from "../utils/handle-filter"
import axios from "./config/axios"

type OrganizationProps = {
	id: string
	active: boolean
	createdAt: Date
	name: string
	cnpj: string
	status: string
	imageUrl: string
	addressId: string
	billingEmail: string
	segment: string
	billingRecorrence: string
	contractExpiresAt: Date | null
}

export type MonthlyDataProps = {
	serviceName: string
	serviceId: string
	teamName: string
	requestType: string
	processingType: string
	originType: string
	serviceLimitId: string
	costRangeModel: string
	cost: string
	usageData: any
	totalUsage: number
	clientName: string
	detailCost: string
}

type UserProps = {
	id: string
	email: string
	role: string
	active: boolean
}

export type InvoicingData = {
	id: string
	organizationId: string
	year: number
	month: number
	value: string
	status: string
	pdfUrl: string
	csvUrl: string
	operationalApproverId: string | null
	operationalApprover: UserProps
	financialApproverId: string | null
	financialApprover: UserProps
	createdAt: string
	monthlyData: GeneratedInvoice
	organization: OrganizationProps
	invoiceChanges: InvoiceChange[]
	batches: ServiceBatch[]
}

export interface User {
	id: string
	email: string
}

export interface AnulledBatch {
	id: string
	originalFileName: string
}

export type InvoiceChange = {
	id: string
	invoiceId: string
	justificative: string
	serviceId: string
	createdAt: string
	value: number | null
	createdByUserId: string
	createdByUser: User
	anulledBatchId: string | null
	anulledBatch: AnulledBatch
}

type ResponsePagination = {
	list: InvoicingData[]
	total: number
}

type RepsonsePagination = {
	data: ResponsePagination
	success: boolean
}

type RepsonseInvoicing<T> = {
	data: T
	success: boolean
}

export interface IByProcessingType {
	[key: string]: number
}

export interface ITotalByClassification {
	[key: string]: number
}

export interface ITeam {
	id: string
	name: string
	total: number
	byProcessingType: IByProcessingType
	users?: ITeam[]
	mail?: string
}

export interface IDailyUsage {
	total: number
	classifications: ITotalByClassification
}

export interface GeneratedInvoiceServiceCostaRange {
	id: string
	usageStart: number
	usageEnd: number
	cost: string
	selected?: boolean
}

export interface GeneratedInvoiceService {
	id: string
	name: string
	dailyUsage: { [key: string]: IDailyUsage }
	teams: ITeam[]
	totalByProcessingType: IByProcessingType
	total: number
	totalByClassification: ITotalByClassification
	price: number
	pricePerUnit: string
	usagePercentage: number
	costRangeModel: string
	billingType: string
	costRanges: GeneratedInvoiceServiceCostaRange[]
}

export type GeneratedInvoice = {
	price: number
	total: number
	totalByProcessingType: IByProcessingType
	totalByTeams: IByProcessingType
	services: GeneratedInvoiceService[]
}

export interface GetAllCustomerInvoicingParams extends PaginationProps {
	organizationIds?: string
	startDate?: string
	endDate?: string
}

export const getAllCustomerInvoicing = async (params: GetAllCustomerInvoicingParams) => {
	const { data } = await axios.post<RepsonsePagination>(
		"/invoices/search",
		handleFilterParams<GetAllCustomerInvoicingParams>({
			currentPage: params.currentPage,
			pageSize: params.pageSize,
			skip: params.skip,
		}),
		{
			params: handleFilterParams<GetAllCustomerInvoicingParams>({
				organizationIds: params.organizationIds,
				startDate: params?.startDate?.split("T")?.[0],
				endDate: params?.endDate?.split("T")?.[0],
			}),
		},
	)
	return data
}

export const getByIdCustomerInvoicing = async (id: string) => {
	const { data } = await axios.get<RepsonseInvoicing<InvoicingData>>(`/invoices/${id}`)
	return data
}

export const getSigninUrlFile = async (id: string, extension: string) => {
	const { data } = await axios.get<RepsonseInvoicing<string>>(
		`/invoices/${id}/sign-url/${extension}`,
	)
	return data
}

export const approveStatusInvoice = async (id: string) => {
	const { data } = await axios.put(`/invoices/${id}/status`)
	return data
}

export const updateInvoicePrice = async (
	invoiceId: string,
	payload: { priceDiff?: number; justification: string; serviceId: string; batchId?: string },
) => {
	const { data } = await axios.put(`/invoices/${invoiceId}/change`, payload)
	return data
}

export const deleteBatchFromInvoice = async (
	invoiceId: string,
	payload: { justification: string; serviceId: string; batchId: string },
) => {
	const { data } = await axios.put(`/invoices/${invoiceId}/deleteBatch`, payload)
	return data
}
