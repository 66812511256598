import { useTranslation } from "react-i18next"

import EmailIcon from "@mui/icons-material/Email"
import FacebookIcon from "@mui/icons-material/Facebook"
import InstagramIcon from "@mui/icons-material/Instagram"
import LinkedinIcon from "@mui/icons-material/LinkedIn"
import WhatsAppIcon from "@mui/icons-material/WhatsApp"
import YoutubeIcon from "@mui/icons-material/YouTube"

import social_links from "../../data/social-medias.json"
import {
	Container,
	Information,
	SocialMedia,
	Description,
	Logo,
	IconsContainer,
	Title,
} from "./footer.styles"

export const Footer = () => {
	const { t } = useTranslation()

	const openLink = (link: string) => {
		window.open(link, "_blank")
	}

	return (
		<Container>
			<Information>
				<Logo />
				<Description>{t("footer.resolv_description")}</Description>
			</Information>
			<SocialMedia>
				<Title>{t("footer.social_media")}</Title>
				<IconsContainer>
					<FacebookIcon
						onClick={() => openLink(social_links.facebook)}
						color={"secondary"}
						fontSize={"large"}
					/>
					<InstagramIcon
						onClick={() => openLink(social_links.instagram)}
						color={"secondary"}
						fontSize={"large"}
					/>
					<LinkedinIcon
						onClick={() => openLink(social_links.linkedin)}
						color={"secondary"}
						fontSize={"large"}
					/>
					<EmailIcon
						onClick={() => openLink(social_links.email)}
						color={"secondary"}
						fontSize={"large"}
					/>
					<WhatsAppIcon
						onClick={() => openLink(social_links.whatsapp)}
						color={"secondary"}
						fontSize={"large"}
					/>
					<YoutubeIcon
						onClick={() => openLink(social_links.youtube)}
						color={"secondary"}
						fontSize={"large"}
					/>
				</IconsContainer>
			</SocialMedia>
		</Container>
	)
}
