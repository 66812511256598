import { Box } from "@mui/material"
import styled from "styled-components"

export const Container = styled(Box)`
	width: 30vw;
	position: relative;
`

export const Content = styled(Box)`
	margin-bottom: 20px;
`
