import { cpf } from "cpf-cnpj-validator"
import z from "zod"

const dateRegex = /^\d{2}\/\d{2}\/\d{4}$/

const dateStringSchema = z
	.string()
	.refine(
		(val) => {
			if (!val?.length) return true

			return dateRegex.test(val)
		},
		{ message: "Data inválida" },
	)
	.transform((val) => {
		if (val?.length) {
			const [day, month, year] = val.split("/")
			return `${year}-${month}-${day}`
		}

		return val
	})

export const advancedSearchSchema = z
	.object({
		nome: z.string().min(3, { message: "O nome deve conter no minimo 3 caractéres" }),
		dtnascimento: dateStringSchema.optional(),
		dtnascimentofim: dateStringSchema.optional(),
		cpf: z
			.string()
			.refine((val) => !val || cpf.isValid(val), {
				message: "CPF inválido",
			})
			.optional(),
		nomeMae: z.string().refine((val) => !val?.length || val?.length > 3, {
			message: "O nome da mãe deve conter no minimo 3 caractéres",
		}),
		dtobitoinicio: dateStringSchema.optional(),
		dtobitofim: dateStringSchema.optional(),
		dtlavraturainicio: dateStringSchema.optional(),
		dtlavraturafim: dateStringSchema.optional(),
	})
	.superRefine(
		(
			{
				dtnascimento,
				dtnascimentofim,
				dtlavraturainicio,
				dtlavraturafim,
				dtobitoinicio,
				dtobitofim,
			},
			ctx,
		) => {
			if (!!dtnascimento && !!dtnascimentofim) {
				if (dtnascimento > dtnascimentofim) {
					ctx.addIssue({
						code: z.ZodIssueCode.invalid_date,
						path: ["dtnascimento", "dtnascimentofim"],
						message: "A data de nascimento fim deve ser maior que a de início",
					})
				}
			}

			if (dtlavraturainicio && dtlavraturafim) {
				if (dtlavraturainicio > dtlavraturafim) {
					ctx.addIssue({
						code: z.ZodIssueCode.invalid_date,
						path: ["dtlavraturainicio", "dtlavraturafim"],
						message: "A data de lavratura fim deve ser maior que a de início",
					})
				}
			}

			if (dtobitoinicio && dtobitofim) {
				if (dtobitoinicio > dtobitofim) {
					ctx.addIssue({
						code: z.ZodIssueCode.invalid_date,
						path: ["dtobitoinicio", "dtobitofim"],
						message: "A data de óbito fim deve ser maior que a de início",
					})
				}
			}
		},
	)

export type AdvancedSearch = z.infer<typeof advancedSearchSchema>
