import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"

import ApiIcon from "@mui/icons-material/Api"
import GroupIcon from "@mui/icons-material/Group"
import TrendingUpIcon from "@mui/icons-material/TrendingUp"
import { List } from "@mui/material"

import { useAuthentication } from "../../../../hooks/app/useAuthentication"
import { Roles } from "../../../../models/Client"
import { pathNames } from "../../../../routes/paths"
import { MenuItem } from "./menu-item"
const sxListConfig = { width: "100%" }

interface MenuListProps {
	onClick?: () => void
}

export const MenuList = ({ onClick }: MenuListProps) => {
	const { t } = useTranslation()

	const navigate = useNavigate()

	const { policies, user, rootPath } = useAuthentication()

	const [openRegister, setOpenRegister] = useState(true)
	const [openServices, setOpenServices] = useState(false)
	const [openConsumption, setOpenConsumption] = useState(false)
	const [selected, setSelected] = useState<any>(rootPath)

	useEffect(() => {
		const pathName = window.location.pathname
		setSelected(pathName)
	}, [])

	const handleClickRegister = () => {
		setOpenRegister(!openRegister)
	}

	const handleClickServices = () => {
		setOpenServices(!openServices)
	}

	const handleClickConsumption = () => {
		setOpenConsumption(!openConsumption)
	}

	const handlePage = (selected: string, path: string) => {
		navigate(path)
		setSelected(path)
		if (onClick) onClick()
	}

	return (
		<List sx={sxListConfig} component="nav" aria-labelledby="nested-list-subheader">
			<MenuItem
				allowedRoles={[Roles.Admin, Roles.Client]}
				role={user?.role}
				icon={<TrendingUpIcon />}
				label={t("menu.consumption")}
				onClick={handleClickConsumption}
				expanded={openConsumption}
			>
				<MenuItem
					allowedRoles={[Roles.Admin, Roles.Client]}
					role={user?.role}
					selected={selected === pathNames.consumption.dashboard}
					label={t("consumption.dashboard")}
					onClick={() => handlePage("consumption", pathNames.consumption.dashboard)}
				/>

				<MenuItem
					allowedRoles={[Roles.Admin]}
					role={user?.role}
					selected={selected === pathNames.consumption.dataExtraction}
					label={t("consumption.data_extraction")}
					onClick={() => handlePage("data-extraction", pathNames.consumption.dataExtraction)}
				/>

				<MenuItem
					allowedRoles={[Roles.Admin]}
					role={user?.role}
					selected={selected === pathNames.consumption.invoicing}
					label={t("menu.invoicing")}
					onClick={() => handlePage("invoicing", pathNames.consumption.invoicing)}
				/>
			</MenuItem>
			<MenuItem
				icon={<ApiIcon />}
				label={t("menu.services")}
				onClick={handleClickServices}
				expanded={openServices}
				show
			>
				<MenuItem
					show={policies?.projects.read}
					selected={selected === pathNames.project.list}
					label={t("menu.projects")}
					onClick={() => handlePage("projects", pathNames.project.list)}
				/>

				<MenuItem
					allowedRoles={[Roles.Admin]}
					role={user?.role}
					selected={selected === pathNames.service.list}
					label={t("menu.registers")}
					onClick={() => handlePage("services", pathNames.service.list)}
				/>

				<MenuItem
					allowedRoles={[Roles.Admin]}
					role={user?.role}
					show={policies?.services.read}
					selected={selected === pathNames.services.advancedSearch}
					label={t("menu.advanced_search")}
					onClick={() => handlePage("advanced-search", pathNames.services.advancedSearch)}
				/>

				<MenuItem
					allowedRoles={[Roles.Admin]}
					role={user?.role}
					show={policies?.services.read}
					selected={selected === pathNames.services.obitoDatabaseUpdate.list}
					label={t("menu.update_obito_database")}
					onClick={() =>
						handlePage("update-obito-database", pathNames.services.obitoDatabaseUpdate.list)
					}
				/>

				<MenuItem
					selected={selected === pathNames.queries.listBatch}
					label={t("menu.batch_service_query")}
					onClick={() => handlePage("batch_service_query", pathNames.queries.listBatch)}
					show
				/>

				<MenuItem
					selected={selected === pathNames.lab.root}
					label={t("menu.lab_api")}
					onClick={() => handlePage("lab_api", pathNames.lab.root)}
					show
				/>
			</MenuItem>
			<MenuItem
				allowedRoles={[Roles.Admin, Roles.Client]}
				role={user?.role}
				icon={<GroupIcon />}
				label={t("menu.accesses")}
				onClick={handleClickRegister}
				expanded={openRegister}
			>
				<MenuItem
					allowedRoles={[Roles.Admin]}
					role={user?.role}
					selected={selected === pathNames.admin.list}
					label={t("menu.admins")}
					onClick={() => handlePage("admins", pathNames.admin.list)}
				/>

				<MenuItem
					selected={selected === pathNames.organization.list}
					label={t("menu.organizations")}
					onClick={() => handlePage("organizations", pathNames.organization.list)}
					show={policies?.organizations?.read}
				/>

				<MenuItem
					selected={selected === pathNames.customer.list}
					label={t("menu.customers")}
					onClick={() => handlePage("customers", pathNames.customer.list)}
					show={policies?.customers?.read}
				/>

				<MenuItem
					selected={selected === pathNames.team.list}
					label={t("menu.teams")}
					onClick={() => handlePage("teams", pathNames.team.list)}
					show={policies?.teams?.read}
				/>

				<MenuItem
					selected={selected === pathNames.users.user.list}
					label={t("menu.users")}
					onClick={() => handlePage("users", pathNames.users.user.list)}
					show={policies?.users?.read}
				/>

				<MenuItem
					allowedRoles={[Roles.Admin]}
					role={user?.role}
					selected={selected === pathNames.apiKey.list}
					label={t("menu.api_keys")}
					onClick={() => handlePage("api-key", pathNames.apiKey.list)}
				/>

				<MenuItem
					selected={selected === "ips"}
					label={t("menu.ip_liberation")}
					onClick={() => handlePage("ips", pathNames.settings.ip_liberation.list)}
					show={policies?.organizationIpRange?.read}
				/>

				<MenuItem
					selected={selected === pathNames.logs}
					label={t("menu.logs")}
					onClick={() => handlePage("logs", pathNames.logs)}
					show={policies?.users?.read}
				/>
			</MenuItem>
		</List>
	)
}
