import { CardActionArea, Grid } from "@mui/material"
import Card from "@mui/material/Card"
import CardContent from "@mui/material/CardContent"
import CardMedia from "@mui/material/CardMedia"
import Typography from "@mui/material/Typography"

import { BodyPage, ContainerPage } from "../../components/container/container.styles"
import { HeaderPage } from "../../components/header/page/header-page"

const fraudeServiceImage = require("../../assets/images/fraude-service.png")
const obitoServiceImage = require("../../assets/images/obito-service.png")
const receitaServiceImage = require("../../assets/images/receita-service.png")

export const Services = () => {
	return (
		<ContainerPage>
			<HeaderPage
				title={"Serviços"}
				searchHide={true}
				description={"Soluções da Plataforma Resolv Risk 2.0"}
				isCreateButtonVisible={false}
			/>
			<BodyPage>
				<Grid container gap={"16px"}>
					<Card sx={{ maxWidth: 345 }}>
						<CardActionArea disabled={true}>
							<CardMedia
								component="img"
								height="140"
								image={fraudeServiceImage}
								alt="green iguana"
							/>
							<CardContent>
								<Typography gutterBottom variant="h5" component="div">
									Fraude Data
								</Typography>
								<Typography variant="body2" color="text.secondary">
									Nossa solução para mitigar riscos associados à data de nascimento com intenção de
									fraudar benefícios em concessões de crédito.
								</Typography>
							</CardContent>
						</CardActionArea>
					</Card>
					<Card sx={{ maxWidth: 345 }}>
						<CardActionArea disabled={true}>
							<CardMedia
								component="img"
								height="140"
								image={obitoServiceImage}
								alt="green iguana"
							/>
							<CardContent>
								<Typography gutterBottom variant="h5" component="div">
									Consulta Óbito
								</Typography>
								<Typography variant="body2" color="text.secondary">
									Nossa solução para mitigar riscos de fraudes, disponibilizando os dados de
									falecidos, com abrangência nacional, em um mesmo banco de dados.
								</Typography>
							</CardContent>
						</CardActionArea>
					</Card>
					<Card sx={{ maxWidth: 345 }}>
						<CardActionArea disabled={true}>
							<CardMedia
								component="img"
								height="140"
								image={receitaServiceImage}
								alt="green iguana"
							/>
							<CardContent>
								<Typography gutterBottom variant="h5" component="div">
									Consulta Receita Federal
								</Typography>
								<Typography variant="body2" color="text.secondary">
									Nossa solução para mitigar riscos de fraudes e irregularidades no CPF em tempo
									real.{" "}
								</Typography>
							</CardContent>
						</CardActionArea>
					</Card>
					<Card sx={{ maxWidth: 345 }}>
						<CardActionArea disabled={true}>
							<CardMedia
								component="img"
								height="140"
								image={
									"https://s22908.pcdn.co/wp-content/uploads/2022/04/how-to-choose-a-security-question.jpg"
								}
								alt="green iguana"
							/>
							<CardContent>
								<Typography gutterBottom variant="h5" component="div">
									Quiz
								</Typography>
								<Typography variant="body2" color="text.secondary">
									Nossa solução para minimizar fraudes durante cadastro.
								</Typography>
							</CardContent>
						</CardActionArea>
					</Card>
				</Grid>
			</BodyPage>
		</ContainerPage>
	)
}
