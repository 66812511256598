import React, { useContext, useState } from "react"
import { useTranslation } from "react-i18next"
import { useLocation, useNavigate } from "react-router-dom"

import { FileCopyOutlined, Logout } from "@mui/icons-material"
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd"
import MenuIcon from "@mui/icons-material/Menu"
import Person2Icon from "@mui/icons-material/Person2"
import ScienceIcon from "@mui/icons-material/Science"
import {
	Avatar,
	Box,
	Divider,
	IconButton,
	ListItemIcon,
	Menu,
	MenuItem,
	MenuList,
	Typography,
} from "@mui/material"

import packageJson from "../../../../package.json"
import { GlobalContext } from "../../../contexts/global-provider"
import { ACTIONS_TYPES } from "../../../contexts/reducer"
import { useAuthentication } from "../../../hooks/app/useAuthentication"
import { pathNames } from "../../../routes/paths"
import { AuthStorage } from "../../../storage/auth-type"
import { setItem } from "../../../storage/local-storage"
import { capitalizeFirstLetter } from "../../../utils/format"
import TemporaryDrawer from "../../menu/main/menu-mobile"
import { SelectCountry } from "../../select/country/select-country"
import { MaterialUISwitch } from "../../switch-mode/switch-mode"
import {
	Button as ButtonStyle,
	Container,
	ContainerMenu,
	IconButton as IconButtonStyle,
	Logo,
	LogoDark,
	Profile,
} from "../header.styles"

type MenuList = "platform" | "lab"

const HeaderLogged = () => {
	const { pathname } = useLocation()

	const { dispatch, light } = useContext(GlobalContext)

	const [showDrawer, setShowDrawer] = useState<boolean>(false)
	const [selectedMenu, setSelectedMenu] = useState<MenuList>("platform")

	const { t } = useTranslation()

	const navigate = useNavigate()

	const { user, rootPath } = useAuthentication()

	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
	const open = Boolean(anchorEl)

	const handleClickTopMenu = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget)
	}

	const handleClose = () => {
		setAnchorEl(null)
	}

	const openMenu = () => {
		setShowDrawer(!showDrawer)
	}

	const openDocs = () => {
		window.open("https://documenter.getpostman.com/view/27472588/2s93kz4jT2", "_blank")
	}

	const handleChangeDarkMode = (event: any) => {
		const value = event.target.checked
		setItem(AuthStorage.mode, { mode: value })
		dispatch({ type: ACTIONS_TYPES.SET_MODE, value })
	}

	const navigateToLab = () => {
		navigate(pathNames.lab.root)
		setSelectedMenu("lab")
	}

	const navigateToPlatform = () => {
		navigate(rootPath)
		setSelectedMenu("platform")
	}

	const signOutRedirect = () => {
		navigate(pathNames.auth.signOut), setItem(AuthStorage.mode, { mode: true })
		dispatch({ type: ACTIONS_TYPES.SET_MODE, value: true })
	}

	return (
		<Container>
			<ContainerMenu>
				<IconButtonStyle onClick={openMenu}>
					<MenuIcon fontSize={"large"} />
				</IconButtonStyle>
				{!light ? (
					<LogoDark onClick={() => navigateToPlatform()} />
				) : (
					<Logo onClick={() => navigateToPlatform()} />
				)}
				<ButtonStyle
					$selected={!pathname.includes("lab")}
					onClick={navigateToPlatform}
					variant="text"
					color={"secondary"}
				>
					{t("home.platform")}
				</ButtonStyle>
				<ButtonStyle
					$selected={pathname.includes("lab")}
					endIcon={<ScienceIcon />}
					onClick={navigateToLab}
					variant="text"
					color={"secondary"}
				>
					{t("home.sandbox_menu")}
				</ButtonStyle>
			</ContainerMenu>
			<Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
				<Profile>
					<Typography
						color="textPrimary"
						fontWeight={"bold"}
						lineHeight={1}
						fontSize={15}
						variant={"subtitle1"}
					>
						{user?.userName}
					</Typography>
					<Typography color="textPrimary" variant={"caption"}>
						{capitalizeFirstLetter(user?.role || "Resolv")}
					</Typography>
					<Typography color={"GrayText"} marginTop={-0.5} fontSize={10} variant={"caption"}>
						{t("header.version")} {packageJson.version}
					</Typography>
				</Profile>
				<IconButton
					onClick={handleClickTopMenu}
					size="small"
					aria-controls={open ? "account-menu" : undefined}
					aria-haspopup="true"
					aria-expanded={open ? "true" : undefined}
					style={{ marginLeft: 8 }}
				>
					<Avatar variant={"rounded"} sx={styles.avatar}>
						{capitalizeFirstLetter(user?.userName ? user?.userName[0] : "R")}
					</Avatar>
				</IconButton>
				<MaterialUISwitch onChange={handleChangeDarkMode} sx={{ m: 1 }} defaultChecked={light} />
				<SelectCountry />
			</Box>
			<Menu
				anchorEl={anchorEl}
				id="account-menu"
				open={open}
				onClose={() => handleClose()}
				onClick={() => handleClose()}
			>
				<MenuItem onClick={() => navigate(pathNames.profile)}>
					<ListItemIcon>
						<Person2Icon fontSize="small" />
					</ListItemIcon>
					{t("header.profile")}
				</MenuItem>
				<Divider />
				{user?.role === "client" && (
					<>
						<MenuItem onClick={() => navigate(pathNames.profileOrganization)}>
							<ListItemIcon>
								<AssignmentIndIcon fontSize="small" />
							</ListItemIcon>
							{t("header.organization")}
						</MenuItem>
						<Divider />
					</>
				)}
				<MenuItem onClick={openDocs}>
					<ListItemIcon>
						<FileCopyOutlined fontSize="small" />
					</ListItemIcon>
					{t("common.docs")}
				</MenuItem>
				<Divider />
				<MenuItem onClick={() => signOutRedirect()}>
					<ListItemIcon>
						<Logout fontSize="small" />
					</ListItemIcon>
					{t("header.exit")}
				</MenuItem>
			</Menu>
			{user && (
				<TemporaryDrawer openDrawer={showDrawer} callbackDrawer={(state) => setShowDrawer(state)} />
			)}
		</Container>
	)
}

const styles = {
	avatar: { width: 36, height: 36, bgcolor: "#16998cc4" },
}

export default HeaderLogged
