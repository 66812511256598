import { AxiosResponse } from "axios"

import { APIResponse } from "../models/API"
import { Client } from "../models/Client"
import axios from "./config/axios"

export const listAllUsers = async ({ currentPage, pageSize, filter, active }: any) =>
	await axios.post("/users/search", { currentPage, pageSize, filter, active })

export const searchSpecificUser = async (
	userId: any,
): Promise<AxiosResponse<APIResponse<Client>>> => await axios.get(`/users/${userId}`)

export const createUser = async (user: any) => await axios.post("/users", user)

export const updateUser = async (userId: any, userData: any) =>
	await axios.put(`/users/${userId}`, userData)

export const deleteUser = async (userId: string) => await axios.delete(`/users/${userId}`)

export const disableUsers = async (users: Array<string>) =>
	await axios.put(`/users/disable`, { userIds: users })
