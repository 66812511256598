import { useEffect, useState } from "react"
import { FormProvider, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useNavigate, useParams } from "react-router-dom"

import { FormControlLabel, FormGroup, Grid, Switch, Typography } from "@mui/material"

import { searchSpecificAdmin, updateAdmin } from "../../apis/admin"
import { BodyPage, ContainerPage } from "../../components/container/container.styles"
import DialogAlert from "../../components/dialog/dialog"
import { HeaderPage } from "../../components/header/page/header-page"
import { Loading } from "../../components/loading/loading"
import { ControlledTextField } from "../../components/text-field/controlled-text-field"
import { pathNames } from "../../routes/paths"
import { styles } from "../../styles/common"
import { createErrorMessage } from "../../utils/format"

export const EditAdmin = () => {
	const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false)
	const [active, setActive] = useState<boolean>(true)

	const [loading, setLoading] = useState<boolean>(false)

	const [dialog, setDialog] = useState<boolean>(false)
	const [dialogTitle, setDialogTitle] = useState<any>("")
	const [dialogDescription, setDialogDescription] = useState<any>("")

	const [isError, setIsError] = useState<boolean>(false)

	const navigate = useNavigate()
	const { t } = useTranslation()

	const { adminId } = useParams()

	const methods = useForm()
	const {
		control,
		getValues,
		reset,
		formState: { isValid },
	} = methods

	useEffect(() => {
		fetchData()
		// eslint-disable-next-line
	}, [])

	const fetchData = async () => {
		try {
			const {
				data: { data },
			} = await searchSpecificAdmin(adminId)
			reset(data)
			setActive(data?.active)
		} catch (error) {}
	}

	const handleSubmit = async () => {
		const formValues = getValues()
		setIsError(true)
		if (isValid) {
			try {
				await updateAdmin(adminId, { ...formValues, active })
				setDialogTitle(t("request_messages.edit_success_title", { name: "Administrador" }))
				setDialogDescription(
					t("request_messages.edit_success_description", { name: "Administrador" }),
				)
			} catch (error: any) {
				setIsError(false)
				setDialogTitle(t("request_messages.500"))
				if (error?.response?.data?.messages) {
					setDialogDescription(createErrorMessage(error?.response?.data?.messages))
				} else {
					setDialogDescription(t("request_messages.400"))
				}
			} finally {
				setDialog(true)
				setLoading(false)
			}
		}
	}

	const handleAccept = () => {
		setDialog(false)
		if (!isError) {
			navigate(pathNames.admin.list)
		}
	}

	if (loading) return <Loading />

	return (
		<ContainerPage>
			<HeaderPage
				onClickSubmit={handleSubmit}
				onClickTopButton={() => navigate(pathNames.admin.list)}
				description={t("user.edit_admin_description")}
				isEdit={true}
				disabledButton={!isValid}
				title={t("user.edit_admin_title")}
			/>
			<BodyPage>
				<FormProvider {...methods}>
					<Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
						<Grid item xs={10}>
							<ControlledTextField
								id={"email"}
								style={styles.fullWidth}
								label={t("user.label_email")}
								control={control}
								rules={{ required: true }}
							/>
						</Grid>
						<Grid style={styles.active} item xs={2}>
							<FormGroup>
								<FormControlLabel
									control={
										<Switch
											onChange={(e) =>
												!e.target.checked ? setShowDeleteDialog(true) : setActive(e.target.checked)
											}
											color={"secondary"}
											checked={active}
										/>
									}
									label={
										<Typography color={"secondary"}>
											{active ? t("status.active") : t("status.inactive")}
										</Typography>
									}
								/>
							</FormGroup>
						</Grid>
					</Grid>
				</FormProvider>
			</BodyPage>
			<DialogAlert
				title={t("admin.delete_message_title")}
				description={t("admin.delete_message_description", { admin: getValues("email") })}
				show={showDeleteDialog}
				accept={() => {
					setActive(false)
					setShowDeleteDialog(false)
				}}
				decline={() => {
					setShowDeleteDialog(false)
					setActive(true)
				}}
			/>
			<DialogAlert
				onlyConfirm={true}
				show={dialog}
				accept={handleAccept}
				title={dialogTitle}
				description={dialogDescription}
			/>
		</ContainerPage>
	)
}
