import { ReactNode } from "react"
import { useTranslation } from "react-i18next"

import AddIcon from "@mui/icons-material/Add"
import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import TuneIcon from "@mui/icons-material/Tune"
import {
	Button,
	FormControl,
	Grid,
	InputLabel,
	MenuItem,
	Select,
	SelectChangeEvent,
	SxProps,
	Typography,
} from "@mui/material"
import { Box } from "@mui/system"
import { DatePicker } from "@mui/x-date-pickers"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import dayjs, { Dayjs } from "dayjs"

import { MultiSelectData } from "../../../apis/dashboard"
import MultiSelect from "../../../pages/consumption/multiselect"
import { AuthStorage } from "../../../storage/auth-type"
import { getItem } from "../../../storage/local-storage"
import { GroupButton } from "../../button-group/button-group"
import { Row, TextField, TitleHeader } from "./header-page.styles"

export type CSVReportOption =
	| "consumptionMonthByDay"
	| "consumptionYearByMonth"
	| "requestsByProduct"
	| "processingTypeConsumption"
	| "dailyProductConsumption"
	| "evolutiveClientConsumption"
	| "classifiedConsumption"
	| "consumptionSummary"
	| "generalConsumptionSummaryByUserAndOrganization"

type FirstLineMultiSelect =
	| {
			hasFirstLineMultiselect: true
			firstLineMultiselectItems: MultiSelectData["data"]
			onFirstLineMultiSelectChange: (event: SelectChangeEvent) => void
			onDeleteFirstLineMultiselect: (event: any) => void
			selectedFirstLineItems: string[]
			firstLineMultiSelectTitle: string
	  }
	| {
			hasFirstLineMultiselect?: false
			firstLineMultiselectItems?: MultiSelectData["data"]
			onFirstLineMultiSelectChange?: (event: SelectChangeEvent) => void
			onDeleteFirstLineMultiselect?: (event: any) => void
			selectedFirstLineItems?: string[]
			firstLineMultiSelectTitle?: string
	  }

type SecondLineMultiselect =
	| {
			hasMultiselect: true
			multiSelectData?: { name: string; id: string }[]
			onChangeMultiselect: (event: SelectChangeEvent) => void
			onDeleteMultiselect: (event: any) => void
			selectedNames: string[]
			multiSelectTitle?: string
	  }
	| {
			hasMultiselect?: false
			multiSelectData?: { name: string; id: string }[]
			onChangeMultiselect?: (event: SelectChangeEvent) => void
			onDeleteMultiselect?: (event: any) => void
			selectedNames?: string[]
			multiSelectTitle?: string
	  }

type HeaderPageProps = {
	children?: ReactNode
	isCreateButtonVisible?: boolean
	title: string
	buttonTitle?: any
	description?: any
	onClickTopButton?: () => void
	onClickSubmit?: () => void
	onSearch?: (event: any) => void
	onOpenFilter?: () => void
	isEdit?: boolean
	isGroupButton?: boolean
	hasCreate?: boolean
	disabledButton?: boolean
	searchValue?: string
	isChartVisible?: boolean
	searchHide?: boolean
	searchPlaceholder?: string
	valueFilterConsumption?: string
	onChangeFilterConsumption?: (event: any) => void
	onChangeDate?: (event: any) => void
	organizations?: Array<any>
	firstLineMultiselect?: boolean
	isFilterConsumptionShow?: boolean
	buttonAttrs?: any
	actionButtonTitle?: any
	hasEndDate?: boolean
	hasMultiselect?: boolean
	hasReportType?: boolean
	reportType?: CSVReportOption
	onChangeReportType?: (event: SelectChangeEvent) => void
	customTitleStyles?: SxProps
	showDownloadButton?: boolean
	defaultStartDate?: Dayjs
	defaultEndDate?: Dayjs
	hideSaveButton?: boolean
	topButton?: ReactNode
	hasFilterHeader?: boolean
} & (
	| { hasEndDate: true; onChangeEndDate: (event: any) => void }
	| { hasEndDate?: false; onChangeEndDate?: (event: any) => void }
) &
	FirstLineMultiSelect &
	SecondLineMultiselect

export const HeaderPage = ({
	isCreateButtonVisible = true,
	title,
	description = "",
	hasCreate = true,
	isEdit = false,
	buttonTitle,
	actionButtonTitle,
	isGroupButton = false,
	onClickTopButton,
	onClickSubmit,
	onSearch,
	onOpenFilter,
	disabledButton = false,
	searchValue = "",
	isChartVisible = false,
	searchHide = false,
	hasFilterHeader = false,
	searchPlaceholder = "",
	valueFilterConsumption = "byTeams",
	onChangeFilterConsumption,
	onChangeDate,
	firstLineMultiselectItems = [],
	onDeleteFirstLineMultiselect,
	onFirstLineMultiSelectChange,
	hasFirstLineMultiselect,
	selectedFirstLineItems,
	firstLineMultiSelectTitle,
	isFilterConsumptionShow,
	buttonAttrs = {},
	hasEndDate,
	onChangeEndDate,
	hasMultiselect,
	multiSelectData,
	onDeleteMultiselect,
	onChangeMultiselect,
	selectedNames,
	multiSelectTitle,
	reportType,
	hasReportType,
	onChangeReportType,
	customTitleStyles,
	defaultStartDate,
	defaultEndDate,
	hideSaveButton,
	topButton,
	children,
}: HeaderPageProps) => {
	const { t } = useTranslation()

	const { dashboard } = getItem(AuthStorage.userPolicy)

	const typedReportTypeOptionTranslation = {
		consumptionMonthByDay: t("consumption.consumptionMonthByDay"),
		consumptionYearByMonth: t("consumption.consumptionYearByMonth"),
		requestsByProduct: t("consumption.requestsByProduct"),
		processingTypeConsumption: t("consumption.processingTypeConsumption"),
		dailyProductConsumption: t("consumption.dailyProductConsumption"),
		evolutiveClientConsumption: t("consumption.evolutiveClientConsumption"),
		classifiedConsumption: t("consumption.classifiedConsumption"),
		consumptionSummary: t("consumption.consumptionSummary"),
		generalConsumptionSummaryByUserAndOrganization: t(
			"consumption.generalConsumptionSummaryByUserAndOrganization",
		),
	}

	return (
		<Box style={{ marginBottom: "1rem" }}>
			<Row>
				<TitleHeader sx={customTitleStyles} color={"secondary"}>
					{title}
				</TitleHeader>
				{hasFilterHeader && (
					<div>
						<Button
							color="secondary"
							style={{ textTransform: "initial" }}
							onClick={onOpenFilter}
							variant={"text"}
							startIcon={<TuneIcon />}
						>
							Filtros
						</Button>
					</div>
				)}

				{!isGroupButton && hasCreate
					? isCreateButtonVisible &&
						!topButton && (
							<Button
								color="secondary"
								style={{ textTransform: "initial" }}
								onClick={onClickTopButton}
								variant={!isEdit ? "outlined" : "text"}
								startIcon={!isEdit ? <AddIcon /> : <ArrowBackIcon />}
							>
								{!isEdit ? buttonTitle : t("headerPage.back")}
							</Button>
						)
					: hasCreate && <GroupButton />}
				{topButton}
			</Row>
			<Row>
				{description && (
					<Typography color="textPrimary" variant={"body2"}>
						{description}
					</Typography>
				)}

				{children ? (
					children
				) : !isEdit ? (
					<>
						{!isChartVisible ? (
							!searchHide && (
								<>
									<TextField
										label={searchPlaceholder ?? t("headerPage.search")}
										value={searchValue}
										onChange={onSearch}
										autoComplete="off"
										color="secondary"
										size="small"
										id="filled-basic"
										variant="filled"
									/>
									<div>
										<Button
											color="secondary"
											style={{ textTransform: "initial" }}
											onClick={onOpenFilter}
											variant={"text"}
											startIcon={<TuneIcon />}
										>
											Filtros
										</Button>
									</div>
								</>
							)
						) : (
							<Grid container spacing={1} columns={12}>
								<Grid item xs={6} sm={2} md={2}>
									<LocalizationProvider dateAdapter={AdapterDayjs}>
										<DatePicker
											sx={{ width: "100%" }}
											onChange={onChangeDate}
											defaultValue={defaultStartDate || dayjs(new Date())}
											views={["year", "month", "day"]}
											label={hasEndDate ? t("headerPage.start_date") : t("headerPage.date")}
											format="DD/MM/YYYY"
											openTo="year"
										/>
									</LocalizationProvider>
								</Grid>

								{hasEndDate ? (
									<Grid item xs={6} sm={2} md={2}>
										<LocalizationProvider dateAdapter={AdapterDayjs}>
											<DatePicker
												sx={{ width: "100%" }}
												onChange={onChangeEndDate}
												defaultValue={defaultEndDate || dayjs(new Date())}
												views={["year", "month", "day"]}
												label={t("headerPage.end_date")}
												format="DD/MM/YYYY"
												openTo="year"
												className="full-width"
											/>
										</LocalizationProvider>
									</Grid>
								) : null}

								{hasReportType ? (
									<Grid item xs={12} sm={4} md={4}>
										<FormControl style={{ width: "100%" }} required>
											<InputLabel id="demo-simple-select-label">Relatório</InputLabel>
											<Select
												labelId="demo-simple-select-label"
												id="demo-simple-select"
												value={reportType}
												label="Relatório"
												onChange={onChangeReportType}
											>
												{Object.entries(typedReportTypeOptionTranslation).map(
													([key, value], index) => (
														<MenuItem key={index} value={key}>
															{value}
														</MenuItem>
													),
												)}
											</Select>
										</FormControl>
									</Grid>
								) : null}

								{hasFirstLineMultiselect ? (
									<Grid item xs={12} sm={4} md={hasReportType ? 2 : 4}>
										<MultiSelect
											data={firstLineMultiselectItems}
											onChange={onFirstLineMultiSelectChange}
											onDelete={onDeleteFirstLineMultiselect}
											selectedNames={selectedFirstLineItems}
											title={firstLineMultiSelectTitle}
										/>
									</Grid>
								) : null}

								{hasMultiselect && multiSelectData?.length ? (
									<Grid item xs={12} sm={4} md={hasReportType ? 2 : 4}>
										{multiSelectData?.length > 0 ? (
											<MultiSelect
												data={multiSelectData}
												onChange={onChangeMultiselect}
												onDelete={onDeleteMultiselect}
												selectedNames={selectedNames}
												title={multiSelectTitle}
											/>
										) : null}
									</Grid>
								) : null}

								{isFilterConsumptionShow && (
									<Grid item xs={12} sm={4} md={4}>
										<FormControl style={{ width: "100%" }}>
											<InputLabel id="demo-simple-select-label">
												{t("consumption.filter")}
											</InputLabel>
											<Select
												labelId="demo-simple-select-label"
												id="demo-simple-select"
												value={valueFilterConsumption}
												label="Filtro"
												onChange={onChangeFilterConsumption}
											>
												{dashboard?.byTeams && (
													<MenuItem value={"byTeams"}>{t("consumption.byTeams")}</MenuItem>
												)}
												{dashboard?.byOrganizationServices && (
													<MenuItem value={"byOrganizationServices"}>
														{t("consumption.byOrganizationServices")}
													</MenuItem>
												)}
												{dashboard?.byTeamUsers && (
													<MenuItem value={"byTeamUsers"}>{t("consumption.byTeamUsers")}</MenuItem>
												)}
												{dashboard?.byTeamServices && (
													<MenuItem value={"byTeamServices"}>
														{t("consumption.byTeamServices")}
													</MenuItem>
												)}
												{dashboard?.byOrganizationUsers && (
													<MenuItem value={"byOrganizationUsers"}>
														{t("consumption.byOrganizationUsers")}
													</MenuItem>
												)}
												{dashboard?.byOrganizationTeamsServices && (
													<MenuItem value={"byOrganizationTeamsServices"}>
														{t("consumption.byOrganizationTeamsServices")}
													</MenuItem>
												)}
											</Select>
										</FormControl>
									</Grid>
								)}
							</Grid>
						)}
					</>
				) : !hideSaveButton ? (
					<Button
						disabled={disabledButton}
						type="submit"
						color="secondary"
						style={{ textTransform: "initial", margin: "0 16px" }}
						onClick={onClickSubmit}
						variant={"outlined"}
						{...buttonAttrs}
						hidden={true}
					>
						{actionButtonTitle || t("headerPage.save_information")}
					</Button>
				) : null}
			</Row>
		</Box>
	)
}
