import styled from "styled-components"

export const Select = styled.select(({ theme }) => ({
	border: "none",
	padding: "5px",
	color: theme.palette.text.primary,
	background: "transparent",
	"&:focus": {
		outline: "none",
	},
}))
