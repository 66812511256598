const pt_services = [
	{
		name: "Consulta Óbito",
		description:
			"Inclua na sua esteira de concessão de crédito uma consulta que identifica e mitiga fraudes em nome de pessoas falecidas, utilizando os mais de 14 mil cartórios em nível nacional.",
		slogan: "Esteja preparado para uma nova jornada",
		id: "668dd068-c746-46ab-9dbb-c0a57f6146c6",
		image: "https://www.resolvrisk.com.br/wp-content/uploads/2021/08/consultanova.png",
		path: "/lab/consult-death",
	},
	{
		name: "Consulta Receita Federal",
		description:
			"Uma solução de consulta via Web Service (API), de forma online junto à Receita Federal com objetivo de realizar o apontamento de confirmação ou divergência da situação cadastral do CPF.",
		slogan: "Pronto para a jornada revolucionária?",
		id: "f15ed201-4914-46aa-af45-753e00890f7c",
		image:
			"https://www.resolvrisk.com.br/wp-content/uploads/2021/08/illustration-api-fraudedata.png",
		path: "/lab/revalidate-prescription",
	},
	{
		name: "Quiz",
		description:
			"Conheça o seu Score de Crédito e desbloqueie novas possibilidades financeiras. Com nossa consulta especializada, obtenha uma avaliação confiável e precisa da sua pontuação de crédito.",
		slogan: "Explore horizontes com esse poderoso produto",
		id: "f973a71d-f70f-44a3-8e69-2284add9393d",
		image:
			"https://s.tmimgcdn.com/scr/1600x1000/282400/mesa-de-pagamento-na-ilustracao-isolada-do-vetor-2d-do-supermercado_282454-original.jpg",
		path: "/lab/quiz",
	},
	{
		name: "Fraude data",
		description: "Nossa solução para mitigar riscos de divergência na data de nascimento.",
		slogan: "data fraude slogan",
		id: "8be27056-97cc-4310-b1a5-7139b9022c8f",
		image:
			"https://s.tmimgcdn.com/scr/1600x1000/282400/mesa-de-pagamento-na-ilustracao-isolada-do-vetor-2d-do-supermercado_282454-original.jpg",
		path: "/lab/date-fraud",
	},
]

const es_services = [
	{
		name: "Consulta de Muerte",
		description:
			"Incluye en tu proceso de otorgamiento de crédito una consulta que identifique y mitigue el fraude a nombre de personas fallecidas, utilizando más de 14 mil notarías a nivel nacional.",
		slogan: "Prepárate para un nuevo viaje",
		id: "668dd068-c746-46ab-9dbb-c0a57f6146c6",
		image: "https://www.resolvrisk.com.br/wp-content/uploads/2021/08/consultanova.png",
		path: "/lab/consult-death",
	},
	{
		name: "Consulta de Ingresos Federales",
		description:
			"Una solución de consulta vía Servicio Web (API), en línea con la Hacienda Federal con el objetivo de realizar una nota de confirmación o discrepancia sobre el estado del registro del CPF.",
		slogan: "¿Listo para el viaje revolucionario?",
		id: "f15ed201-4914-46aa-af45-753e00890f7c",
		image:
			"https://www.resolvrisk.com.br/wp-content/uploads/2021/08/illustration-api-fraudedata.png",
		path: "/lab/revalidate-prescription",
	},
	{
		name: "Prueba",
		description:
			"Conozca su puntaje crediticio y descubra nuevas posibilidades financieras. Con nuestra consulta de expertos, obtenga una evaluación confiable y precisa de su puntaje crediticio.",
		slogan: "Explora horizontes con este poderoso producto",
		id: "f973a71d-f70f-44a3-8e69-2284add9393d",
		image:
			"https://s.tmimgcdn.com/scr/1600x1000/282400/mesa-de-pagamento-na-ilustracao-isolada-do-vetor-2d-do-supermercado_282454-original.jpg",
		path: "/lab/quiz",
	},
	{
		name: "Fraude data",
		description: "Nossa solução para mitigar riscos de divergência na data de nascimento.",
		slogan: "data fraude slogan",
		id: "8be27056-97cc-4310-b1a5-7139b9022c8f",
		image:
			"https://s.tmimgcdn.com/scr/1600x1000/282400/mesa-de-pagamento-na-ilustracao-isolada-do-vetor-2d-do-supermercado_282454-original.jpg",
		path: "lab/date-fraud",
	},
]

export type ServiceProps = {
	name: string
	description: string
	slogan: string
	id: string
	image: string
	path: string
}

export enum SERVICES_IDS {
	consult_death = "668dd068-c746-46ab-9dbb-c0a57f6146c6",
	revalidate_prescription = "f15ed201-4914-46aa-af45-753e00890f7c",
	quiz = "f973a71d-f70f-44a3-8e69-2284add9393d",
	data_fraud = "8be27056-97cc-4310-b1a5-7139b9022c8f",
}

type LanguageOptions = "pt" | "es" | string

export const getServicesDataByLanguage = (language: LanguageOptions): Array<ServiceProps> => {
	if (language === "es") return es_services
	else return pt_services
}

export const getSpecificServiceByIdAndLanguage = (
	language: LanguageOptions,
	serviceId: string,
): ServiceProps => {
	const serviceLanguage = getServicesDataByLanguage(language)
	const serviceObject = serviceLanguage.filter((obj) => obj.id === serviceId)
	return serviceObject[0]
}

export { pt_services, es_services }
