import styled from "styled-components"

export const GraphContainer = styled.section<{ gap?: string }>`
	gap: ${(props) => props.gap ?? "10px"};
	justify-content: center;
	flex-direction: column;
	align-items: center;
	display: flex;
	height: auto;
`

export const GraphContent = styled.div`
	height: 270px;
	width: 100%;
`

export const GraphTitle = styled.h6<{ align?: "center" | "left" }>`
	text-align: ${(props) => props.align ?? "center"};
	margin: 10px 0 !important;
	font-size: 14px;
	width: 100%;
	color: ${({ theme: { palette } }) => (palette.mode === "dark" ? "#fff" : "#000")};
`

export const ContainerLegend = styled.div<{ justifyContent?: "center" | "flex-start" }>`
	justify-content: ${(props) => props.justifyContent ?? "center"};
	display: flex;
	align-items: center;
	gap: 10px;
	flex-wrap: wrap;
	cursor: pointer;
	width: 100%;
`

export const LegendContent = styled.div<{ $color?: boolean; isInactive?: boolean }>`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: min-content;
	border: solid 1px #ededed;
	border-left: ${(props) => `solid 3px ${props.color}`};
	padding: 5px;
	padding-right: 8px;
	border-radius: 3px;
	gap: 3px;
	opacity: ${(props) => (props.isInactive ? 0.25 : 1)};
`

export const LegendName = styled.strong`
	font-size: 12px;
	text-align: left;
	width: 100%;
	white-space: nowrap;
	color: ${({ theme: { palette } }) => (palette.mode === "dark" ? "#fff" : "#000")};
`

export const LegendDescription = styled.small`
	font-size: 10px;
	text-align: left;
	width: 100%;
	white-space: nowrap;
	color: ${({ theme: { palette } }) => (palette.mode === "dark" ? "#fff" : "#000")};
`
