import { Alert, Snackbar as SnackBarUI } from "@mui/material"

type SnackBarProps = {
	open: boolean
	handleClose: () => void
	message: string | string[] | null
	type: "error" | "success"
	vertical?: "top" | "bottom"
	horizontal?: "left" | "center" | "right"
	className?: string
	style?: Record<string, any>
}

export const SnackBar = ({
	open,
	handleClose,
	message,
	type,
	vertical = "bottom",
	horizontal = "right",
	className,
	style,
}: SnackBarProps) => (
	<SnackBarUI
		anchorOrigin={{ vertical, horizontal }}
		open={open}
		onClose={handleClose}
		autoHideDuration={6000}
		key={"snackbar"}
		className={className}
		style={style}
	>
		<Alert onClose={handleClose} severity={type} sx={{ width: "100%" }}>
			{Array.isArray(message) ? message.map((str, index) => <p key={index}>{str}</p>) : message}
		</Alert>
	</SnackBarUI>
)
