import { useEffect } from "react"
import { FormProvider } from "react-hook-form"

import { Alert, AlertTitle } from "@mui/material"

import { BodyPage, FormContainerPage } from "../../components/container/container.styles"
import DialogAlert from "../../components/dialog/dialog"
import { HeaderPage } from "../../components/header/page/header-page"
import { Loading } from "../../components/loading/loading"
import { useOrganization } from "../../hooks/app/organization/use-organization"
import { pathNames } from "../../routes/paths"
import { BillingDataSection } from "./components/billing-data-section"
import { OrganizationDataSection } from "./components/organization-data-section"
import { ServicesSection } from "./components/services-section"

export const CreateOrganization = () => {
	const {
		segments,
		methods,
		dialog,
		loading,
		dialogTitle,
		dialogDescription,
		t,
		fetchSegments,
		navigate,
		handleCreateOrganizationSubmit,
		handleSubmitionAccept,
	} = useOrganization()

	useEffect(() => {
		fetchSegments()
		methods.setValue("address.country", "Brazil")
	}, [])

	if (loading) return <Loading />

	return (
		<FormContainerPage onSubmit={handleCreateOrganizationSubmit}>
			<HeaderPage
				onClickTopButton={() => navigate(pathNames.organization.list)}
				description={t("organizations.create_organization_description")}
				isEdit={true}
				title={t("organizations.create_organization_title")}
			/>
			{!methods.formState.isValid && methods.formState.isSubmitted && (
				<Alert severity="error" style={{ marginBottom: 16 }}>
					<AlertTitle>Campo(s) invalido</AlertTitle>
					Corrija os campos inválidos antes de finalizar.
				</Alert>
			)}
			<BodyPage>
				<FormProvider {...methods}>
					<OrganizationDataSection segments={segments} />
					<BillingDataSection />
					<ServicesSection />
				</FormProvider>
			</BodyPage>
			<DialogAlert
				onlyConfirm={true}
				show={dialog}
				accept={handleSubmitionAccept}
				title={dialogTitle}
				description={dialogDescription}
			/>
		</FormContainerPage>
	)
}
