import React from "react"

import { ListItemButton } from "@mui/material"

interface SideMenuItemProps {
	isSelected?: boolean
	onClick: (e: React.MouseEvent) => void
	isDisabled?: boolean
	children: React.ReactNode
}

export const SideMenuItem = ({ isSelected, onClick, isDisabled, children }: SideMenuItemProps) => {
	return (
		<ListItemButton
			selected={isSelected}
			onClick={onClick}
			disabled={isDisabled}
			style={{
				display: "block",
			}}
		>
			{children}
		</ListItemButton>
	)
}
