import { useRef, useState } from "react"

import ClearIcon from "@mui/icons-material/Clear"
import CloudUploadIcon from "@mui/icons-material/CloudUpload"
import { Button, IconButton } from "@mui/material"
import styled from "styled-components"

import { Box, Container, Text } from "./file-upload.styles"

interface FileUploadProps {
	title: string
}

const VisuallyHiddenInput = styled("input")({
	clip: "rect(0 0 0 0)",
	clipPath: "inset(50%)",
	height: 1,
	overflow: "hidden",
	position: "absolute",
	bottom: 0,
	left: 0,
	whiteSpace: "nowrap",
	width: 1,
})

export const FileUpload = ({ title }: FileUploadProps) => {
	const ref = useRef(null)

	const [selectedFile, setSelectedFile] = useState(null)
	const [fileName, setFileName] = useState<string>("")

	const handleFileChange = (e: any) => {
		const file = e.target.files[0]
		if (file) {
			if (file.type.startsWith("image/")) {
				setSelectedFile(file)
				setFileName(file.name)
			} else {
				alert("Por favor, selecione uma imagem válida.")
			}
		}
	}

	const handleDelete = () => {
		setSelectedFile(null)
		setFileName("")
		// @ts-ignore
		document.getElementById("#fileInput").value = ""
	}

	return (
		<Container>
			<Button
				style={styles.buttonStyles}
				component={"label"}
				size={"large"}
				color={"secondary"}
				variant={"outlined"}
				startIcon={<CloudUploadIcon />}
			>
				{title}
				<VisuallyHiddenInput
					type="file"
					accept="image"
					id="fileInput"
					onChange={handleFileChange}
				/>
			</Button>
			{fileName && (
				<Box>
					<Text color={"GrayText"}>{fileName}</Text>
					<IconButton onClick={() => handleDelete()} aria-label="delete">
						<ClearIcon fontSize={"small"} color={"error"} />
					</IconButton>
				</Box>
			)}
		</Container>
	)
}

const styles = {
	buttonStyles: { marginTop: 8, height: 50, width: "100%" },
}
