import { Loading } from "../../../../../components/loading/loading"
import { Container } from "./styles"

interface APIExecuterRootProps {
	children: React.ReactNode
	isLoading?: boolean
}

export const APIExecuterRoot = ({ children, isLoading }: APIExecuterRootProps) => {
	if (isLoading) return <Loading />

	return <Container>{children}</Container>
}
