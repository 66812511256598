import {
	Checkbox,
	FormControl,
	InputLabel,
	ListItemText,
	MenuItem,
	OutlinedInput,
	Select,
	SelectChangeEvent,
} from "@mui/material"

const MultiSelect = ({
	data,
	onChange,
	onDelete,
	selectedNames,
	title,
}: {
	data?: { name: string; id: string }[]
	onChange: (event: SelectChangeEvent) => void
	onDelete: (event: any) => void
	selectedNames: string[]
	title?: string
}) => {
	const uniqueNames = Array.from(new Set(data?.map(({ name }) => name)))
	const uniqueIds = Array.from(new Set(data?.map(({ id }) => id)))

	const uniqueData = uniqueNames.map((name, index) => ({ name, id: uniqueIds[index] }))
	return (
		<FormControl sx={{ width: "100%" }}>
			<InputLabel required id={title}>
				{title ?? "Multiselect"}
			</InputLabel>
			<Select
				labelId={title}
				multiple
				value={selectedNames as unknown as string}
				onChange={onChange}
				input={<OutlinedInput label="Multiple Select" />}
				required
				renderValue={(selected: any) => selected.join(", ")}
			>
				{uniqueData?.map((option, index) => (
					<MenuItem key={index} value={option.name}>
						<Checkbox checked={selectedNames.includes(option.name)} />
						<ListItemText primary={option.name} />
					</MenuItem>
				))}
			</Select>
		</FormControl>
	)
}

export default MultiSelect
