import { Table, TableBody } from "@mui/material"

interface ResponseViewerTableProps {
	children: React.ReactNode
}

export const ResponseViewerTable = ({ children }: ResponseViewerTableProps) => {
	return (
		<Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
			<TableBody>{children}</TableBody>
		</Table>
	)
}
