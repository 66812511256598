import axios from "./config/axios"

export const resetApiKey = async (apiKey: string) => await axios.put(`/apikeys/refresh/${apiKey}`)

export const detailsApiKey = async (apiKey: string) =>
	await axios.get(`/apikeys/details`, {
		headers: {
			apikey: apiKey,
		},
	})

export const findApiKey = async (apiKeyId: string) => await axios.get(`/apikeys/${apiKeyId}`)

export const listAllAPIKeys = async ({ currentPage, pageSize, filter, active }: any) =>
	await axios.post("/apikeys/search", { currentPage, pageSize, filter, active })

export const updateAPIKey = async (apiKeyId: any, data: any) =>
	await axios.put(`/apikeys/${apiKeyId}`, data)

export const createAPIKey = async (data: any) => await axios.post("/apikeys", data)
