import { SyntheticEvent } from "react"

import { Tabs } from "@mui/material"

interface ResponseViewerTabsProps {
	children: React.ReactNode
	onTabChange: (e: SyntheticEvent, newValue: string) => void
	activeTab?: string | number
}

export const ResponseViewerTabs = ({
	children,
	onTabChange,
	activeTab,
}: ResponseViewerTabsProps) => {
	return (
		<div>
			<Tabs
				value={activeTab}
				onChange={onTabChange}
				indicatorColor={"primary"}
				textColor={"primary"}
				variant="fullWidth"
			>
				{children}
			</Tabs>
		</div>
	)
}
