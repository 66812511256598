import { SideMenuItem } from "./side-menu-item"
import { SideMenuItemContent } from "./side-menu-item-content"
import { SideMenuItemTip } from "./side-menu-item-tip"
import { SideMenuRoot } from "./side-menu-root"

export const SideMenu = {
	Root: SideMenuRoot,
	Item: SideMenuItem,
	ItemTip: SideMenuItemTip,
	ItemContent: SideMenuItemContent,
}
