import { useMemo, useState } from "react"
import { useTranslation } from "react-i18next"

import { Box, Button, Grid, Typography } from "@mui/material"

import { InvoicingData, getSigninUrlFile } from "../../../../apis/invoicing"
import { GenericDialogHistory } from "../../../../components/dialog-historic/generic-dialog-history"
import { HorizontaStepper } from "../../../../components/stepper/stepper"
import { invoiceBillingOptions } from "../../../../constants/options/invoice-billing"
import { useCurrency } from "../../../../hooks/app/useCurrency"
import { useDate } from "../../../../hooks/useDate"
import { month } from "../../../../utils/date"
import { handleApiExceptions } from "../../../../utils/handle-errors"
import { DialogApprove } from "./DialogApprove/dialog-approve"
import { Container, ContainerWidget, SubTitle, Title } from "./header-actions.styles"

interface HeaderActionsProps {
	refreshInvoicingData: () => void
	invoicing: InvoicingData | null
	onPDF: () => void
	isPrinting?: boolean
}

enum STEPPER_STATE {
	INVOICED = 1,
	APPROVED_OPERATIONAL = 2,
	APPROVED_FINANCE = 4,
}

export function HeaderActions({
	invoicing,
	refreshInvoicingData,
	onPDF,
	isPrinting,
}: HeaderActionsProps) {
	const { t } = useTranslation()
	const { formatCurrency } = useCurrency()

	const dialogDispatch = useState<boolean>(false)
	const [openDialogHistoric, setOpenDialogHistoric] = useState<boolean>(false)
	const { formatToBRDate } = useDate()

	const steps = useMemo(
		() => [
			t("consumption.invoicing.headerActions.status.invoiced"),
			invoicing?.operationalApproverId
				? t("consumption.invoicing.headerActions.status.operationalApproved")
				: t("consumption.invoicing.headerActions.status.operationalPending"),
			invoicing?.financialApproverId
				? t("consumption.invoicing.headerActions.status.financialApproved")
				: t("consumption.invoicing.headerActions.status.financialPending"),
			t("consumption.invoicing.headerActions.status.approved"),
		],
		[invoicing, t],
	)

	function handleMonthToString(date: string | undefined) {
		if (!date) return "-"
		const monthParsed = date?.split("/")
		if (!monthParsed.length) return "-"
		const [mm, yyyy] = monthParsed
		const monthStr = month.find(([key]) => Number(key) === Number(mm) - 1)?.[1]
		return `${monthStr} de ${yyyy}`
	}

	const downloadFile = async (fileUrl: string | undefined, extension: string) => {
		try {
			if (!fileUrl || !invoicing?.id) throw new Error("Url inválida")
			else {
				const { data } = await getSigninUrlFile(invoicing?.id, extension)
				if (data) window.open(data)
			}
		} catch (error) {
			handleApiExceptions(error)
		}
	}

	const historyHeaders = [
		t("consumption.invoicing.headerActions.historyHeaders.date"),
		t("consumption.invoicing.headerActions.historyHeaders.service"),
		t("consumption.invoicing.headerActions.historyHeaders.user"),
		t("consumption.invoicing.headerActions.historyHeaders.justification"),
		t("consumption.invoicing.headerActions.historyHeaders.priceDiff"),
		t("consumption.invoicing.headerActions.historyHeaders.anulledBatch"),
	]

	const historyRows = useMemo(() => {
		const services =
			invoicing?.monthlyData?.services.reduce((acc: { [key: string]: string }, service) => {
				acc[service.id] = service.name
				return acc
			}, {}) ?? {}
		return (
			invoicing?.invoiceChanges?.map((change) => [
				formatToBRDate(change.createdAt),
				services[change.serviceId as keyof typeof services] ?? "",
				change.createdByUser.email,
				change.justificative,
				change.value !== null ? (
					change.value > 0 ? (
						<Typography color={"primary.main"}>{formatCurrency(change.value)}</Typography>
					) : (
						<Typography color={"error.main"}>{formatCurrency(change.value)}</Typography>
					)
				) : (
					""
				),
				change?.anulledBatch?.originalFileName ?? "",
			]) ?? []
		)
	}, [invoicing])

	return (
		<Container>
			{!isPrinting && (
				<DialogApprove
					onSucces={refreshInvoicingData}
					dialogDispatch={dialogDispatch}
					invoicing={invoicing}
				/>
			)}
			{!isPrinting && (
				<HorizontaStepper
					activeStep={Number(STEPPER_STATE[invoicing?.status as keyof typeof STEPPER_STATE])}
					steps={steps}
				/>
			)}
			{!isPrinting && (
				<Box
					sx={{
						display: "flex",
						justifyContent: "flex-end",
						alignItems: "center",
						margin: "24px 0",
						gap: "12px",
					}}
				>
					<Button onClick={onPDF} color={"secondary"} variant="outlined" size="small">
						{t("consumption.invoicing.headerActions.downloadPDF")}
					</Button>
					<Button
						onClick={() => downloadFile(invoicing?.csvUrl, "csv")}
						disabled={!invoicing?.csvUrl}
						color={"secondary"}
						variant="outlined"
						size="small"
					>
						{t("consumption.invoicing.headerActions.downloadCSV")}
					</Button>
					<Button
						onClick={() => setOpenDialogHistoric(true)}
						disabled={!invoicing?.invoiceChanges?.length}
						color={"secondary"}
						variant="outlined"
						size="small"
					>
						{t("consumption.invoicing.headerActions.changesHistory")}
					</Button>
					{invoicing?.status !== "APPROVED_FINANCE" && (
						<Button
							onClick={() => dialogDispatch?.[1](true)}
							color={"secondary"}
							variant="outlined"
							size="small"
						>
							{invoicing?.operationalApproverId
								? t("consumption.invoicing.headerActions.financialApprove")
								: t("consumption.invoicing.headerActions.financialOperational")}
						</Button>
					)}
				</Box>
			)}
			<Box>
				<Grid container rowSpacing={2} columnSpacing={2}>
					<Grid item xs={12} sm={12} md={12} lg={6}>
						<ContainerWidget>
							<Title variant="h6">{t("consumption.invoicing.headerActions.client")}</Title>
							<SubTitle>{invoicing?.organization?.name ?? ""}</SubTitle>
						</ContainerWidget>
					</Grid>
					<Grid item xs={12} sm={6} lg={3}>
						<ContainerWidget>
							<Title variant="h6">{t("consumption.invoicing.headerActions.cnpj")}</Title>
							<SubTitle>
								{invoicing?.organization?.cnpj?.replace(
									/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
									"$1.$2.$3/$4-$5",
								) ?? ""}
							</SubTitle>
						</ContainerWidget>
					</Grid>
					<Grid item xs={12} sm={6} lg={3}>
						<ContainerWidget>
							<Title variant="h6">{t("consumption.invoicing.headerActions.email")}</Title>
							<SubTitle>{invoicing?.organization?.billingEmail ?? ""}</SubTitle>
						</ContainerWidget>
					</Grid>
					{!isPrinting && (
						<Grid item xs={12} sm={6}>
							<ContainerWidget>
								<Title variant="h6">
									{t("consumption.invoicing.headerActions.operationalApprovedBy")}
								</Title>
								<SubTitle>{invoicing?.operationalApprover?.email ?? "-"}</SubTitle>
							</ContainerWidget>
						</Grid>
					)}
					{!isPrinting && (
						<Grid item xs={12} sm={6}>
							<ContainerWidget>
								<Title variant="h6">
									{t("consumption.invoicing.headerActions.financialApprovedBy")}
								</Title>
								<SubTitle>{invoicing?.financialApprover?.email ?? "-"}</SubTitle>
							</ContainerWidget>
						</Grid>
					)}
					<Grid item xs={12} sm={6} lg={3}>
						<ContainerWidget>
							<Title variant="h6">{t("consumption.invoicing.headerActions.monthReference")}</Title>
							<SubTitle>{handleMonthToString(`${invoicing?.month}/${invoicing?.year}`)}</SubTitle>
						</ContainerWidget>
					</Grid>
					<Grid item xs={12} sm={6} lg={3}>
						<ContainerWidget>
							<Title variant="h6">{t("consumption.invoicing.headerActions.billingType")}</Title>
							<SubTitle>
								{invoiceBillingOptions.getLabel(invoicing?.organization?.billingRecorrence ?? "") ??
									""}
							</SubTitle>
						</ContainerWidget>
					</Grid>
					<Grid item xs={12} sm={6} lg={3}>
						<ContainerWidget>
							<Title variant="h6">
								{t("consumption.invoicing.headerActions.totalconsumption")}
							</Title>
							<SubTitle>
								{new Intl.NumberFormat("pt-BR").format(invoicing?.monthlyData?.total ?? 0)}
							</SubTitle>
						</ContainerWidget>
					</Grid>
					<Grid item xs={12} sm={6} lg={3}>
						<ContainerWidget>
							<Title variant="h6">{t("consumption.invoicing.headerActions.totalInvoiced")}</Title>
							<SubTitle>
								{Number(invoicing?.monthlyData?.price ?? 0).toLocaleString("pt-BR", {
									style: "currency",
									currency: "BRL",
								})}
							</SubTitle>
						</ContainerWidget>
					</Grid>
				</Grid>
			</Box>

			<GenericDialogHistory
				handleClose={() => setOpenDialogHistoric(false)}
				open={openDialogHistoric}
				headers={historyHeaders}
				rows={historyRows}
			></GenericDialogHistory>
		</Container>
	)
}
