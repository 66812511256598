import { useTranslation } from "react-i18next"
import { Divider, Grid, InputLabel, MenuItem, Select } from "@mui/material"
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers"
import { listOptionsOrganizations } from "../../../apis/organization"
import { handleApiExceptions } from "../../../utils/handle-errors"
import DialogContentText from "@mui/material/DialogContentText"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { MultiSelectData } from "../../../apis/dashboard"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import { Controller, useForm } from "react-hook-form"
import DialogTitle from "@mui/material/DialogTitle"
import FormControl from "@mui/material/FormControl"
import { styles } from "../../../styles/common"
import { useEffect, useState } from "react"
import Dialog from "@mui/material/Dialog"
import Button from "@mui/material/Button"
import { ActionContainer, FooterContainer } from "./filter.styles"

type FilterProps = {
	apply: (data: InvoicingFilterDataProps) => void
	clearFilter: () => void
	cancel: () => void
	show: boolean
}

type InvoicingFilterFormProps = {
	organizationId: string
	date: Date
}

export type InvoicingFilterDataProps = {
	organizationId: string
	date: Date
}

export const InvoicingFilter = ({
	show,
	apply,
	cancel,
	clearFilter: onClearFilter,
}: FilterProps) => {
	const { t } = useTranslation()

	const { handleSubmit, control, reset } = useForm<InvoicingFilterFormProps>()

	const handleClose = () => cancel()

	const [organizations, setOrganizations] = useState<MultiSelectData["data"]>([])

	const getOrganizations = async () => {
		try {
			const {
				data: { data },
			} = await listOptionsOrganizations()
			setOrganizations(data)
		} catch (error) {
			handleApiExceptions(error)
		}
	}

	function onSubmit(data: InvoicingFilterFormProps) {
		apply({
			organizationId: data.organizationId,
			date: data.date,
		})
		cancel()
	}

	function clearFilter() {
		onClearFilter()
		reset({
			organizationId: "",
			date: null!,
		})
		cancel()
	}

	useEffect(() => {
		getOrganizations()
	}, [])

	return (
		<Dialog
			open={show}
			onClose={handleClose}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
			maxWidth={"sm"}
			fullWidth={true}
		>
			<DialogTitle id="alert-dialog-title">{t("filter.filter_title")}</DialogTitle>
			<Divider />
			<form onSubmit={handleSubmit(onSubmit)}>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						<Grid container rowSpacing={2} columnSpacing={2}>
							<Grid item xs={12}>
								<Controller
									control={control}
									defaultValue=""
									name="organizationId"
									render={({ field: { onChange, name, value } }) => (
										<FormControl fullWidth>
											<InputLabel id="demo-simple-select-label">Organização</InputLabel>
											<Select
												style={styles.fullWidth}
												value={value}
												name={name}
												label={t("consumption.invoicing.organization")}
												onChange={onChange}
											>
												{organizations.map((organization) => (
													<MenuItem key={organization.id} value={organization.id}>
														{organization.name}
													</MenuItem>
												))}
											</Select>
										</FormControl>
									)}
								/>
							</Grid>
							<LocalizationProvider dateAdapter={AdapterDayjs}>
								<Grid item xs={12}>
									<Controller
										defaultValue={null!}
										control={control}
										name="date"
										render={({ field: { onChange, name, value } }) => (
											<DatePicker
												sx={styles.fullWidth}
												views={["year", "month"]}
												label={t("consumption.invoicing.month")}
												onChange={onChange}
												format="MM/YYYY"
												value={value}
												openTo="year"
												name={name}
											/>
										)}
									/>
								</Grid>
							</LocalizationProvider>
						</Grid>
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<FooterContainer>
						<Button color="secondary" onClick={clearFilter} sx={{ whiteSpace: "nowrap" }}>
							{t("filter.clear")}
						</Button>
						<ActionContainer>
							<Button color="secondary" onClick={handleClose}>
								{t("filter.cancel")}
							</Button>
							<Button color="secondary" autoFocus type="submit">
								{t("filter.apply")}
							</Button>
						</ActionContainer>
					</FooterContainer>
				</DialogActions>
			</form>
		</Dialog>
	)
}
