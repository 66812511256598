export const useDate = () => {
	function formatToUSDate(date: string) {
		return new Date(date).toLocaleDateString("en-US", {
			day: "2-digit",
			month: "2-digit",
			year: "numeric",
		})
	}

	function formatToBRDate(date: string) {
		return new Date(date).toLocaleDateString("pt-br", {
			hour: "numeric",
			minute: "numeric",
			second: "numeric",
		})
	}

	return {
		formatToUSDate,
		formatToBRDate,
	}
}
