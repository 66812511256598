import { createContext, forwardRef } from "react"
import { Outlet } from "react-router-dom"

import { Alert } from "@mui/material"
import { CustomContentProps, SnackbarContent, SnackbarProvider, closeSnackbar } from "notistack"

const NotistackContext = createContext({})

const Toast = forwardRef<HTMLDivElement, CustomContentProps>(({ ...props }, ref) => {
	return (
		<SnackbarContent ref={ref}>
			<Alert
				onClose={() => closeSnackbar(props.id)}
				severity={props.variant as any}
				sx={{ width: "100%", maxWidth: "300px", height: "min-content" }}
			>
				{Array.isArray(props.message)
					? props.message.map((str, index) => <p key={index}>{str}</p>)
					: props.message}
			</Alert>
		</SnackbarContent>
	)
})

function NotistackProvider() {
	return (
		<NotistackContext.Provider value={{}}>
			<SnackbarProvider
				maxSnack={3}
				autoHideDuration={5000}
				anchorOrigin={{
					vertical: "top",
					horizontal: "right",
				}}
				Components={{
					warning: Toast,
					success: Toast,
					error: Toast,
					info: Toast,
					default: Toast,
				}}
			>
				<Outlet />
			</SnackbarProvider>
		</NotistackContext.Provider>
	)
}

export { NotistackProvider }
