import * as React from "react"

import { Divider } from "@mui/material"
import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogContentText from "@mui/material/DialogContentText"
import DialogTitle from "@mui/material/DialogTitle"

type DialogAlertProps = {
	show: boolean
	title: string | React.ReactNode
	description: string
	accept: () => void
	decline?: () => void
	props?: any
	onlyConfirm?: boolean
	children?: React.ReactNode
	styles?: any
	yesDisabled?: boolean
	noDisabled?: boolean
}

const DialogAlert = ({
	show = false,
	title,
	description,
	accept,
	decline,
	props,
	onlyConfirm = false,
	children,
	styles,
	yesDisabled,
	noDisabled,
}: DialogAlertProps) => {
	return (
		<Dialog
			open={show}
			onClose={decline}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			<DialogTitle id="alert-dialog-title">{title}</DialogTitle>
			<Divider />
			<DialogContent style={{ paddingBottom: 0, marginBottom: 16 }}>
				<DialogContentText style={{ whiteSpace: "pre-line" }} id="alert-dialog-description">
					{description}
				</DialogContentText>
				<>{children ?? ""}</>
			</DialogContent>

			{!onlyConfirm ? (
				<DialogActions>
					<Button color={"secondary"} onClick={decline} disabled={noDisabled}>
						Não
					</Button>
					<Button color={"secondary"} onClick={accept} autoFocus disabled={yesDisabled}>
						Sim
					</Button>
				</DialogActions>
			) : (
				<DialogActions>
					<Button color={"secondary"} onClick={accept} autoFocus>
						Ok
					</Button>
				</DialogActions>
			)}
		</Dialog>
	)
}

export default DialogAlert
