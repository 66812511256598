import * as React from "react"
import { useTranslation } from "react-i18next"

import { Divider, Radio, RadioGroup } from "@mui/material"
import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogContentText from "@mui/material/DialogContentText"
import DialogTitle from "@mui/material/DialogTitle"
import FormControl from "@mui/material/FormControl"
import FormControlLabel from "@mui/material/FormControlLabel"
import FormLabel from "@mui/material/FormLabel"

type FilterProps = {
	show: boolean
	cancel: () => void
	apply: (value: any) => void
	value: any
}

export const Filter = ({ show, apply, cancel, value }: FilterProps) => {
	const { t } = useTranslation()

	const [state, setState] = React.useState<string>("all")

	const handleClickApply = () => {
		apply(state)
	}

	const handleClose = () => {
		cancel()
	}

	return (
		<Dialog
			open={show}
			onClose={handleClose}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
			maxWidth={"sm"}
			fullWidth={true}
		>
			<DialogTitle id="alert-dialog-title">{t("filter.filter_title")}</DialogTitle>
			<Divider />
			<DialogContent>
				<DialogContentText id="alert-dialog-description">
					<FormControl>
						<FormLabel color={"secondary"} id="demo-radio-buttons-group-label">
							{t("filter.status")}
						</FormLabel>
						<RadioGroup
							aria-labelledby="demo-radio-buttons-group-label"
							name="radio-buttons-group"
							defaultValue={value}
							onChange={(e) => setState(e.target.value)}
						>
							<FormControlLabel
								value={"all"}
								control={<Radio color="secondary" />}
								label={t("filter.all")}
							/>
							<FormControlLabel
								value={"enable"}
								control={<Radio color="secondary" />}
								label={t("filter.enable")}
							/>
							<FormControlLabel
								value={"disable"}
								control={<Radio color="secondary" />}
								label={t("filter.disable")}
							/>
						</RadioGroup>
					</FormControl>
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button color="secondary" onClick={handleClose}>
					{t("filter.cancel")}
				</Button>
				<Button color="secondary" onClick={handleClickApply} autoFocus>
					{t("filter.apply")}
				</Button>
			</DialogActions>
		</Dialog>
	)
}
