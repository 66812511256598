import { useEffect, useState } from "react"
import { FormProvider, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"

import { Alert, Autocomplete, DialogContentText, Grid, TextField } from "@mui/material"

import { MultiSelectData } from "../../apis/dashboard"
import { listOptionsOrganizations } from "../../apis/organization"
import { listAllTeams, searchTeamByOrganization } from "../../apis/team"
import { createUser } from "../../apis/user"
import { BodyPage, ContainerPage } from "../../components/container/container.styles"
import DialogAlert from "../../components/dialog/dialog"
import { HeaderPage } from "../../components/header/page/header-page"
import { Loading } from "../../components/loading/loading"
import { ControlledTextField } from "../../components/text-field/controlled-text-field"
import { useAuthentication } from "../../hooks/app/useAuthentication"
import { Client, Roles } from "../../models/Client"
import { pathNames } from "../../routes/paths"
import { AuthStorage } from "../../storage/auth-type"
import { getItem } from "../../storage/local-storage"
import { styles } from "../../styles/common"
import { createErrorMessage } from "../../utils/format"

export const CreateCommonUser = () => {
	const { organizationId } = getItem(AuthStorage.userInformation)

	const [loading, setLoading] = useState<boolean>(false)
	const [organizations, setOrganizations] = useState<MultiSelectData["data"]>([])
	const [organizationSelected, setOrganizationSelected] = useState<any>({
		id: organizationId,
	})
	const [dialog, setDialog] = useState<boolean>(false)
	const [dialogTitle, setDialogTitle] = useState<any>("")
	const [dialogDescription, setDialogDescription] = useState<any>("")
	const [isError, setIsError] = useState<boolean>(false)

	const [teams, setTeams] = useState<[]>([])
	const [teamSelected, setSelectedTeam] = useState<any>(null)

	const polices = getItem(AuthStorage.userPolicy)
	const { isAdmin } = useAuthentication()

	const { t } = useTranslation()

	const navigate = useNavigate()

	const methods = useForm<Client>()
	const {
		control,
		getValues,
		formState: { isValid },
	} = methods

	useEffect(() => {
		getOrganizations()
		if (polices?.role !== Roles.Admin) getTeams()
	}, [])

	const getOrganizations = async () => {
		if (!isAdmin) return
		try {
			const {
				data: { data },
			} = await listOptionsOrganizations()
			setOrganizations(data)
		} catch (error) {
		} finally {
		}
	}

	const getTeams = async () => {
		try {
			const {
				data: { data },
			} = await listAllTeams({ pageSize: 20, currentPage: 1, filter: "" })
			setTeams(data?.list)
		} catch (error) {
		} finally {
		}
	}

	const getTeamsByOrganization = async (organizationId: string) => {
		try {
			const {
				data: { data },
			} = await searchTeamByOrganization(organizationId)
			setTeams(data)
		} catch (error) {}
	}

	const handleSubmit = async () => {
		const formValues = getValues()

		const objectClient = {
			...formValues,
			teamId: teamSelected?.id,
			organizationId: organizationSelected?.id,
			role: Roles.User,
		}

		if (polices?.role !== Roles.Admin && organizationId)
			objectClient.organizationId = organizationId

		if (isValid) {
			setLoading(true)
			setIsError(false)
			try {
				await createUser(objectClient)
				setDialogTitle(t("request_messages.create_success_title", { name: "Usuário" }))
				setDialogDescription(t("request_messages.success_description", { name: "usuário" }))
				setOrganizationSelected(null)
				setSelectedTeam(null)
			} catch (error: any) {
				setIsError(true)
				setDialogTitle(t("request_messages.500"))
				if (error?.response?.data?.messages) {
					setDialogDescription(createErrorMessage(error?.response?.data?.messages))
				} else {
					setDialogDescription(t("request_messages.400"))
				}
			} finally {
				setDialog(true)
				setLoading(false)
			}
		}
	}

	const handleAccept = () => {
		setDialog(false)
		if (!isError) {
			navigate(pathNames.users.user.list)
			methods.reset()
		}
	}

	const handleAutoCompleteTeams = (event: any, option: any) => {
		setSelectedTeam(option)
	}

	const handleAutoCompleteOrganizations = (event: any, option: any) => {
		setSelectedTeam("")
		setTeams([])
		setOrganizationSelected(option)
		getTeamsByOrganization(option?.id)
	}

	if (loading) return <Loading />

	return (
		<ContainerPage>
			<HeaderPage
				disabledButton={false}
				onClickSubmit={handleSubmit}
				onClickTopButton={() => navigate(pathNames.users.user.list)}
				description={t("customer.create_user_common_description")}
				isEdit={true}
				title={t("customer.create_user_common_title")}
			/>
			<BodyPage>
				<FormProvider {...methods}>
					<Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
						<Grid item xs={12}>
							<ControlledTextField
								id={"name"}
								style={styles.fullWidth}
								label={t("customer.label_name")}
								control={control}
								rules={{ required: true }}
							/>
						</Grid>
						<Grid item xs={6}>
							<ControlledTextField
								id={"email"}
								style={styles.textFieldMarginTop}
								label={t("customer.label_email")}
								control={control}
								rules={{ required: true }}
							/>
						</Grid>
						<Grid item xs={6}>
							<ControlledTextField
								id={"phone"}
								style={styles.textFieldMarginTop}
								label={t("customer.label_phone")}
								control={control}
								rules={{ required: true }}
								mask={"(99) 99999-9999"}
							/>
						</Grid>
						{polices?.role === "admin" && (
							<Grid item xs={12}>
								{organizations.length > 0 && (
									<Autocomplete
										style={styles.textFieldMarginTop}
										disablePortal
										size={"small"}
										options={organizations}
										defaultValue={organizationSelected}
										getOptionLabel={(option: any) => option.name || ""}
										onChange={handleAutoCompleteOrganizations}
										renderInput={(params) => (
											<TextField
												{...params}
												variant={"filled"}
												color={"secondary"}
												label={t("customer.label_organization")}
											/>
										)}
									/>
								)}
							</Grid>
						)}
						<Grid item xs={12}>
							{teams.length > 0 && (
								<Autocomplete
									style={styles.textFieldMarginTop}
									disablePortal
									size={"small"}
									options={teams}
									defaultValue={teamSelected}
									getOptionLabel={(option: any) => option.name || ""}
									onChange={handleAutoCompleteTeams}
									renderInput={(params) => (
										<TextField
											{...params}
											variant={"filled"}
											color={"secondary"}
											label={t("customer.label_team")}
										/>
									)}
								/>
							)}
							{/* <FormControl
                style={styles.formControl} variant="filled" sx={{ m: 1, minWidth: 120 }}>
                <InputLabel color={'secondary'} id="demo-simple-select-filled-label">{t('customer.label_team')}</InputLabel>
                <Select
                  color={'secondary'}
                  labelId="demo-simple-select-filled-label"
                  id="demo-simple-select-filled"
                  style={{ height: 50 }}
                  onChange={handleSelectTeam}
                  value={teamSelected}
                >
                  {teams.map((element: any) => (
                    <MenuItem value={element?.id}>{element?.name}</MenuItem>
                  ))}
                </Select>
              </FormControl> */}
						</Grid>
					</Grid>
				</FormProvider>
				<Grid item xs={12} style={{ marginTop: 16 }}>
					<Alert severity={"info"} title="Importante">
						O usuário cadastrado irá receber um e-mail na sua caixa de entrada com o código de
						confirmação e o link para resetar a senha.
					</Alert>
				</Grid>
			</BodyPage>
			<DialogAlert
				props={!isError ? <DialogContent /> : null}
				onlyConfirm={true}
				show={dialog}
				accept={handleAccept}
				title={dialogTitle}
				description={dialogDescription}
			/>
		</ContainerPage>
	)
}

const DialogContent = () => {
	const { t } = useTranslation()

	return (
		<>
			<br />
			<DialogContentText>
				<b>{t("modal.description_email_steps_1")}</b>
			</DialogContentText>
			<DialogContentText>
				<b>{t("modal.description_email_steps_2")}</b>
			</DialogContentText>
			<br />
			<DialogContentText>{t("modal.description_email_steps_3")}</DialogContentText>
			<img alt="email-example" width={400} src={require("../../assets/images/email-example.png")} />
		</>
	)
}
