import { t } from "i18next"

import { pathNames } from "../../routes/paths"

export const listTableKeys = [
	{
		isDate: true,
		id: "createdAt",
		value: "createdAt",
	},
	"operation",
	"context",
	{
		isLink: true,
		value: "organizationName",
		id: "organizationId",
		path: pathNames.organization.edit,
	},
	{
		isLink: true,
		value: "authorName",
		id: "authorId",
		path: pathNames.users.user.edit,
	},
]

export const listTableColumns = [
	{
		label: t("audit_logs.table.date"),
		id: "createdAt",
	},
	{
		label: t("audit_logs.table.operation"),
		id: "operation",
	},
	{
		label: t("audit_logs.table.context"),
		id: "tableName",
	},
	{
		label: t("audit_logs.table.organization"),
		id: "organizationName",
	},
	{
		label: t("audit_logs.table.author"),
		id: "authorName",
	},
]

export const detailsTableKeys = [
	{
		isElement: true,
		value: "item",
		id: "item",
	},
	{
		isElement: true,
		value: "oldValue",
		id: "oldValue",
	},
	{
		isElement: true,
		value: "newValue",
		id: "newValue",
	},
]

export const detailsTableColumns = [
	{
		label: t("audit_logs.table.item"),
		id: "item",
	},
	{
		label: t("audit_logs.table.old_value"),
		id: "oldValue",
	},
	{
		label: t("audit_logs.table.new_value"),
		id: "newValue",
	},
]
