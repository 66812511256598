import styled, { keyframes } from "styled-components"

import { ReactComponent as JuliaVector } from "../../../assets/julia/julia_02.svg"
import { maxDeviceSizeForMediaQueries } from "../../../styles/devices-medias"

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

export const SvgJulia = styled(JuliaVector)`
	/* width: 900px;
  height: 600px; */
	@media ${maxDeviceSizeForMediaQueries.tablet} {
		width: 300px;
		height: 300px;
	}
`

export const Container = styled.div`
	width: 100%;
	box-sizing: border-box;
	height: 700px;
	padding: 16px 128px;
	background: ${(props) => props.theme.palette.secondary.main};
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	animation: ${fadeIn} 300ms ease-in-out forwards;

	@media ${maxDeviceSizeForMediaQueries.tablet} {
		flex-direction: column-reverse;
		padding: 16px 16px;
		height: 100%;
	}
`

export const SubTitle = styled.h2`
	padding: 0;
	margin: 0;
	font-size: 32px;
	color: ${(props) => props.theme.palette.primary.main};
	@media ${maxDeviceSizeForMediaQueries.tablet} {
		font-size: 24px;
		text-align: center;
	}
`

export const ButtonLine = styled.button`
	color: #cacaca;
	background: transparent;
	border: none;
	cursor: pointer;
	display: flex;
	align-items: center;
	text-transform: uppercase;
	font-size: 14px;
	margin: 0 8px;
	@media ${maxDeviceSizeForMediaQueries.tablet} {
		margin-top: 16px;
	}
`

export const ContainerButtons = styled.div`
	display: flex;
	flex-direction: row;
	@media ${maxDeviceSizeForMediaQueries.tablet} {
		flex-direction: column;
		align-items: center;
	}
`
