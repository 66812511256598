import { Modal } from "@mui/material"
import { Box } from "@mui/system"
import styled from "styled-components"

export const Container = styled(Modal)`
	width: 100vw;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
`

export const Header = styled(Box)`
	display: flex;
	justify-content: space-between;
	align-items: center;
`

export const Content = styled.section`
	width: 80vw;
	height: min-content;
	background-color: white;
	padding: 20px;
	border-radius: 5px;
`

export const Footer = styled.footer`
	width: 100%;
	margin-top: 20px;
	display: flex;
	justify-content: flex-end;
	align-items: center;
`
