import { useMemo } from "react"
import { useTranslation } from "react-i18next"

import { Grid } from "@mui/material"

import { GraphDatasetProps } from "../../../../@types/graphs/dataset"
import { GeneratedInvoiceService, InvoicingData } from "../../../../apis/invoicing"
import { Doughnut } from "../../../../components/graphs/doughnut/doughnut"
import { GraphLineProps, Line } from "../../../../components/graphs/multi-axis/multi-axis"
import { LoadingTable } from "../../../../components/loading/loading"
import { month } from "../../../../utils/date"
import { mountDatasetGraph } from "../../../../utils/graphs"
import { Center } from "../invoicing-details.styles"

interface InvoicingSummaryProps {
	invoicing: InvoicingData | null
	isLoading?: boolean
	isPrinting?: boolean
}

export function InvoicingSummary({ invoicing, isLoading, isPrinting }: InvoicingSummaryProps) {
	const { t } = useTranslation()

	const handleValue = (acc: any, [key, value]: any) => {
		const crr = acc[key as keyof typeof acc]
		if (!crr) return { ...acc, [key as keyof typeof acc]: Number(value) }
		return { ...acc, [key as keyof typeof acc]: Number(crr) + Number(value) }
	}

	const parseDataset = ([legend, value, array]: [legend: string, value: string, array: any[]]) => {
		if (!array.length) return {} as GraphDatasetProps
		const products = array.map((f) => [f[legend], f[value] ?? value])
		const data = products.reduce(handleValue, {})
		return mountDatasetGraph(data)
	}

	const consultationData = useMemo(() => {
		const response =
			invoicing?.monthlyData?.services?.map((service: GeneratedInvoiceService) => ({
				consultation_qty: service.total,
				service: service.name,
			})) || []

		return parseDataset(["service", "consultation_qty", response])
	}, [invoicing])

	const consultationFormData = useMemo(() => {
		const data = invoicing?.monthlyData?.totalByProcessingType
		const result = data
			? Object.keys(data).map((key) => ({
					processType: key,
					total: invoicing?.monthlyData?.totalByProcessingType[key],
				}))
			: []
		return parseDataset(["processType", "total", result])
	}, [invoicing])

	const teamsData = useMemo(() => {
		const data = invoicing?.monthlyData?.totalByTeams
		const result = data
			? Object.keys(data).map((key) => ({
					team: key,
					total: invoicing?.monthlyData?.totalByTeams[key],
				}))
			: []
		return parseDataset(["team", "total", result])
	}, [invoicing])

	const consultationForDateDataset = useMemo(() => {
		const result = invoicing?.monthlyData.services.map((service: GeneratedInvoiceService) => ({
			label: service.name,
			data: Object.entries(service.dailyUsage).map(([date, value], index) => {
				const monthName = month.find(([key]) => key === Number(date?.split("-")?.[1]) - 1)
				return [`${index + 1} - ${monthName?.[1] ?? ""}`, `${value.total}`]
			}),
		})) as GraphLineProps[]
		return result
	}, [invoicing])

	if (!isLoading && !consultationData.datasets && !consultationFormData.datasets) return <></>
	return (
		<>
			<Grid container columns={3} mb={4}>
				<Grid item md={3} lg={1}>
					{!isLoading && Boolean(consultationData.datasets) ? (
						<Doughnut
							title={t("consumption.invoicing.overview.consumptionByProduct") as string}
							data={consultationData}
						/>
					) : (
						<Center>
							<LoadingTable />
						</Center>
					)}
				</Grid>
				<Grid item md={3} lg={1}>
					{!isLoading && Boolean(consultationFormData.datasets) ? (
						<Doughnut
							title={t("consumption.invoicing.overview.consumptionByForm") as string}
							data={consultationFormData}
						/>
					) : (
						<Center>
							<LoadingTable />
						</Center>
					)}
				</Grid>

				<Grid item md={3} lg={1}>
					{!isLoading && Boolean(teamsData.datasets) ? (
						<Doughnut
							title={t("consumption.invoicing.overview.consumptionByTeam") as string}
							data={teamsData}
						/>
					) : (
						<Center>
							<LoadingTable />
						</Center>
					)}
				</Grid>
			</Grid>

			<Line
				legend={consultationForDateDataset.map(({ label, data }) => [
					label,
					`${data.reduce((acc, crr) => acc + Number(crr?.[1]), 0)} consultas`,
				])}
				title={
					t("consumption.invoicing.overview.consumptionByProductDate", {
						month: t(`month.${invoicing?.month}`),
					}) as string
				}
				data={consultationForDateDataset}
			/>
		</>
	)
}
