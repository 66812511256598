import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

import { CloudDownload } from "@mui/icons-material"
import { SelectChangeEvent } from "@mui/material"
import {
	BarElement,
	CategoryScale,
	Chart as ChartJS,
	Legend,
	LineElement,
	LinearScale,
	PointElement,
	Title,
	Tooltip,
} from "chart.js"

import {
	MultiSelectData,
	downloadCSVReport,
	searchServiceNamesByOrganization,
} from "../../../apis/dashboard"
import { listOptionsOrganizations } from "../../../apis/organization"
import { ContainerPage } from "../../../components/container/container.styles"
import { CSVReportOption, HeaderPage } from "../../../components/header/page/header-page"
import { HeaderPageTopButton } from "../../../components/header/page/header-page-top-button"
import { Loading } from "../../../components/loading/loading"
import { SnackBar } from "../../../components/snackbar/snackbar"
import { Roles } from "../../../models/Client"
import { AuthStorage } from "../../../storage/auth-type"
import { getItem } from "../../../storage/local-storage"
import { getCurrentDate, getCurrentLocaleDate } from "../../../utils/format"

ChartJS.register(
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend,
	PointElement,
	LineElement,
)

export const DataExtraction = () => {
	const { t } = useTranslation()

	const [loading, setLoading] = useState<boolean>(false)

	const [filter, setFilter] = useState<string>("byTeamServices")
	const [serviceNames, setServiceNames] = useState<MultiSelectData["data"]>([])
	const [selectedServices, setSelectedServices] = useState<string[]>([])

	const [date, setDate] = useState<string>(getCurrentDate())
	const [endDate, setEndDate] = useState<string>(getCurrentDate())
	const [localeStartDate, setLocaleStartDate] = useState<string>(getCurrentLocaleDate())
	const [localeEndDate, setLocaleEndDate] = useState<string>(getCurrentLocaleDate())

	const [invalidEndDate, setInvalidEndDate] = useState<boolean>(false)

	const { role, organizationId } = getItem(AuthStorage.userInformation)

	const [organizations, setOrganizations] = useState<MultiSelectData["data"]>([])
	const [selectedOrganizationId, setSelectedOrganizationId] = useState<string>("")
	const [selectedOrganizations, setSelectedOrganizations] = useState<string[]>([])

	const [reportType, setReportType] = useState<CSVReportOption>("consumptionMonthByDay")
	const [errors, setErrors] = useState<Array<string>>([])

	useEffect(() => {
		getOrganizations().then(([{ id }]) => {
			const organizationsToFetch = selectedOrganizations.map((organization) => {
				const foundOrganization = organizations.find(({ name }) => name === organization)
				return foundOrganization?.id
			})

			if (role !== Roles.Admin) {
				setSelectedOrganizationId(organizationId)
				setSelectedOrganizations([organizationId])
				fetchServices([organizationId])
				return
			}

			fetchServices(organizationsToFetch as string[])
		})
	}, [localeStartDate, endDate, localeEndDate, selectedOrganizations])

	const getOrganizations = async (): Promise<Array<{ id: string; name: string }>> => {
		const {
			data: { data },
		} = await listOptionsOrganizations()
		setOrganizations(data)
		if (!data) return []

		return data
	}

	const fetchServices = async (organizationIds: string[]) => {
		if (!organizationIds) throw new Error("Organization ID Not Found")
		try {
			const {
				data: { data },
			} = await searchServiceNamesByOrganization([organizationIds.join(",")])

			setServiceNames(data)
		} catch (error) {}
	}

	const downloadExportDocument = async () => {
		const fieldsToCheck = [
			{
				field: reportType,
				error: "Faltando o tipo de relatório.",
			},
			{
				field: selectedServices,
				error: "Faltando os serviços.",
			},
		]

		let fieldCheckResult = []

		fieldsToCheck.forEach(({ field, error }) => {
			if (!field || !field.length) {
				fieldCheckResult = [...errors, error]
				setErrors(fieldCheckResult)
				return
			}

			fieldCheckResult = errors.filter((fieldError) => fieldError !== error)
			setErrors(fieldCheckResult)
		})

		if (fieldCheckResult?.length) return

		setLoading(true)
		try {
			const servicesToFetch = selectedServices
				.map((service: string) => {
					const foundService = serviceNames.find(({ name }: { name: string }) => name === service)
					return foundService?.id
				})
				.filter(Boolean)
			const organizationsToFetch = selectedOrganizations
				.map((organization) => {
					const foundOrganization = organizations.find(({ name }) => name === organization)
					return foundOrganization?.id
				})
				.filter(Boolean)

			const { data } = await downloadCSVReport(
				organizationsToFetch as string[],
				date,
				localeStartDate,
				localeEndDate,
				servicesToFetch,
				reportType as CSVReportOption,
			)

			// Create a Blob from the CSV data
			const blob = new Blob([data], { type: "text/csv;charset=utf-8;" })

			// Create a URL for the blob
			const url = URL.createObjectURL(blob)

			// Create a temporary anchor element and trigger a download
			const a = document.createElement("a")
			a.href = url
			a.download = "export.csv" // Name the file here
			document.body.appendChild(a) // Append the anchor to the body
			a.click() // Trigger a click on the element

			// Clean up by revoking the blob URL and removing the anchor element
			URL.revokeObjectURL(url)
			document.body.removeChild(a)
		} catch (error) {
			console.error("Error downloading the file", error)
		} finally {
			setLoading(false)
		}
	}

	const handleDate = (e: any) => {
		setInvalidEndDate(false)
		const newDate = new Date(e)
		const localeEndDateState = new Date(localeEndDate)

		const localeUsDate = newDate.toLocaleDateString("en-US", {
			month: "2-digit",
			day: "2-digit",
			year: "numeric",
		})

		if (
			!newDate.getDate().toString()?.length ||
			!newDate.getMonth().toString()?.length ||
			!newDate.getFullYear().toString()?.length
		)
			return

		if (new Date(localeUsDate) <= localeEndDateState) {
			const month = newDate.getMonth() + 1
			const year = newDate.getFullYear()

			const dateFormat = month + "-" + year

			setDate(dateFormat)
			setLocaleStartDate(localeUsDate)
		} else {
			setInvalidEndDate(true)
		}
	}

	const handleEndDate = (e: any) => {
		setInvalidEndDate(false)
		const newDate = new Date(e)
		const localeStartDateState = new Date(localeStartDate)

		const localeUsDate = newDate.toLocaleDateString("en-US", {
			month: "2-digit",
			day: "2-digit",
			year: "numeric",
		})
		if (
			!newDate.getDate().toString()?.length ||
			!newDate.getMonth().toString()?.length ||
			!newDate.getFullYear().toString()?.length
		)
			return

		if (new Date(localeUsDate) >= localeStartDateState) {
			const month = newDate.getMonth() + 1
			const year = newDate.getFullYear()

			const dateFormat = month + "-" + year

			setEndDate(dateFormat)
			setLocaleEndDate(localeUsDate)
		} else {
			setInvalidEndDate(true)
			setErrors([...errors, "A data de início deve ser maior que a data fim "])
		}
	}

	const onChangeMultiselect = (e: SelectChangeEvent) => {
		setSelectedServices(e.target.value as unknown as string[])
	}

	const onDeleteMultiselect = (value: any) => {
		const newSelectedServices = selectedServices.filter((item) => item !== value)
		setSelectedServices(newSelectedServices)
	}

	const onChangeReportType = (e: SelectChangeEvent) => {
		setReportType(e.target.value as CSVReportOption)
	}
	const onChangeMultiselectOrganization = (e: SelectChangeEvent) => {
		const values = e.target.value as unknown as string[]
		const option = organizations.find((organization) => {
			const found = values.find((value) => {
				return value === organization.name
			})
			return found
		})

		if (option) {
			setSelectedOrganizationId(option.id)
		}

		setSelectedOrganizations(values as unknown as string[])
	}

	const onDeleteMultiselectOrganization = (value: any) => {
		const newSelectedOrganizations =
			selectedOrganizations?.filter((item: any) => item !== value) || []
		setSelectedOrganizations(newSelectedOrganizations)
	}

	if (loading) return <Loading />

	return (
		<ContainerPage>
			<div>
				<HeaderPage
					title={t("consumption.title.data-extraction")}
					isChartVisible={true}
					onChangeDate={handleDate}
					valueFilterConsumption={filter}
					hasEndDate
					onChangeEndDate={handleEndDate}
					hasMultiselect
					multiSelectData={serviceNames}
					onChangeMultiselect={onChangeMultiselect}
					onDeleteMultiselect={onDeleteMultiselect}
					selectedNames={selectedServices}
					multiSelectTitle={t("consumption.multiSelectTitle") as string}
					hasReportType
					reportType={reportType}
					onChangeReportType={onChangeReportType}
					hasFirstLineMultiselect
					firstLineMultiselectItems={organizations}
					onFirstLineMultiSelectChange={onChangeMultiselectOrganization}
					onDeleteFirstLineMultiselect={onDeleteMultiselectOrganization}
					firstLineMultiSelectTitle="Organizações"
					selectedFirstLineItems={selectedOrganizations}
					customTitleStyles={{ marginBottom: "2rem" }}
					showDownloadButton
					topButton={
						<HeaderPageTopButton onClick={downloadExportDocument} icon={<CloudDownload />}>
							{t("headerPage.export_as_file")}
						</HeaderPageTopButton>
					}
				/>
			</div>

			<SnackBar
				open={invalidEndDate || !!errors?.length}
				type={"error"}
				message={errors}
				handleClose={() => setInvalidEndDate(false)}
				style={{
					paddingBottom: "5rem",
					paddingRight: "2rem",
				}}
			/>
		</ContainerPage>
	)
}
