export const keepOldOptionsSettings = {
	"data-grid": (newSettings: any, oldSettings: any): any => {
		const editableFields = oldSettings.columns
			.filter((column: any) => column.editable)
			.map((c: any) => c.field)

		const updatedOptions = newSettings.options.map((option: any) => {
			editableFields.forEach((field: any) => {
				option[field] = oldSettings.options.find((o: any) => o.id === option.id)?.[field]
			})
			return option
		})
		return {
			...newSettings,
			options: updatedOptions,
		}
	},
	selection: (newSettings: any, oldSettings: any): any => {
		const selectedOption = oldSettings.options.find((option: any) => option.selected)
		const updatedOptions = newSettings.options.map((option: any) => {
			option.selected = option.id === selectedOption?.id
			return option
		})
		return {
			...newSettings,
			options: updatedOptions,
		}
	},
}
