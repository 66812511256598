import styled from "styled-components"

import { maxDeviceSizeForMediaQueries } from "../../../styles/devices-medias"

export const ContainerMenu = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	@media ${maxDeviceSizeForMediaQueries.tablet} {
		display: none;
	}
`
