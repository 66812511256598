import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"

import { disableClients, listAllCustomers } from "../../apis/customers"
import { BodyPage, ContainerPage } from "../../components/container/container.styles"
import DialogAlert from "../../components/dialog/dialog"
import { Filter } from "../../components/filter/filter"
import { HeaderPage } from "../../components/header/page/header-page"
import { LoadingTable } from "../../components/loading/loading"
import { Table } from "../../components/table/table"
import { Police } from "../../models/Police"
import { pathNames } from "../../routes/paths"
import { AuthStorage } from "../../storage/auth-type"
import { getItem } from "../../storage/local-storage"

export const ListCustomer = () => {
	const [showDialogConfirmation, setShowDialogConfirmation] = useState<boolean>(false)
	const [selectedItems, setSelectedItems] = useState<any>()

	const [showFilter, setShowFilter] = useState<boolean>(false)
	const [active, setActive] = useState<any>("enable")

	const [customerPolices, setCustomersPolices] = useState<Police>()

	const [rows, setRows] = useState<[]>([])
	const [currentPage, setCurrentPage] = useState<number>(1)
	const [pageSize, setPageSize] = useState<number>(10)
	const [filter, setFilter] = useState<string>("")
	const [pageCount, setPageCount] = useState<number>(0)

	const [loading, setLoading] = useState<boolean>(true)

	const navigate = useNavigate()

	const { t } = useTranslation()

	const columns = [
		{
			label: t("columns.name"),
			id: "name",
		},
		{
			label: t("columns.email"),
			id: "email",
		},
		{
			label: t("columns.phone"),
			id: "phone",
		},
		{
			label: t("columns.organization"),
			id: "organizationId",
		},
		{
			label: t("columns.status"),
			id: "active",
		},
	]

	const keys = [
		"name",
		"email",
		"phone",
		{
			isLink: true,
			value: "organization",
			id: "organizationId",
			path: pathNames.organization.edit,
			backPath: pathNames.customer.list,
		},
		"active",
	]

	useEffect(() => {
		const { customers } = getItem(AuthStorage.userPolicy)
		setCustomersPolices(customers)
	}, [])

	useEffect(() => {
		listCustomers()
		// eslint-disable-next-line
	}, [pageSize, currentPage, filter])

	const listCustomers = async (status?: any) => {
		try {
			const {
				data: { data },
			} = await listAllCustomers({
				currentPage,
				pageSize,
				filter,
				admin: true,
				active: status,
			})
			setRows(data?.list)
			setPageCount(data?.total)
		} catch (error) {
			console.log(error)
		} finally {
			setLoading(false)
		}
	}

	const redirectLink = (key: string, id: string) => {
		if (key === "organization") {
			navigate(pathNames.organization.edit + id)
		} else if (key === "team") {
			navigate(pathNames.team.edit + id)
		}
	}

	const selectActiveState = (state: string) => {
		setShowFilter(false)
		setActive(state)
		if (state === "all") {
			listCustomers(null)
		} else if (state === "enable") {
			listCustomers(true)
		} else if (state === "disable") {
			listCustomers(false)
		}
	}

	const handleSelectedDisableItems = (items: any) => {
		setSelectedItems(items)
		setShowDialogConfirmation(true)
	}

	const handleDisableAllItems = async () => {
		setShowDialogConfirmation(false)
		setLoading(true)

		try {
			await disableClients(selectedItems)
		} catch (error) {
		} finally {
			setLoading(false)
			listCustomers()
		}
	}

	return (
		<ContainerPage>
			<HeaderPage
				searchValue={filter}
				onSearch={(event) => setFilter(event?.target?.value)}
				isCreateButtonVisible={customerPolices?.create}
				title={t("menu.customers")}
				onOpenFilter={() => setShowFilter(true)}
				buttonTitle={t("customer.create_user_admin_title")}
				onClickTopButton={() => navigate(pathNames.customer.create)}
			/>
			<BodyPage>
				{!loading ? (
					<Table
						page={currentPage - 1}
						rowsPerPage={pageSize}
						pageCount={pageCount}
						changeRowsPerPage={(value) => setPageSize(value)}
						changePage={(value) => setCurrentPage(value + 1)}
						onDelete={() => null}
						onEdit={(element: any) => navigate(pathNames.customer.edit + element?.id)}
						onClickLink={redirectLink}
						columns={columns}
						rows={rows}
						keys={keys}
						isEditVisible={customerPolices?.update}
						isDeleteVisible={customerPolices?.delete}
						isReadVisible={customerPolices?.read}
						isSelectable={true}
						onDisableAll={handleSelectedDisableItems}
					/>
				) : (
					<LoadingTable />
				)}
			</BodyPage>
			<Filter
				show={showFilter}
				apply={(e) => selectActiveState(e)}
				cancel={() => setShowFilter(false)}
				value={active}
			/>
			<DialogAlert
				show={showDialogConfirmation}
				description={t("table.modal_disable_label_description")}
				title={t("table.disable")}
				accept={() => handleDisableAllItems()}
				decline={() => setShowDialogConfirmation(false)}
			/>
		</ContainerPage>
	)
}
