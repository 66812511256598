import axiosInstance from "./config/axios"

export const listProjectsToSelect = async () => await axiosInstance.get("/projects")

export const listAllProjects = async ({ currentPage, pageSize, filter = "", active = true }: any) =>
	await axiosInstance.post("/projects/search", { currentPage, pageSize, filter, active })

export const createProject = async (project: any) => await axiosInstance.post("/projects", project)

export const updateProject = async (projectId: any, projectData: any) =>
	await axiosInstance.put(`/projects/${projectId}`, projectData)

export const searchProject = async (projectId: any) =>
	await axiosInstance.get(`/projects/${projectId}`)
