import { Typography } from "@mui/material"
import styled from "styled-components"

import { ReactComponent as ResolvOneLogo } from "../../assets/images/resolvone.svg"

export const Container = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	overflow: hidden;
`

export const Motion = styled.img`
	width: 500px;
`

export const Description = styled(Typography)`
	width: 200px;
	text-align: center;
`

export const ContainerError = styled.div`
	padding: 16px;
	display: flex;
	flex-direction: column;
	max-width: 400px;
	align-items: center;
	gap: 16px;
	justify-content: center;
`

export const Logo = styled(ResolvOneLogo)`
	width: 180px;
`
