import { createContext, useReducer } from "react"

import { AuthStorage } from "../storage/auth-type"
import { getItem } from "../storage/local-storage"
import AppReducer from "./reducer"

const item = getItem(AuthStorage.mode)

const defaultState = {
	isAuthenticated: false,
	light: item ? item?.mode : true,
	productIdShow: 0,
	language: "pt",
}

export const GlobalContext = createContext<any>(defaultState)

export const GlobalProvider = ({ children }: any) => {
	const [state, dispatch] = useReducer(AppReducer, defaultState)

	return (
		<GlobalContext.Provider
			value={{
				dispatch,
				isAuthenticated: state.isAuthenticated,
				light: state.light,
				productIdShow: state.productIdShow,
				language: state.language,
			}}
		>
			{children}
		</GlobalContext.Provider>
	)
}
