import React, { useContext, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"

import { Folder, LoginOutlined } from "@mui/icons-material"
import AppsIcon from "@mui/icons-material/Apps"
import ExpandLess from "@mui/icons-material/ExpandLess"
import ExpandMore from "@mui/icons-material/ExpandMore"
import ScienceIcon from "@mui/icons-material/Science"
import { Collapse } from "@mui/material"
import Box from "@mui/material/Box"
import Divider from "@mui/material/Divider"
import Drawer from "@mui/material/Drawer"
import List from "@mui/material/List"
import ListItemButton from "@mui/material/ListItemButton"
import ListItemIcon from "@mui/material/ListItemIcon"
import ListItemText from "@mui/material/ListItemText"

import { GlobalContext } from "../../../contexts/global-provider"
import { ACTIONS_TYPES } from "../../../contexts/reducer"
import { ServiceProps, getServicesDataByLanguage } from "../../../data/services"
import { pathNames } from "../../../routes/paths"

const sxListConfig = { width: "100%", bgcolor: "background.paper" }

type Anchor = "top" | "left" | "bottom" | "right"

type DrawerProps = {
	openDrawer: boolean
	callbackDrawer: (show: boolean) => void
}

export default function TemporaryDrawerHome({ openDrawer = false, callbackDrawer }: DrawerProps) {
	const { dispatch } = useContext(GlobalContext)

	const [openRegister, setOpenRegister] = useState(true)

	const [openLabCollapse, setOpenLabCollapse] = useState(false)

	const { t, i18n } = useTranslation()

	const navigate = useNavigate()

	const toggleDrawer =
		(anchor: Anchor, open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
			if (
				event.type === "keydown" &&
				((event as React.KeyboardEvent).key === "Tab" ||
					(event as React.KeyboardEvent).key === "Shift")
			) {
				return
			}

			// callbackDrawer(!openDrawer);
		}

	const handleClickRegister = () => {
		setOpenRegister(!openRegister)
	}

	const handleClickLab = () => {
		setOpenLabCollapse(!openLabCollapse)
	}

	const openDocs = () => {
		window.open("https://documenter.getpostman.com/view/27472588/2s93kz4jT2", "_blank")
	}

	const openLogin = () => {
		// @ts-ignore: Unreachable code error
		window.location.href = process.env.REACT_APP_COGNITO_URL
	}

	const navigateToSpecificLab = (product: ServiceProps) => {
		navigate(product.path, { state: { isFromHome: true } })
		callbackDrawer(false)
	}

	const navigateToHomeSpecificProduct = (e: any) => {
		navigate(pathNames.home)
		selectProductMenu(e.id)
		callbackDrawer(false)
	}

	const selectProductMenu = (id: number) => {
		dispatch({ type: ACTIONS_TYPES.SET_HOME_PRODUCT_SHOW, value: id })
	}

	const list = (anchor: Anchor) => (
		<Box
			sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
			role="presentation"
			onClick={toggleDrawer("left", false)}
		>
			<Divider />
			<List sx={sxListConfig} component="nav" aria-labelledby="nested-list-subheader">
				<ListItemButton onClick={handleClickRegister}>
					<ListItemIcon>
						<AppsIcon />
					</ListItemIcon>
					<ListItemText secondary={t("common.products")} />
					{openRegister ? <ExpandLess color={"action"} /> : <ExpandMore color="action" />}
				</ListItemButton>
				<Collapse in={openRegister} timeout="auto" unmountOnExit>
					{getServicesDataByLanguage(i18n.language).map((e: any) => (
						<List key={e?.name} component="div" disablePadding>
							<ListItemButton onClick={() => navigateToHomeSpecificProduct(e)}>
								<ListItemText secondary={e.name} />
							</ListItemButton>
						</List>
					))}
				</Collapse>
				<List component="div" disablePadding>
					<ListItemButton onClick={() => openDocs()}>
						<ListItemIcon>
							<Folder />
						</ListItemIcon>
						<ListItemText secondary={t("common.docs")} />
					</ListItemButton>
				</List>
				<ListItemButton onClick={handleClickLab}>
					<ListItemIcon>
						<ScienceIcon />
					</ListItemIcon>
					<ListItemText secondary={t("menu.try_it")} />
					{openLabCollapse ? <ExpandLess color={"action"} /> : <ExpandMore color="action" />}
				</ListItemButton>
				<Collapse in={openLabCollapse} timeout="auto" unmountOnExit>
					{getServicesDataByLanguage(i18n.language).map((e: any) => (
						<List key={e?.name} component="div" disablePadding>
							<ListItemButton onClick={() => navigateToSpecificLab(e)}>
								<ListItemText secondary={e.name} />
							</ListItemButton>
						</List>
					))}
				</Collapse>
				<List component="div" disablePadding>
					<ListItemButton onClick={() => openLogin()}>
						<ListItemIcon>
							<LoginOutlined />
						</ListItemIcon>
						<ListItemText secondary={t("header.access_platform")} />
					</ListItemButton>
				</List>
			</List>
		</Box>
	)

	//onClick={() => navigate(pathNames.lab.consultDeath, { state: { isFromHome: true } })}

	return (
		<Drawer anchor={"left"} open={openDrawer} onClose={toggleDrawer("left", false)}>
			{list("left")}
		</Drawer>
	)
}
