import { useContext, useEffect, useState } from "react"
import { FormProvider, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useNavigate, useParams } from "react-router-dom"

import SyncIcon from "@mui/icons-material/Sync"
import {
	Autocomplete,
	Button,
	Checkbox,
	Divider,
	FormControlLabel,
	Grid,
	TextField,
	Tooltip,
	Typography,
} from "@mui/material"

import { listProjectsToSelect } from "../../apis/projects"
import { searchService, updateService } from "../../apis/services"
import { BatchFields } from "../../components/batch-fields/batch-fields"
import { BodyPage, ContainerPage } from "../../components/container/container.styles"
import DialogAlert from "../../components/dialog/dialog"
import { HeaderPage } from "../../components/header/page/header-page"
import { Loading } from "../../components/loading/loading"
import { ControlledTextField } from "../../components/text-field/controlled-text-field"
import { GlobalContext } from "../../contexts/global-provider"
import { useManifest } from "../../hooks/app/service/useManifest"
import { useQuery } from "../../hooks/app/useQuery"
import { pathNames } from "../../routes/paths"
import { styles } from "../../styles/common"
import { createErrorMessage } from "../../utils/format"
import { ExecutionOptions } from "./components/execution-options"
import { SuccessBillingOptions } from "./components/success-billing-options"

export const EditService = () => {
	const [loading, setLoading] = useState<boolean>(false)

	const [dialog, setDialog] = useState<boolean>(false)
	const [dialogTitle, setDialogTitle] = useState<any>("")
	const [dialogDescription, setDialogDescription] = useState<any>("")

	const [projectSelected, setProjectSelected] = useState<any>("")
	const [projects, setProjects] = useState<[]>([])

	const [isError, setIsError] = useState<boolean>(false)

	const { t } = useTranslation()

	const backPath = useQuery("backPath")

	const navigate = useNavigate()

	const { light } = useContext(GlobalContext)

	const { serviceId } = useParams()

	const methods = useForm<any>()
	const {
		control,
		getValues,
		reset,
		formState: { isValid },
		register,
		watch,
	} = methods

	useEffect(() => {
		fetchData()
	}, [])

	const fetchData = async () => {
		setLoading(true)
		try {
			const {
				data: { data },
			} = await searchService(serviceId)
			reset(data)
			listProjects(data?.projectId)
		} catch (error) {
		} finally {
			setLoading(false)
		}
	}

	const handleSubmit = async () => {
		const formValues = getValues()

		const objectService = {
			...formValues,
			projectId: projectSelected?.id,
		}

		setIsError(false)

		if (isValid) {
			setLoading(true)
			try {
				await updateService(serviceId, objectService)
				setDialogTitle(t("request_messages.edit_success_title", { name: "Serviço" }))
				setDialogDescription(t("request_messages.edit_success_description", { name: "Serviço" }))
			} catch (error: any) {
				setIsError(true)
				setDialogTitle(t("request_messages.500"))
				if (error?.response?.data?.messages) {
					setDialogDescription(createErrorMessage(error?.response?.data?.messages))
				} else {
					setDialogDescription(t("request_messages.400"))
				}
			} finally {
				setDialog(true)
				setLoading(false)
			}
		}
	}

	const listProjects = async (projectSelectId?: string) => {
		try {
			const {
				data: { data },
			} = await listProjectsToSelect()

			if (projectSelectId) {
				const selectedProject = data.filter((e: any) => e?.id === projectSelectId)
				setProjectSelected(selectedProject[0])
			}

			setProjects(data)
		} catch (error) {}
	}

	const handleAccept = () => {
		setDialog(false)
		if (!isError) {
			navigate(pathNames.service.list)
		}
	}

	const { fetch } = useManifest({ methods })

	const handleAutoComplete = (event: any, option: any) => {
		setProjectSelected(option)
	}

	if (loading || projects.length === 0) return <Loading />

	return (
		<ContainerPage>
			<HeaderPage
				onClickSubmit={handleSubmit}
				onClickTopButton={() => navigate(backPath ?? pathNames.service.list)}
				description={t("service.description_edit")}
				isEdit={true}
				title={t("service.edit_title")}
				buttonAttrs={{
					form: "edit-service",
					type: "submit",
				}}
			/>
			<BodyPage>
				<FormProvider {...methods}>
					<form onSubmit={methods.handleSubmit(handleSubmit)} id="edit-service">
						<Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
							<Grid item xs={12} sm={6}>
								<ControlledTextField
									id={"name"}
									style={styles.fullWidth}
									label={t("service.name")}
									control={control}
									rules={{ required: true }}
								/>
							</Grid>
							<Grid item xs={12} sm={6}>
								{projects.length > 0 && (
									<Autocomplete
										disablePortal
										size={"small"}
										options={projects}
										defaultValue={projectSelected}
										getOptionLabel={(option: any) => option.name || ""}
										onChange={handleAutoComplete}
										renderInput={(params) => (
											<TextField
												{...params}
												variant={"filled"}
												color={"secondary"}
												label={t("service.project")}
											/>
										)}
									/>
								)}
							</Grid>
							<Grid item xs={12}>
								<ControlledTextField
									id={"description"}
									style={styles.textFieldMarginTop}
									label={t("service.description")}
									control={control}
									rules={{ required: true }}
								/>
							</Grid>
							<Grid item xs={8} md={9} lg={10}>
								<ControlledTextField
									id={"url"}
									style={styles.textFieldMarginTop}
									validChars={
										/^(https?:\/\/)?(www\.)?([a-zA-Z\d-]+\.){0,}[a-zA-Z]{2,}(\S*)?$|^(\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3})$/
									}
									label={t("service.url")}
									control={control}
									rules={{ required: true }}
								/>
							</Grid>

							<Grid item xs={4} md={3} lg={2} style={{ display: "flex", alignItems: "center" }}>
								<FormControlLabel
									control={
										<Checkbox
											{...register(`usePrivateNetwork`)}
											checked={watch(`usePrivateNetwork`)}
										/>
									}
									label={
										<Tooltip title="Utilizar acesso direto via rede interna nos casos em que esta conexão é possível (sem uso do API Gateway)">
											<Typography color={light ? "black" : "white"}>
												{t("service.use_private_network")}
											</Typography>
										</Tooltip>
									}
								/>
							</Grid>

							<Grid item xs={8} md={9} lg={10}>
								<ControlledTextField
									id={"manifestUrl"}
									validChars={
										/^(https?:\/\/)?(www\.)?([a-zA-Z\d-]+\.){0,}[a-zA-Z]{2,}(\S*)?$|^(\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3})$/
									}
									style={styles.textFieldMarginTop}
									label={t("service.manifestUrl")}
									placeholder={watch("url") + "/manifest"}
									control={control}
								/>
							</Grid>

							<Grid item xs={4} md={3} lg={2} style={styles.active}>
								<Button
									variant="outlined"
									startIcon={<SyncIcon />}
									color={"secondary"}
									style={styles.button}
									onClick={fetch}
								>
									{t("service.update_button")}
								</Button>
							</Grid>
						</Grid>

						<Grid item xs={12} marginTop={3}>
							<Divider style={styles.divider} />
						</Grid>

						<BatchFields />

						<ExecutionOptions />

						<SuccessBillingOptions />
					</form>
				</FormProvider>
			</BodyPage>

			<DialogAlert
				onlyConfirm={true}
				show={dialog}
				accept={handleAccept}
				title={dialogTitle}
				description={dialogDescription}
			/>
		</ContainerPage>
	)
}
