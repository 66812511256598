import ReportProblemIcon from "@mui/icons-material/ReportProblem"

import { Content, Wrapper } from "./styles"

interface SideMenuItemTipProps {
	text: string
}

export const SideMenuItemTip = ({ text }: SideMenuItemTipProps) => {
	return (
		<Wrapper>
			<ReportProblemIcon style={{ fontSize: "0.9rem" }} />
			<Content>{text}</Content>
		</Wrapper>
	)
}
