import axios, { AxiosError, InternalAxiosRequestConfig } from "axios"

import { AuthStorage } from "../../storage/auth-type"
import { getItem } from "../../storage/local-storage"

const axiosInstance = axios.create({
	headers: {
		"Content-Type": "application/json",
	},
})

axiosInstance.interceptors.request.use(
	(config: InternalAxiosRequestConfig) => {
		const userApiKey = getItem(AuthStorage.userApiKey)
		const userToken = getItem(AuthStorage.userToken)

		if (userApiKey) {
			config.headers.set("apikey", userApiKey?.apiKey)
		}

		if (userToken) {
			config.headers.Authorization = `Bearer ${userToken.access_token}`
		}

		return config
	},
	(error: AxiosError) => {
		throw error
	},
)

axiosInstance.interceptors.response.use(
	(response) => response,
	(error: AxiosError) => {
		throw error
	},
)

export default axiosInstance
