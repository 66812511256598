import { List } from "@mui/material"

import { Container } from "./styles"

const sxListConfig = { width: "100%" }

interface SideMenuRootProps {
	children: React.ReactNode
}

export const SideMenuRoot = ({ children }: SideMenuRootProps) => {
	return (
		<Container>
			<List sx={sxListConfig} component="nav" aria-labelledby="nested-list-subheader">
				{children}
			</List>
		</Container>
	)
}
