import { IconButton as IconButtonDS, Button as ButtonDS } from "@mui/material"
import styled from "styled-components"

import { ReactComponent as ResolvOneDarklogo } from "../../assets/images/resolvone-dark.svg"
import { ReactComponent as ResolvOneLogo } from "../../assets/images/resolvone.svg"
import { maxDeviceSizeForMediaQueries } from "../../styles/devices-medias"

export const Container = styled.div`
	width: 100%;
	height: 80px;
	display: flex;
	align-items: center;
	padding: 0 32px;
	box-sizing: border-box;
	justify-content: space-between;
	border-bottom: 1px solid rgba(203, 203, 203, 0.3);
	position: fixed;
	top: 0;
	left: 0;
	z-index: 10;
	background-color: ${(props) => props.theme.palette.background.default};
	backdrop-filter: blur(3px);
	@media ${maxDeviceSizeForMediaQueries.tablet} {
		padding: 0 16px;
	}
`
export const ContainerHome = styled.div`
	width: 100%;
	height: 80px;
	display: flex;
	align-items: center;
	padding: 0 128px;
	box-sizing: border-box;
	justify-content: space-between;
	border-bottom: 1px solid rgba(203, 203, 203, 0.3);
	position: fixed;
	top: 0;
	left: 0;
	z-index: 10;
	background-color: ${(props) => props.theme.palette.background.default};
	backdrop-filter: blur(3px);
	@media ${maxDeviceSizeForMediaQueries.tablet} {
		padding: 0 16px;
		position: fixed;
	}
`

export const Image = styled.img`
	width: 150px;
	cursor: pointer;
	@media ${maxDeviceSizeForMediaQueries.tablet} {
		width: 100px;
		margin-top: -6px;
		margin-left: 8px;
	}
`

export const Profile = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-end;

	@media ${maxDeviceSizeForMediaQueries.tablet} {
		display: none;
	}
`

export const ContainerMenu = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
`

export const IconButton = styled(IconButtonDS)`
	display: none !important;
	@media ${maxDeviceSizeForMediaQueries.tablet} {
		display: block !important;
	}
`

export const ContainerMenuMobile = styled.div`
	display: none;
	@media ${maxDeviceSizeForMediaQueries.tablet} {
		display: flex;
	}
`

export const MenuOption = styled.div`
	max-width: 250px;
	flex-wrap: wrap;
`

export const LogoImage = styled.img`
	width: 150px;
	cursor: pointer;
	@media ${maxDeviceSizeForMediaQueries.tablet} {
		width: 100px;
	}
`

export const Button = styled(ButtonDS)<{ $selected?: boolean }>`
	border-bottom: ${(props: any) =>
		props.$selected ? `2px solid ${props.theme.palette.secondary.main}` : 0} !important;
	border-radius: 0 !important;

	@media ${maxDeviceSizeForMediaQueries.tablet} {
		display: none !important;
	}
`

export const Logo = styled(ResolvOneLogo)`
	width: 130px;
	margin-right: 16px;
	cursor: pointer;
`

export const LogoDark = styled(ResolvOneDarklogo)`
	width: 130px;
	margin-right: 16px;
	cursor: pointer;
`
