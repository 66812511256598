export const useCurrency = () => {
	const currency = new Intl.NumberFormat("pt-BR", {
		style: "currency",
		currency: "BRL",
	})

	const formatCurrency = (value: number) => {
		return currency.format(value)
	}

	return {
		formatCurrency,
	}
}
