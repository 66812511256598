import { Grid } from "@mui/material"

interface APIExecuterFormFieldWrapperProps {
	size: "full" | "half"
	children: React.ReactNode
}

export const APIExecuterFormFieldWrapper = ({
	size,
	children,
}: APIExecuterFormFieldWrapperProps) => {
	return (
		<Grid item xs={size === "full" ? 12 : 6}>
			{children}
		</Grid>
	)
}
