import CloseIcon from "@mui/icons-material/Close"
import {
	Dialog,
	DialogContent,
	DialogTitle,
	IconButton,
	styled,
	Table,
	TableBody,
	TableCell as MuiTableCell,
	TableContainer,
	TableRow,
	Paper,
} from "@mui/material"

import { AdvancedSearchResponse } from "../../../apis/services"

type ModalProps = {
	isOpen: boolean
	death: AdvancedSearchResponse
	handleClose: () => any
}

export function ModalDetails({ isOpen, death, handleClose }: ModalProps) {
	const BootstrapDialog = styled(Dialog)(({ theme }) => ({
		"& .MuiDialogContent-root": {
			padding: theme.spacing(2),
		},
		"& .MuiDialogActions-root": {
			padding: theme.spacing(1),
		},
	}))

	const TableCell = styled(MuiTableCell)({
		borderRight: "1px solid rgba(224, 224, 224, 1)",
	})

	const BoldTableCell = styled(MuiTableCell)({
		fontWeight: 700,
	})

	return (
		<>
			<BootstrapDialog
				onClose={handleClose}
				aria-labelledby="customized-dialog-title"
				open={isOpen}
			>
				<DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
					Detalhes do óbito
				</DialogTitle>
				<IconButton
					aria-label="close"
					onClick={handleClose}
					sx={{
						position: "absolute",
						right: 8,
						top: 8,
						color: (theme) => theme.palette.grey[500],
					}}
				>
					<CloseIcon />
				</IconButton>
				<DialogContent dividers style={{ width: "30vw" }}>
					<TableContainer component={Paper}>
						<Table aria-label="detalhes do óbito">
							<TableBody>
								<TableRow>
									<BoldTableCell component="th" scope="row">
										Base ID:
									</BoldTableCell>
									<TableCell>{death.baseId}</TableCell>
								</TableRow>
								<TableRow>
									<BoldTableCell component="th" scope="row">
										Nome do falecido:
									</BoldTableCell>
									<TableCell>{death.nomeFalecido}</TableCell>
								</TableRow>
								<TableRow>
									<BoldTableCell component="th" scope="row">
										CPF:
									</BoldTableCell>
									<TableCell>{death.cpf}</TableCell>
								</TableRow>
								<TableRow>
									<BoldTableCell component="th" scope="row">
										Nome da mãe:
									</BoldTableCell>
									<TableCell>{death.nomeMaeFalecido}</TableCell>
								</TableRow>
								<TableRow>
									<BoldTableCell component="th" scope="row">
										Data de nascimento:
									</BoldTableCell>
									<TableCell>{death.dataNascimento}</TableCell>
								</TableRow>
								<TableRow>
									<BoldTableCell component="th" scope="row">
										Data de óbito:
									</BoldTableCell>
									<TableCell>{death.dataObito}</TableCell>
								</TableRow>
								<TableRow>
									<BoldTableCell component="th" scope="row">
										Data de lavratura:
									</BoldTableCell>
									<TableCell>{death.dataLavrat}</TableCell>
								</TableRow>
								<TableRow>
									<BoldTableCell component="th" scope="row">
										Cartório ID:
									</BoldTableCell>
									<TableCell>{death.cartorioId}</TableCell>
								</TableRow>
								<TableRow>
									<BoldTableCell component="th" scope="row">
										Cartorio:
									</BoldTableCell>
									<TableCell>{death.cartorioNome}</TableCell>
								</TableRow>
								<TableRow>
									<BoldTableCell component="th" scope="row">
										Endereço do Cartório:
									</BoldTableCell>
									<TableCell>{death.cartorioEndereco}</TableCell>
								</TableRow>
								<TableRow>
									<BoldTableCell component="th" scope="row">
										Livro:
									</BoldTableCell>
									<TableCell>{death.livro}</TableCell>
								</TableRow>
								<TableRow>
									<BoldTableCell component="th" scope="row">
										Folha:
									</BoldTableCell>
									<TableCell>{death.folha}</TableCell>
								</TableRow>
							</TableBody>
						</Table>
					</TableContainer>
				</DialogContent>
			</BootstrapDialog>
		</>
	)
}
