import { Typography, TextField as TextFieldDS } from "@mui/material"
import styled from "styled-components"

import { maxDeviceSizeForMediaQueries } from "../../../styles/devices-medias"

export const Container = styled.div`
	width: 100%;
	height: 20%;
	display: flex;
	flex-direction: column;
`

export const Row = styled.div`
	flex: 1;
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	justify-content: space-between;
`

export const TitleHeader = styled(Typography)`
	font-size: 30px !important;
	font-weight: lighter;

	@media ${maxDeviceSizeForMediaQueries.tablet} {
		font-size: 22px !important ;
	}
`

export const TextField = styled(TextFieldDS)`
	width: 65%;
`
