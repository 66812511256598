import { useState, useEffect } from "react"
import { FormProvider, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useNavigate, useParams } from "react-router-dom"

import { Grid } from "@mui/material"

import { searchProject, updateProject } from "../../apis/projects"
import { BodyPage, ContainerPage } from "../../components/container/container.styles"
import DialogAlert from "../../components/dialog/dialog"
import { HeaderPage } from "../../components/header/page/header-page"
import { Loading } from "../../components/loading/loading"
import { ControlledTextField } from "../../components/text-field/controlled-text-field"
import { pathNames } from "../../routes/paths"
import { styles } from "../../styles/common"
import { createErrorMessage } from "../../utils/format"

export const EditProject = () => {
	const [loading, setLoading] = useState<boolean>(false)

	const [dialog, setDialog] = useState<boolean>(false)
	const [dialogTitle, setDialogTitle] = useState<any>("")
	const [dialogDescription, setDialogDescription] = useState<any>("")

	const [isError, setIsError] = useState<boolean>(false)

	const { t } = useTranslation()

	const navigate = useNavigate()

	const { projectId } = useParams()

	const methods = useForm<any>()
	const {
		control,
		getValues,
		formState: { isValid },
		reset,
	} = methods

	useEffect(() => {
		fetchData()
		// eslint-disable-next-line
	}, [])

	const fetchData = async () => {
		setLoading(true)
		try {
			const {
				data: { data },
			} = await searchProject(projectId)
			reset(data)
		} catch (error) {
		} finally {
			setLoading(false)
		}
	}

	const handleSubmit = async () => {
		const formValues = getValues()

		const projectObject = {
			...formValues,
		}

		setIsError(false)

		if (isValid) {
			setLoading(true)
			try {
				await updateProject(projectId, projectObject)
				setDialogTitle(t("request_messages.edit_success_title", { name: "Projeto" }))
				setDialogDescription(t("request_messages.edit_success_description", { name: "Projeto" }))
			} catch (error: any) {
				setIsError(true)
				setDialogTitle(t("request_messages.500"))
				if (error?.response?.data?.messages) {
					setDialogDescription(createErrorMessage(error?.response?.data?.messages))
				} else {
					setDialogDescription(t("request_messages.400"))
				}
			} finally {
				setDialog(true)
				setLoading(false)
			}
		}
	}

	const handleAccept = () => {
		setDialog(false)
		if (!isError) {
			navigate(pathNames.project.list)
		}
	}

	if (loading) return <Loading />

	return (
		<ContainerPage>
			<HeaderPage
				onClickSubmit={handleSubmit}
				onClickTopButton={() => navigate(pathNames.project.list)}
				description={t("project.description_edit")}
				isEdit={true}
				disabledButton={!isValid}
				title={t("project.edit_title")}
			/>
			<BodyPage>
				<FormProvider {...methods}>
					<Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
						<Grid item xs={12}>
							<ControlledTextField
								id={"name"}
								style={styles.fullWidth}
								label={t("project.name")}
								control={control}
								rules={{ required: true }}
							/>
						</Grid>
						<Grid item xs={12}>
							<ControlledTextField
								id={"description"}
								style={styles.textFieldMarginTop}
								label={t("project.description")}
								control={control}
								rules={{ required: true }}
							/>
						</Grid>
					</Grid>
				</FormProvider>
			</BodyPage>
			<DialogAlert
				onlyConfirm={true}
				show={dialog}
				accept={handleAccept}
				title={dialogTitle}
				description={dialogDescription}
			/>
		</ContainerPage>
	)
}
