import { ElementType } from "react"

import { Button } from "@mui/material"

interface APIExecuterFormActionProps {
	onClick: (e: React.MouseEvent) => void
	icon: ElementType
	isDisabled?: boolean
	children: React.ReactNode
}

export const APIExecuterFormAction = ({
	onClick,
	icon: Icon,
	isDisabled,
	children,
}: APIExecuterFormActionProps) => {
	return (
		<Button
			disabled={isDisabled}
			onClick={onClick}
			variant={"outlined"}
			endIcon={<Icon />}
			size="small"
			style={{ alignSelf: "start", marginLeft: "1rem" }}
		>
			{children}
		</Button>
	)
}
