import { useEffect } from "react"
import { Controller, FormProvider } from "react-hook-form"
import { useNavigate } from "react-router-dom"

import { Checkbox, Divider, FormControlLabel, Grid, Typography } from "@mui/material"

import { BodyPage, FormContainerPage } from "../../components/container/container.styles"
import DialogAlert from "../../components/dialog/dialog"
import { HeaderPage } from "../../components/header/page/header-page"
import { Loading } from "../../components/loading/loading"
import { ProductSelect } from "../../components/product-select/product-select"
import { ControlledTextField } from "../../components/text-field/controlled-text-field"
import { useAPIKey } from "../../hooks/api-key/use-api-key"
import { pathNames } from "../../routes/paths"
import { styles } from "../../styles/common"

export const CreateAPIKey = () => {
	const navigate = useNavigate()
	const {
		methods,
		loading,
		dialog,
		dialogTitle,
		dialogDescription,
		setShowDialogHistoric,
		products,
		handleAccept,
		handleAddProduct,
		handleDeleteProduct,
		handleUpdateValue,
		handleCreateOrganization,
		t,
	} = useAPIKey()

	const {
		control,
		getValues,
		formState: { errors },
		watch,
		reset,
	} = methods

	watch("trial")

	useEffect(() => {
		reset({
			trial: false,
			active: true,
			services: [],
		})
	}, [])

	if (loading) return <Loading />

	return (
		<FormContainerPage onSubmit={handleCreateOrganization}>
			<HeaderPage
				onClickTopButton={() => navigate(pathNames.apiKey.list)}
				description={t("user.create_api_key_description")}
				isEdit={true}
				title={t("user.create_api_key")}
			/>
			<BodyPage>
				<FormProvider {...methods}>
					<Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
						<Grid item xs={10}>
							<ControlledTextField
								id={"description"}
								style={styles.fullWidth}
								label={t("columns.description")}
								control={control}
								rules={{ required: false }}
								errors={errors.description}
								helperText={errors.description?.message}
							/>
						</Grid>

						<Grid item xs={2}>
							<Controller
								control={control}
								name="trial"
								render={({ field: { value, ...fieldValues } }) => (
									<FormControlLabel
										{...fieldValues}
										control={<Checkbox checked={Boolean(value)} />}
										label={<Typography color={"secondary"}>{t("columns.trial")}</Typography>}
									/>
								)}
							/>
						</Grid>
					</Grid>

					{getValues("trial") && (
						<>
							<Divider style={styles.divider} />

							<Grid margin={"0 0 8px 0"}>
								<Typography color={"secondary"} variant={"h6"}>
									{t("api_keys.lead_data_title")}
								</Typography>

								<Typography color="textPrimary" variant={"body2"}>
									{t("api_keys.lead_data_description")}
								</Typography>
							</Grid>
							<Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
								<Grid item xs={6}>
									<ControlledTextField
										id={"lead.name"}
										style={styles.fullWidth}
										label={t("columns.name")}
										control={control}
										rules={{ required: true }}
										errors={errors.lead?.name}
										helperText={errors.lead?.name?.message}
									/>
								</Grid>

								<Grid item xs={3}>
									<ControlledTextField
										id={"lead.company"}
										style={styles.fullWidth}
										label={t("columns.company")}
										control={control}
										rules={{ required: false }}
										errors={errors.lead?.company}
										helperText={errors.lead?.company?.message}
									/>
								</Grid>

								<Grid item xs={3}>
									<ControlledTextField
										id={"lead.role"}
										style={styles.fullWidth}
										label={t("columns.role")}
										control={control}
										rules={{ required: false }}
										errors={errors.description}
										helperText={errors.lead?.role?.message}
									/>
								</Grid>

								<Grid item xs={4}>
									<ControlledTextField
										id={"lead.utm"}
										style={styles.fullWidth}
										label={t("columns.utm")}
										control={control}
										rules={{ required: false }}
										errors={errors.lead?.utm}
										helperText={errors.lead?.utm?.message}
									/>
								</Grid>

								<Grid item xs={4}>
									<ControlledTextField
										id={"lead.email"}
										label={t("organizations.label_billing_email")}
										control={control}
										type={"email"}
										errors={errors.lead?.email}
										// eslint-disable-next-line
										validChars={
											/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
										}
										style={styles.fullWidth}
										helperText={errors.lead?.email?.message}
									/>
								</Grid>

								<Grid item xs={4}>
									<ControlledTextField
										id={"lead.phone"}
										style={styles.fullWidth}
										label={t("customer.label_phone")}
										control={control}
										rules={{ required: true }}
										mask={"(99) 99999-9999"}
										errors={errors.lead?.phone}
										helperText={errors.lead?.phone?.message}
									/>
								</Grid>

								<Grid item xs={12}>
									<ControlledTextField
										id={"lead.observation"}
										style={styles.fullWidth}
										label={t("columns.observation")}
										control={control}
										rules={{ required: false }}
										errors={errors.lead?.observation}
										helperText={errors.lead?.observation?.message}
									/>
								</Grid>
							</Grid>
						</>
					)}

					<Divider style={styles.divider} />

					<ProductSelect
						handleUpdateValue={handleUpdateValue}
						onClickHistoric={() => setShowDialogHistoric(true)}
						dataProducts={products as any}
						handleAddProduct={handleAddProduct}
						handleDeleteProduct={handleDeleteProduct}
					/>
				</FormProvider>
			</BodyPage>
			<DialogAlert
				onlyConfirm={true}
				show={dialog}
				accept={handleAccept}
				title={dialogTitle}
				description={dialogDescription}
			/>
			<DialogAlert
				onlyConfirm={true}
				show={dialog}
				accept={handleAccept}
				title={dialogTitle}
				description={dialogDescription}
			/>
		</FormContainerPage>
	)
}
