import { ReactNode } from "react"

import { Content, Description, Header, Title } from "./styles"

interface APIExecuterFormHeaderProps {
	title: string
	description: string
	children?: ReactNode
}

export const APIExecuterFormHeader = ({
	title,
	description,
	children,
}: APIExecuterFormHeaderProps) => {
	return (
		<Header>
			<Content>
				<Title color={"secondary"} variant={"h4"}>
					{title}
				</Title>

				{children}
			</Content>
			<Description color="textPrimary" variant={"body2"}>
				{description}
			</Description>
		</Header>
	)
}
