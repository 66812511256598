import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	styled,
} from "@mui/material"

type ModalConfirmProps = {
	isOpen: boolean
	handleConfirm: () => any
	handleDeny: () => any
}
export function ConfirmDialog({ isOpen, handleConfirm, handleDeny }: ModalConfirmProps) {
	const BootstrapDialog = styled(Dialog)(({ theme }) => ({
		"& .MuiDialogContent-root": {
			padding: theme.spacing(2),
		},
		"& .MuiDialogActions-root": {
			padding: theme.spacing(1),
		},
	}))

	return (
		<>
			<BootstrapDialog open={isOpen} onClose={handleDeny} aria-labelledby="responsive-dialog-title">
				<DialogTitle id="responsive-dialog-title">Deseja realmente remover esse óbito?</DialogTitle>
				<DialogContent>
					<DialogContentText>Você confirma a exclusão do obito?</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button autoFocus onClick={handleDeny}>
						Cancelar
					</Button>
					<Button onClick={handleConfirm} autoFocus>
						Remover
					</Button>
				</DialogActions>
			</BootstrapDialog>
		</>
	)
}
