import { MenuList } from "./components/menu-list"
import { Container } from "./menu.styles"

export const Menu = () => {
	return (
		<Container>
			<MenuList />
		</Container>
	)
}
