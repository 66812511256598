import React, { useContext, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"

import AppsIcon from "@mui/icons-material/Apps"
import MenuIcon from "@mui/icons-material/Menu"
import { Button, Divider, Menu, MenuItem, Typography } from "@mui/material"

import { GlobalContext } from "../../../contexts/global-provider"
import { ACTIONS_TYPES } from "../../../contexts/reducer"
import { getServicesDataByLanguage } from "../../../data/services"
import { pathNames } from "../../../routes/paths"
import { resizeText } from "../../../utils/format"
import TemporaryDrawerHome from "../../menu/main/menu-mobile-home"
import { SelectCountry } from "../../select/country/select-country"
import {
	ContainerHome,
	ContainerMenuMobile,
	IconButton,
	Logo,
	LogoDark,
	MenuOption,
} from "../header.styles"
import { ContainerMenu } from "./header-home.styles"

// const logo = require('../../../assets/images/resolv-one.png')
// const logoWhite = require('../../../assets/images/resolv-one-white.png')

const HeaderHome = () => {
	const { dispatch, light } = useContext(GlobalContext)

	const { t, i18n } = useTranslation()

	const navigate = useNavigate()

	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
	const open = Boolean(anchorEl)
	const [showDrawer, setShowDrawer] = useState<boolean>(false)

	const openProductsMenu = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget)
	}

	const closeProductsMenu = () => {
		setAnchorEl(null)
	}

	const openMenu = () => {
		setShowDrawer(!showDrawer)
	}

	const openDocs = () => {
		window.open("https://documenter.getpostman.com/view/27472588/2s93kz4jT2", "_blank")
	}

	const openLogin = () => {
		// @ts-ignore: Unreachable code error
		window.location.href = process.env.REACT_APP_COGNITO_URL
	}

	const selectProductMenu = (id: number) => {
		dispatch({ type: ACTIONS_TYPES.SET_HOME_PRODUCT_SHOW, value: id })
	}

	const navigateToHome = () => {
		selectProductMenu(0)
		navigate(pathNames.home)
	}

	const navigateToHomeSpecificProduct = (e: any) => {
		navigate(pathNames.home)
		selectProductMenu(e.id)
	}

	return (
		<ContainerHome>
			<ContainerMenuMobile>
				<IconButton onClick={openMenu}>
					<MenuIcon fontSize={"large"} />
				</IconButton>
				{!light ? <LogoDark onClick={navigateToHome} /> : <Logo onClick={navigateToHome} />}
			</ContainerMenuMobile>
			<ContainerMenu>
				{!light ? <LogoDark onClick={navigateToHome} /> : <Logo onClick={navigateToHome} />}
				<Button
					onClick={openProductsMenu}
					variant="text"
					color={"secondary"}
					endIcon={<AppsIcon color={"secondary"} />}
				>
					{t("common.products")}
				</Button>
				<Button onClick={openDocs} variant="text" color={"secondary"}>
					{t("common.docs")}
				</Button>
				<Button
					onClick={() => navigate(pathNames.lab.root, { state: { isFromHome: true } })}
					variant="text"
					color={"secondary"}
				>
					{t("menu.try_it")}
				</Button>
			</ContainerMenu>
			<ContainerMenu>
				<Button onClick={openLogin} variant={"text"} color={"secondary"}>
					{t("header.access_platform")}
				</Button>
				<SelectCountry />
			</ContainerMenu>
			<Menu
				anchorEl={anchorEl}
				id="account-menu"
				open={open}
				onClose={() => closeProductsMenu()}
				onClick={() => closeProductsMenu()}
			>
				{getServicesDataByLanguage(i18n.language).map((e) => (
					<div key={e.id}>
						<MenuItem onClick={() => navigateToHomeSpecificProduct(e)}>
							<MenuOption>
								<Typography color={"secondary"}>{e.name}</Typography>
								<Typography
									style={{ whiteSpace: "break-spaces" }}
									variant={"caption"}
									color={"GrayText"}
								>
									{resizeText(e.description, 80)}
								</Typography>
							</MenuOption>
						</MenuItem>
						<Divider />
					</div>
				))}
			</Menu>
			<TemporaryDrawerHome
				openDrawer={showDrawer}
				callbackDrawer={(state) => setShowDrawer(state)}
			/>
		</ContainerHome>
	)
}

export default HeaderHome
