import styled, { keyframes } from "styled-components"

import { ReactComponent as JuliaVector } from "../../../assets/julia/Image_julia.svg"
import { maxDeviceSizeForMediaQueries } from "../../../styles/devices-medias"

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

export const SvgJulia = styled(JuliaVector)`
	@media ${maxDeviceSizeForMediaQueries.tablet} {
		height: 300px;
	}
`

export const Container = styled.div`
	width: 100%;
	box-sizing: border-box;
	height: 500px;
	padding: 16px 128px;
	background: ${(props) => props.theme.palette.secondary.contrastText};
	display: flex;
	flex-direction: row;
	background: linear-gradient(
		90deg,
		rgba(31, 64, 119, 1) 0%,
		rgba(31, 64, 119, 1) 43%,
		rgba(48, 149, 135, 1) 100%
	);
	justify-content: space-between;
	animation: ${fadeIn} 300ms ease-in-out forwards;

	@media ${maxDeviceSizeForMediaQueries.tablet} {
		flex-direction: column-reverse;
		height: 100%;
		padding: 16px 16px;
		box-sizing: border-box;
	}
`

export const Information = styled.div`
	display: flex;
	flex: 1;
	flex-direction: column;
	justify-content: center;
`

export const ContainerImage = styled.div`
	display: flex;
	flex: 1;
	justify-content: center;
	align-items: center;
`

export const Title = styled.h1`
	padding: 0;
	margin: 0;
	font-size: 48px;
	color: ${(props) => props.theme.palette.primary.contrastText};
	@media ${maxDeviceSizeForMediaQueries.tablet} {
		text-align: center;
		font-size: 40px;
	}
`

export const Description = styled.p`
	padding: 0;
	margin: 0;
	margin: 16px 0;
	color: #c4c4c4;
	max-width: 600px;
	@media ${maxDeviceSizeForMediaQueries.tablet} {
		width: 100%;
		text-align: center;
	}
`

export const Button = styled.button`
	max-width: 250px;
	padding: 16px;
	background-color: ${(props) => props.theme.palette.primary.contrastText};
	border-radius: 50px;
	color: ${(props) => props.theme.palette.secondary.main};
	border: 1px solid ${(props) => props.theme.palette.primary.contrastText};
	font-size: 16px;
	cursor: pointer;
	&:hover {
		background-color: white;
		color: ${(props) => props.theme.palette.secondary.main};
		border: 1px solid ${(props) => props.theme.palette.secondary.main};
	}
	@media ${maxDeviceSizeForMediaQueries.tablet} {
		min-width: 100%;
	}
`
