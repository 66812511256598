import { cnpj as cnpjValidator } from "cpf-cnpj-validator"
import { Dayjs } from "dayjs"
import { z } from "zod"

import {
	BillingRecorrence,
	OrganizationService,
	OrganizationServiceCostRange,
	ServiceBillingType,
} from "../../../models/Organization"
import { serviceBillingParamsSchema, serviceSchema } from "../shared"

export const organizationAddressSchema = z.object({
	id: z.string().optional(),
	street: z.string().min(1, "Campo obrigatório").max(255, "Logradouro muito extenso"),
	neighborhood: z.string().min(1, "Bairro é obrigatório").max(255, "Bairro muito extenso"),
	city: z.string().min(3, "Campo obrigatória").max(255, "Cidade muito extensa"),
	state: z.string().min(2, "Campo obrigatório").max(255, "Estado muito extenso"),
	country: z.string().min(3, "Campoo obrigatório").max(255, "País muito extenso"),
	number: z.string().min(1, "Campo obrigatório").max(255, "Número muito extenso"),
	zipCode: z.string().min(5, "Campo obrigatório").max(8, "CEP muito extenso"),
	complement: z.string().min(3, "Campo obrigatório").max(255, "Complemento muito extenso"),
})

export const createOrganizationSchema = z
	.object({
		id: z.string().optional(),
		name: z.string().min(3, "Nome muito curto").max(255, "Nome muito longo"),
		cnpj: z
			.string({
				required_error: "Campo obrigatório",
			})
			.refine(cnpjValidator.isValid, {
				message: "CNPJ inválido",
			}),
		billingEmail: z.string().email("Email inválido"),
		billingRecorrence: z.enum(["monthly", "loose"]).default("monthly"),
		imageUrl: z.string().url("Url inválida").optional(),
		//dayjs type
		contractExpiresAt: z
			.custom<Dayjs>((value: any) => {
				return value?.$isDayjsObject
			})
			.optional(),
		segment: z.string({
			required_error: "Campo obrigatório",
		}),
		status: z.string().optional().default("active"),
		active: z.boolean().default(true),
		address: organizationAddressSchema,
		services: z
			.array(serviceSchema.merge(serviceBillingParamsSchema))
			.superRefine((services: any[], ctx) => {
				services.forEach((service: OrganizationService, index: number) => {
					let isValid = true
					let message = "Intervalo de faixa inválido"

					if (
						service.billingType === ServiceBillingType.SUCCESS &&
						service?.successBillingOptions?.length === 0
					) {
						ctx.addIssue({
							code: "invalid_type",
							message: "Nenhuma opção de sucesso selecionada",
							path: ["$" + index],
							expected: "array",
							received: "array",
						})
					}

					service.costRanges.forEach((costRange: OrganizationServiceCostRange, index: number) => {
						const nextCostRange = service.costRanges[index + 1]

						if (costRange.usageStart > costRange.usageEnd) {
							isValid = false
						}
						if (nextCostRange && costRange.usageEnd >= nextCostRange.usageStart) {
							isValid = false
						}

						if (!service.unlimited && costRange.usageEnd > service.usageLimit) {
							isValid = false
							message = "Faixa excede o limite de uso"
						}
					})
					if (!isValid) {
						ctx.addIssue({
							code: "invalid_type",
							message,
							path: ["$" + index],
							expected: "array",
							received: "array",
						} as any)
					}
				})
			}),
	})
	.refine(
		(data) => {
			return data.billingRecorrence === BillingRecorrence.LOOSE
				? data.contractExpiresAt?.isAfter(new Date())
				: true
		},
		{ path: ["contractExpiresAt"], message: "Deve ser uma data futura" },
	)

export const segmentSchema = z.object({
	key: z.string(),
	value: z.string(),
})

export type CreateOrganizationData = z.infer<typeof createOrganizationSchema>
export type Segment = z.infer<typeof segmentSchema>
