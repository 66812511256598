import "./i18n/config"
import { GlobalProvider } from "./contexts"
import Wrap from "./Wrap"

function App() {
	return (
		<GlobalProvider>
			<Wrap />
		</GlobalProvider>
	)
}

export default App
