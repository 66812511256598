import { Card as CardDS } from "@mui/material"
import styled from "styled-components"

import { maxDeviceSizeForMediaQueries } from "../../../styles/devices-medias"

export const Container = styled.div`
	padding: 16px 128px;
	@media ${maxDeviceSizeForMediaQueries.tablet} {
		padding: 16px 16px;
	}
`

export const CardsServices = styled.div`
	/* display: flex;
  flex-direction: row;
  gap: 16px;
  max-width: 100%;
  overflow-y: scroll; */
`

export const Header = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
`

export const Title = styled.h2`
	color: ${(props) => props.theme.palette.secondary.main};
	font-size: 40px;
	@media ${maxDeviceSizeForMediaQueries.tablet} {
		font-size: 32px;
	}
`

export const Line = styled.div`
	width: 150px;
	margin: 0 32px;
	height: 1px;
	background: ${(props) => props.theme.palette.secondary.main};
	opacity: 0.75;
`

export const ContainerCards = styled.div`
	display: flex;
	flex-direction: row;
	gap: 16px;
	box-sizing: border-box;

	@media ${maxDeviceSizeForMediaQueries.tablet} {
		flex-direction: column;
	}
`

export const Card = styled(CardDS)`
	width: 50%;
	@media ${maxDeviceSizeForMediaQueries.tablet} {
		width: 100%;
	}
`
