import styled from "styled-components"

import { maxDeviceSizeForMediaQueries } from "../../../../../../styles/devices-medias"

export const Container = styled.div`
	display: none;
	justify-content: flex-end;
	padding: 0 16px;
	@media ${maxDeviceSizeForMediaQueries.tablet} {
		display: flex;
	}
`
