import {
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
} from "@mui/material"

import { formatProductConsumptionRelationByClient } from "../../utils/format"

export const ConsumptionTable = ({
	data,
	title,
}: {
	data: ReturnType<typeof formatProductConsumptionRelationByClient>
	title: string
}) => {
	return (
		<TableContainer
			component={Paper}
			sx={{
				maxWidth: "90%",
				marginBottom: "2rem",
			}}
		>
			<Typography variant="h5" align="center" gutterBottom>
				{title}
			</Typography>
			<Table aria-label="simple table">
				<TableHead>
					<TableRow>
						<TableCell>Cliente</TableCell>
						<TableCell align="left">Produto</TableCell>
						<TableCell align="left">Forma de consulta</TableCell>
						<TableCell align="left">Tipo de consulta</TableCell>
						<TableCell align="left">Qtd. consultas</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{data.map((row) => (
						<TableRow key={row.service}>
							<TableCell component="th" scope="row">
								{row.clientName}
							</TableCell>
							<TableCell align="left">{row.service}</TableCell>
							<TableCell align="left">{row.requestType}</TableCell>
							<TableCell align="left">{row.processingType}</TableCell>
							<TableCell align="left">{row.totalUsage}</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
		</TableContainer>
	)
}
