import { useEffect, useState } from "react"
import { Controller, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useNavigate, useParams } from "react-router-dom"

import { FormControlLabel, Grid, Switch, Typography } from "@mui/material"

import { zodResolver } from "@hookform/resolvers/zod"
import { searchIp, updateIp } from "../../apis/ips"
import { BodyPage, FormContainerPage } from "../../components/container/container.styles"
import DialogAlert from "../../components/dialog/dialog"
import { HeaderPage } from "../../components/header/page/header-page"
import { Loading } from "../../components/loading/loading"
import { ControlledTextField } from "../../components/text-field/controlled-text-field"
import { CreateIPLiberationData } from "../../libs/zod/ips-liberation/create-ip-liberation"
import {
	EditIPLiberationData,
	EditIPLiberationSchema,
} from "../../libs/zod/ips-liberation/edit-ip-liberation"
import { pathNames } from "../../routes/paths"
import { styles } from "../../styles/common"
import { createErrorMessage } from "../../utils/format"

export const EditIp = () => {
	const [loading, setLoading] = useState<boolean>(false)

	const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false)
	const [active, setActive] = useState<boolean>(true)

	const [dialog, setDialog] = useState<boolean>(false)
	const [dialogTitle, setDialogTitle] = useState<any>("")
	const [dialogDescription, setDialogDescription] = useState<any>("")

	const { t } = useTranslation()

	const navigate = useNavigate()

	const { ipId } = useParams()

	const {
		control,
		formState: { errors },
		reset,
		handleSubmit,
	} = useForm<EditIPLiberationData>({
		resolver: zodResolver(EditIPLiberationSchema),
	})

	useEffect(() => {
		fetchData()
	}, [])

	const fetchData = async () => {
		setLoading(true)
		try {
			const {
				data: { data },
			} = await searchIp(ipId)
			reset({
				...data,
				id: ipId,
			})
		} catch (error) {
		} finally {
			setLoading(false)
		}
	}

	const handleCreateIpSubmit = async (data: CreateIPLiberationData) => {
		setLoading(true)
		try {
			await updateIp(ipId, data)
			setDialogTitle(t("request_messages.edit_success_title", { name: "IP" }))
			setDialogDescription(t("request_messages.edit_success_description", { name: "IP" }))
		} catch (error: any) {
			setDialogTitle(t("request_messages.500"))
			if (error?.response?.data?.messages) {
				setDialogDescription(createErrorMessage(error?.response?.data?.messages))
			} else {
				setDialogDescription(t("request_messages.400"))
			}
		} finally {
			setDialog(true)
			setLoading(false)
		}
	}

	const handleAccept = () => {
		setDialog(false)
	}

	if (loading) return <Loading />

	return (
		<FormContainerPage onSubmit={handleSubmit(handleCreateIpSubmit)}>
			<HeaderPage
				onClickTopButton={() => navigate(pathNames.settings.ip_liberation.list)}
				description={t("ips.description_edit")}
				isEdit={true}
				title={t("ips.edit_title")}
			/>
			<BodyPage>
				<Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
					<Grid item xs={12} md={5}>
						<ControlledTextField
							id={"startIp"}
							style={styles.textFieldMarginTop}
							label={t("ips.startIp")}
							control={control}
							maskPlaceholder="0"
							alwaysShowMask
							errors={errors.startIp}
							rules={{ required: true }}
							helperText={errors.startIp?.message}
						/>
					</Grid>

					<Grid item xs={12} md={5}>
						<ControlledTextField
							id={"endIp"}
							maskPlaceholder="0"
							style={styles.textFieldMarginTop}
							label={t("ips.endIp")}
							control={control}
							errors={errors.endIp}
							rules={{ required: true }}
							helperText={errors.endIp?.message}
						/>
					</Grid>

					<Grid style={styles.active} item xs={2}>
						<Controller
							control={control}
							name="active"
							render={({ field: { value, ...fieldValues } }) => (
								<FormControlLabel
									control={<Switch {...fieldValues} color={"secondary"} checked={value} />}
									label={
										<Typography color={"secondary"}>{`${value ? "Ativo" : "Inativo"}`}</Typography>
									}
								/>
							)}
						/>
					</Grid>
				</Grid>
			</BodyPage>
			<DialogAlert
				title={t("ips.disable_message_title")}
				description={t("ips.disable_message_description")}
				show={showDeleteDialog}
				accept={() => {
					setActive(false)
					setShowDeleteDialog(false)
				}}
				decline={() => {
					setShowDeleteDialog(false)
					setActive(true)
				}}
			/>
			<DialogAlert
				onlyConfirm={true}
				show={dialog}
				accept={handleAccept}
				title={dialogTitle}
				description={dialogDescription}
			/>
		</FormContainerPage>
	)
}
