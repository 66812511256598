import { Typography } from "@mui/material"
import styled from "styled-components"

export const Container = styled.div`
	width: 100%;
`

export const Box = styled.div`
	display: flex;
	align-items: center;
`

export const Text = styled(Typography)`
	margin: 0;
	font-size: 12px;
	text-decoration: underline;
`
