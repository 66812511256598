import { useEffect, useState } from "react"
import { Controller, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"

import { zodResolver } from "@hookform/resolvers/zod"
import { FormHelperText, Grid, InputLabel, MenuItem, Select } from "@mui/material"

import { MultiSelectData } from "../../apis/dashboard"
import { createIp } from "../../apis/ips"
import { listOptionsOrganizations } from "../../apis/organization"
import { BodyPage, FormContainerPage } from "../../components/container/container.styles"
import DialogAlert from "../../components/dialog/dialog"
import { HeaderPage } from "../../components/header/page/header-page"
import { Loading } from "../../components/loading/loading"
import { ControlledTextField } from "../../components/text-field/controlled-text-field"
import { useAuthentication } from "../../hooks/app/useAuthentication"
import {
	CreateIPLiberationData,
	CreateIPLiberationSchema,
} from "../../libs/zod/ips-liberation/create-ip-liberation"
import { pathNames } from "../../routes/paths"
import { styles } from "../../styles/common"
import { createErrorMessage } from "../../utils/format"

export const CreateIP = () => {
	const [loading, setLoading] = useState<boolean>(false)

	const [dialog, setDialog] = useState<boolean>(false)
	const [dialogTitle, setDialogTitle] = useState<any>("")
	const [dialogDescription, setDialogDescription] = useState<any>("")
	const [isError, setIsError] = useState<boolean>(false)

	const { t } = useTranslation()

	const navigate = useNavigate()
	const { isAdmin, user } = useAuthentication()

	useEffect(() => {
		getOrganizations()
	}, [])

	const getOrganizations = async () => {
		if (!isAdmin) return
		try {
			const {
				data: { data },
			} = await listOptionsOrganizations()
			setOrganizations(data)
		} catch (error) {
		} finally {
		}
	}

	const [organizations, setOrganizations] = useState<MultiSelectData["data"]>([])

	const {
		control,
		formState: { errors },
		reset,
		handleSubmit,
	} = useForm<CreateIPLiberationData>({
		resolver: zodResolver(CreateIPLiberationSchema),
	})

	const handleAccept = () => {
		setDialog(false)
		if (!isError) {
			navigate(pathNames.settings.ip_liberation.list)
			reset()
		}
	}

	const handleCreateIpSubmit = async (data: CreateIPLiberationData) => {
		setLoading(true)
		setIsError(false)

		if (!isAdmin) {
			data.organizationId = user.organizationId
		}

		try {
			await createIp(data)
			setDialogTitle(t("request_messages.create_success_title", { name: "IP" }))
			setDialogDescription(t("request_messages.success_description", { name: "ip" }))
		} catch (error: any) {
			setIsError(true)
			setDialogTitle(t("request_messages.500"))
			if (error?.response?.data?.messages) {
				setDialogDescription(createErrorMessage(error?.response?.data?.messages))
			} else {
				setDialogDescription(t("request_messages.400"))
			}
		} finally {
			setDialog(true)
			setLoading(false)
		}
	}

	if (loading) return <Loading />

	return (
		<FormContainerPage onSubmit={handleSubmit(handleCreateIpSubmit)}>
			<HeaderPage
				onClickTopButton={() => navigate(pathNames.settings.ip_liberation.list)}
				description={t("ips.create_description")}
				isEdit={true}
				title={t("ips.create_title")}
			/>
			<BodyPage>
				<Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
					<Grid item xs={12} md={6}>
						<ControlledTextField
							id={"startIp"}
							style={styles.textFieldMarginTop}
							label={t("ips.startIp")}
							control={control}
							maskPlaceholder="0"
							alwaysShowMask
							errors={errors.startIp}
							rules={{ required: true }}
							helperText={errors.startIp?.message}
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<ControlledTextField
							id={"endIp"}
							maskPlaceholder="0"
							style={styles.textFieldMarginTop}
							label={t("ips.endIp")}
							control={control}
							errors={errors.endIp}
							rules={{ required: true }}
							helperText={errors.endIp?.message}
						/>
					</Grid>
					{isAdmin && (
						<Grid item xs={12}>
							<Controller
								control={control}
								name="organizationId"
								render={({ field: { onChange, value } }) => (
									<>
										<InputLabel color={"secondary"} id="organization-selector-label">
											{t("customer.label_organization")}
										</InputLabel>
										<Select
											color={"secondary"}
											labelId="organization-selector-label"
											id="organization-selector"
											style={styles.formControl}
											variant="filled"
											sx={{ m: 1, minWidth: 120, height: 45 }}
											onChange={onChange}
											value={value}
											error={!!errors.organizationId}
											label={t("customer.label_organization")}
										>
											{organizations.map((element: any, index) => (
												<MenuItem key={index} value={element?.id}>
													{element?.name}
												</MenuItem>
											))}
										</Select>
										{errors.organizationId && (
											<FormHelperText sx={{ color: "#d32f2f", marginLeft: "10px" }}>
												{errors.organizationId?.message}asda
											</FormHelperText>
										)}
									</>
								)}
							/>
						</Grid>
					)}
				</Grid>
			</BodyPage>
			<DialogAlert
				onlyConfirm={true}
				show={dialog}
				accept={handleAccept}
				title={dialogTitle}
				description={dialogDescription}
			/>
		</FormContainerPage>
	)
}
