import { useEffect, useState } from "react"
import { FormProvider, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"

import { Download as DownloadIcon } from "@mui/icons-material"
import { Alert, Autocomplete, DialogContentText, Grid, Link, TextField } from "@mui/material"
import { download, generateCsv, mkConfig } from "export-to-csv"

import { MultiSelectData } from "../../../apis/dashboard"
import { listOptionsOrganizations } from "../../../apis/organization"
import { createBatchConsult, searchService } from "../../../apis/services"
import {
	getSelfTeam,
	listAllTeams,
	searchSpecificTeam,
	searchTeamByOrganization,
} from "../../../apis/team"
import { BodyPage, ContainerPage } from "../../../components/container/container.styles"
import DialogAlert from "../../../components/dialog/dialog"
import { Dropzone } from "../../../components/dropzone/dropzone"
import { HeaderPage } from "../../../components/header/page/header-page"
import { HelpButton } from "../../../components/help-button/help-button"
import { Loading } from "../../../components/loading/loading"
import { Table } from "../../../components/table/table"
import { useDialog } from "../../../hooks/app/dialog/use-dialog"
import { Roles } from "../../../models/Client"
import { BatchServiceConsult } from "../../../models/ServiceConsult"
import { pathNames } from "../../../routes/paths"
import { AuthStorage } from "../../../storage/auth-type"
import { getItem } from "../../../storage/local-storage"
import { styles } from "../../../styles/common"

export const CreateServiceBatchQuery = (props: any) => {
	const [loading, setLoading] = useState<boolean>(false)

	const [organizations, setOrganizations] = useState<MultiSelectData["data"]>([])
	const [organizationSelected, setOrganizationSelected] = useState<any>(null)
	const [isFormatError, setIsFormatError] = useState<boolean>(false)
	const [products, setProducts] = useState<[]>([])
	const [selectedProduct, setSelectedProduct] = useState<any>(null)
	const [teams, setTeams] = useState<[]>([])
	const [teamSelected, setSelectedTeam] = useState<any>(null)
	const [isValid, setIsValid] = useState<boolean>(false)
	const [file, setFile] = useState<File | null>(null)
	const [productDetails, setProductDetails] = useState<any>(null)

	const {
		dialogTitle,
		dialogDescription,
		dialog,
		setDialog,
		setDialogTitle,
		setDialogDescription,
	} = useDialog()

	const polices = getItem(AuthStorage.userPolicy)

	const { organizationId, teamId } = getItem(AuthStorage.userInformation)

	const { t } = useTranslation()

	const navigate = useNavigate()

	const methods = useForm<BatchServiceConsult>()

	useEffect(() => {
		if (polices?.role === Roles.Admin) {
			getOrganizations()
			getTeams()
		} else {
			setSelectedTeam(teamId)
			setOrganizationSelected(organizationId)
			getSelfTeam().then(({ data }) => {
				setProducts(data.data.services)
			})
		}
	}, [])

	useEffect(() => {
		checkValidation()
	}, [organizationSelected, teamSelected, selectedProduct, file])

	useEffect(() => {
		if (selectedProduct) getProductDetails(selectedProduct?.serviceId)
	}, [selectedProduct])

	const getOrganizations = async () => {
		try {
			const {
				data: { data },
			} = await listOptionsOrganizations()
			setOrganizations(data)
		} catch (error) {
		} finally {
		}
	}

	const getTeams = async () => {
		try {
			const {
				data: { data },
			} = await listAllTeams({ pageSize: 20, currentPage: 1, filter: "" })
			setTeams(data?.list)
		} catch (error) {
		} finally {
		}
	}

	const getTeamsByOrganization = async (organizationId: string) => {
		try {
			const {
				data: { data },
			} = await searchTeamByOrganization(organizationId)
			setTeams(data)
		} catch (error) {}
	}

	const getTeamsProducts = async (teamId: string) => {
		try {
			const {
				data: { data },
			} = await searchSpecificTeam(teamId)
			setProducts(data.services)
		} catch (error) {}
	}

	const getProductDetails = async (productId: string) => {
		try {
			const {
				data: { data },
			} = await searchService(productId)
			setProductDetails(data)
		} catch (error) {}
	}

	const handleAccept = () => {
		setIsFormatError(false)
		setDialog(false)
	}

	const handleAutoCompleteTeams = async (event: any, option: any) => {
		setSelectedTeam(option)

		setSelectedProduct(null)
		await getTeamsProducts(option?.id)
	}

	const handleAutoCompleteOrganizations = (event: any, option: any) => {
		setSelectedTeam(null)
		setSelectedProduct(null)

		setTeams([])
		setProducts([])
		setOrganizationSelected(option)
		getTeamsByOrganization(option?.id)
	}

	const handleAutoCompleteProducts = (event: any, option: any) => {
		setSelectedProduct(option)
	}

	if (loading) return <Loading />

	function checkValidation() {
		const isValid = !!organizationSelected && !!teamSelected && !!file && !!selectedProduct

		setIsValid(isValid)
	}

	const handleFileDrop = (files: any) => {
		setFile(files[0])
		checkValidation()
	}

	const openModalOfFileStructureInstructions = (errorMsg: any) => {
		setIsFormatError(true)
		setDialog(true)
		setDialogTitle(
			errorMsg
				? t("batch_consult.invalid_file_format.title")
				: t("batch_consult.invalid_file_format.instructions_title"),
		)
		setDialogDescription(t("batch_consult.invalid_file_format.description"))
	}

	const handleSubmit = async () => {
		if (!file) return
		setLoading(true)

		try {
			const result = await createBatchConsult({
				file,
				serviceId: selectedProduct?.serviceId,
				organizationId: organizationSelected?.id,
			})

			if (result) {
				navigate(pathNames.queries.listBatch)
			}
			setLoading(false)
		} catch (error) {
			setDialog(true)
			setDialogTitle(t("batch_consult.submition_error.title"))
			setDialogDescription(t("batch_consult.submition_error.description"))
			setLoading(false)
		}
	}

	return (
		<ContainerPage>
			<HeaderPage
				disabledButton={!isValid}
				onClickSubmit={handleSubmit}
				onClickTopButton={() => navigate(pathNames.queries.listBatch)}
				description={t("service_query.batch_service_query_description")}
				isEdit={true}
				title={t("service_query.new_query")}
				actionButtonTitle={t("service_query.dispatch")}
			/>
			<BodyPage>
				<FormProvider {...methods}>
					<Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
						{polices?.role === "admin" && (
							<Grid item xs={6}>
								{organizations.length > 0 && (
									<Autocomplete
										style={styles.textFieldMarginTop}
										disablePortal
										size={"small"}
										options={organizations}
										value={organizationSelected}
										getOptionLabel={(option: any) => option.name || ""}
										onChange={handleAutoCompleteOrganizations}
										renderInput={(params) => (
											<TextField
												{...params}
												variant={"filled"}
												color={"secondary"}
												label={t("service_query.label_organization")}
											/>
										)}
									/>
								)}
							</Grid>
						)}

						{polices?.role === "admin" && (
							<Grid item xs={6}>
								<Autocomplete
									style={styles.textFieldMarginTop}
									disabled={!organizationSelected}
									disablePortal
									size={"small"}
									options={teams}
									value={teamSelected}
									getOptionLabel={(option: any) => option.name || ""}
									onChange={handleAutoCompleteTeams}
									noOptionsText={t("service_query.no_team")}
									renderInput={(params) => (
										<TextField
											{...params}
											variant={"filled"}
											color={"secondary"}
											label={t("customer.label_team")}
										/>
									)}
								/>
							</Grid>
						)}
						<Grid item xs={12} style={{ marginBottom: 12 }}>
							<Autocomplete
								style={styles.textFieldMarginTop}
								disablePortal
								disabled={!teamSelected}
								size={"small"}
								options={products}
								value={selectedProduct}
								onChange={handleAutoCompleteProducts}
								noOptionsText={t("service_query.no_service")}
								getOptionLabel={(option: any) => option.name || ""}
								renderInput={(params) => (
									<TextField
										{...params}
										variant={"filled"}
										color={"secondary"}
										label={t("service_query.label_service")}
									/>
								)}
							/>
						</Grid>
					</Grid>
				</FormProvider>
				{selectedProduct && (
					<div
						style={{
							display: "flex",
							alignItems: "center",
							justifyContent: "flex-end",
							fontSize: "30px",
						}}
					>
						<HelpButton
							onClick={() => openModalOfFileStructureInstructions(false)}
							fontSize="inherit"
						/>
					</div>
				)}
				<Dropzone
					styles={{ marginTop: 4 }}
					disabled={!selectedProduct}
					onDrop={handleFileDrop}
					extensions={["text/csv"]}
					onDropRejected={openModalOfFileStructureInstructions}
					maxFiles={1}
				/>
				<Grid item xs={12} style={{ marginTop: 16 }}>
					<Alert severity={"info"} title="Importante">
						Ao finalizar o processamento do arquivo, você receberá um e-mail com um resumo da
						operação e o link para download do arquivo contendo os resultados.
					</Alert>
				</Grid>
			</BodyPage>

			<DialogAlert
				onlyConfirm={true}
				show={dialog}
				accept={handleAccept}
				title={dialogTitle}
				description={dialogDescription}
			>
				{productDetails?.batchFields?.length > 0 && <DialogContent product={productDetails} />}
			</DialogAlert>
		</ContainerPage>
	)
}

const DialogContent = ({ product }: any) => {
	const { t } = useTranslation()

	const csvConfig = mkConfig({
		useKeysAsHeaders: true,
		filename: `examplo_${product.name}.csv`,
	})

	const columns = product.batchFields.map((o: any) => ({
		label: o.name,
		id: o.name,
	}))

	const rows: any = {}
	product.batchFields.forEach((o: any) => {
		rows[o.name] = o.example
	})

	const csv = generateCsv(csvConfig)([rows])

	return (
		<>
			<DialogContentText>
				<p>{t("batch_consult.invalid_file_format.helper_text")}</p>
				<Table
					rows={[rows]}
					keys={columns.map((o: any) => o.id)}
					hidePagination={true}
					columns={columns}
					onDelete={() => null}
					onEdit={() => null}
					onClickLink={() => null}
					changePage={() => null}
					page={0}
					isEditVisible={false}
					isSelectable={false}
					changeRowsPerPage={() => null}
				/>
			</DialogContentText>
			<Link
				style={{
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
					cursor: "pointer",
					marginTop: 32,
				}}
				onClick={() => download(csvConfig)(csv)}
				color="secondary"
			>
				<DownloadIcon />
				Baixar exemplo
			</Link>
		</>
	)
}
