import { useTranslation } from "react-i18next"

import { format, isValid, parseISO } from "date-fns"
import dayjs from "dayjs"
import utc from "dayjs/plugin/utc"

import { DailyProductConsumption, OrganizationClientConsumptionEvolution } from "../apis/dashboard"

dayjs.extend(utc)

export type FormattedUsageData = {
	date: string
	value: {
		[key: string]: { [x: string]: number }
	}
	serviceName: string
}[]

export const formatNumberVisualization = (number: number) => {
	return number ? `${number.toLocaleString("pt-BR")}` : ""
}

export const createErrorMessage = (array: Array<string>) => {
	let message = ""
	// eslint-disable-next-line
	array.map((element) => {
		message += "* " + element + `\n`
	})
	return message
}

export function capitalizeFirstLetter(text: string) {
	return text.charAt(0).toUpperCase() + text.slice(1)
}

export function formatDataChart(response: any) {
	const labels = response.map((e: any) => e.name)
	const data = response.map((e: any) => Number(e.usedLimit))

	return { labels, data }
}

export const getCurrentDate = (): string => {
	const currentDate = new Date()
	const currentMonth = currentDate.getMonth() + 1
	const currentYear = currentDate.getFullYear()

	return currentMonth + "-" + currentYear
}

export const getCurrentLocaleDate = () => {
	const currentDate = new Date().toLocaleDateString("en-US", {
		month: "2-digit",
		day: "2-digit",
		year: "numeric",
	})

	return currentDate
}

export const formatDataChartTeamsService = (data: any) => {
	const arrayTeam = <any>[]
	const arrayServices = <any>[]
	const arrayServicesData = <any>[]

	const backgroundColor = ["#309587", "#1f4077", "#10444c"]

	data.forEach((element: any) => {
		arrayTeam.push(element.team)
		element.services.forEach((sv: any) => {
			arrayServices.push(sv.serviceName)
			arrayServicesData.push({
				label: sv.serviceName,
				data: Number(sv.usedLimit),
				team: sv.teamName,
			})
		})
	})

	const servicesUnique = removeDuplicates(arrayServices)
	const datasets = <any>[]

	servicesUnique.forEach((element: any) => {
		const dataTemp = <any>[]
		const service = arrayServicesData.filter(function (o: any) {
			return o.label === element
		})

		service.forEach((element: any) => {
			dataTemp.push(element.data)
		})

		datasets.push({
			label: element,
			data: dataTemp,
			backgroundColor,
		})
	})

	return { teams: arrayTeam, datasets }
}

export const formatReports =
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	(labelKey: string) => (data: any) => {
		const labels = data.map((e: any) => e[labelKey])

		return { labels, data }
	}

export const formatClassifiedConsumption = (
	res: Array<{
		classification: string
		usedLimit: string
		serviceName: string
	}>,
) => {
	const labels = res.map(({ classification }) => classification)

	return { labels, data: res }
}

export const formatChartDataWithLocaleDates = (
	dailyProductConsumptionData: DailyProductConsumption["data"],
): { labels: string[]; data: FormattedUsageData } => {
	const usageDataByDateAndService: {
		[date: string]: {
			[service: string]: (typeof dailyProductConsumptionData)[number]["usageData"][number]
		}
	} = {}

	const serviceNames = new Set<string>()

	dailyProductConsumptionData.forEach(({ serviceName, usageData }) => {
		serviceNames.add(serviceName)

		Object.entries(usageData).forEach(([dateString, value]) => {
			if (!usageDataByDateAndService[dateString]) {
				usageDataByDateAndService[dateString] = {}
			}

			usageDataByDateAndService[dateString][serviceName] = value
		})
	})

	const labels = Array.from(serviceNames)

	const data = Object.entries(usageDataByDateAndService)
		.map(([date, values]) => {
			return Object.entries(values).map(([serviceName, value]) => ({
				date: date,
				value: { [serviceName]: value },
				serviceName: serviceName,
			}))
		})
		.flat()

	return { labels, data }
}

export const formatEvolutiveClientConsumption = (
	data: OrganizationClientConsumptionEvolution["data"],
) => {
	const usageDataByClientAndDate: {
		[date: string]: {
			[client: string]: {
				[service: string]: {
					processingType: string
					requestType: string
					usageLimit: number
				}
			}
		}
	} = {}

	const clientNames = new Set<string>()
	const serviceNames = new Set<string>()

	data.forEach(({ clientName, usageDate, usageData }) => {
		clientNames.add(clientName)

		if (!usageDataByClientAndDate[usageDate]) {
			usageDataByClientAndDate[usageDate] = {}
		}

		usageDataByClientAndDate[usageDate][clientName] = {}

		Object.entries(usageData).forEach(([service, info]) => {
			serviceNames.add(service)
			usageDataByClientAndDate[usageDate][clientName][service] = info
		})
	})

	const labels = Array.from(clientNames)
	const services = Array.from(serviceNames)

	const dataArr = Object.entries(usageDataByClientAndDate).flatMap(([date, clients]) => {
		return Object.entries(clients).map(([clientName, servicesData]) => ({
			date: date,
			clientName: clientName,
			services: servicesData,
		}))
	})

	return { labels, services, data: dataArr }
}

export const formatProductConsumptionRelationByClient = (
	data: OrganizationClientConsumptionEvolution["data"],
): {
	clientName: string
	service: string
	totalUsage: number
	requestType: string
	processingType: string
}[] => {
	const result: {
		clientName: string
		service: string
		totalUsage: number
		requestType: string
		processingType: string
	}[] = []

	data.forEach(({ clientName, usageData }) => {
		Object.entries(usageData).forEach(([service, serviceDetails]) => {
			const { requestType, processingType, usageLimit } = serviceDetails

			const existingServiceIndex = result.findIndex(
				(entry) =>
					entry.clientName === clientName &&
					entry.service === service &&
					entry.requestType === requestType &&
					entry.processingType === processingType,
			)

			if (existingServiceIndex !== -1) {
				result[existingServiceIndex].totalUsage += Number(usageLimit)
			} else {
				result.push({
					clientName: clientName,
					service: service,
					totalUsage: Number(usageLimit),
					requestType: requestType,
					processingType: processingType,
				})
			}
		})
	})

	return result
}

const removeDuplicates = function (nums: any[]) {
	for (let i = nums.length - 1; i >= 0; i--) {
		for (let j = 0; j < i; j++)
			if (nums[j] === nums[i]) {
				nums.splice(i, 1)
				break
			}
	}

	return nums
}

export const resizeText = (text: string, maxLength: number) => {
	if (text.length <= maxLength) {
		return text
	}

	return text.substring(0, maxLength) + "..."
}

function randomArray(array: Array<any>) {
	for (let i = array.length - 1; i > 0; i--) {
		const j = Math.floor(Math.random() * (i + 1))
		;[array[i], array[j]] = [array[j], array[i]]
	}
	return array
}

export function buildMatrizArray(array: Array<any>) {
	const arrayRandom = randomArray([...array])
	const newMatriz = []
	for (let i = 0; i < arrayRandom.length; i += 2) {
		const firstStep = arrayRandom.slice(i, i + 2)
		newMatriz.push(firstStep)
	}
	return newMatriz
}

export function extractNumbersFromString(str: string) {
	return str.replace(/\D/g, "") // The regular expression /\D/g matches all non-digit characters globally.
}

export function formatTimeStopWatch(milliseconds: number) {
	const seconds = milliseconds / 1000

	if (seconds < 1) {
		return 0
	}

	return seconds.toFixed(2)
}

export const objectKeyToTranslation = (key: any) => {
	const { t } = useTranslation()

	const translationKey = t(`playground.${key.toLowerCase()}`)

	return translationKey
}

export const hasScoreClassification = (obj: any): boolean => {
	return obj.hasOwnProperty("scoreClassification")
}

export const openPDF = (pdfData: any, popupMessage: any) => {
	const blob = new Blob([pdfData], { type: "application/pdf" })

	const blobUrl = URL.createObjectURL(blob)

	const newTab = window.open(blobUrl, "_blank")

	URL.revokeObjectURL(blobUrl)

	if (!newTab) {
		alert(popupMessage)
	}
}

export const extractNumbersFromStringAndJoin = (cepWithMask: string) => {
	const numbersFound = cepWithMask.match(/\d+/g)
	if (numbersFound) {
		const extractedNumbers = numbersFound.join("")
		return extractedNumbers
	} else {
		return ""
	}
}

export const formatDateToLocaleWithHourOrReceiveInvalid = (
	strDate: string,
	invalidDateString: string,
) => {
	try {
		const date = new Date(strDate)
		if (isNaN(date.getTime())) return invalidDateString

		const options = {
			year: "numeric",
			month: "2-digit",
			day: "2-digit",
			hour: "2-digit",
			minute: "2-digit",
			hour12: false,
		} as Intl.DateTimeFormatOptions

		const result = date.toLocaleString("pt-BR", options)

		return result
	} catch (error) {
		console.log("🚀 ~ formatDateOrReceiveInvalid ~ error:", error)
	}
}

export const formatDateOrReceiveInvalid = (strDate: string, invalidDateString: string) => {
	return isValid(parseISO(strDate)) ? format(parseISO(strDate), "dd/MM/yyyy") : invalidDateString
}
