import styled from "styled-components"

export const DropzoneContainer = styled.div`
	width: 100%;
	height: 200px;
	box-sizing: border-box;
	border: 2px dashed #c4c4c4;
	border-radius: 5px;
	background-color: ${(props) => props.theme.palette.background.default};
	padding: 10px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	font-size: 12px;
	font-weight: 500;
	color: #c4c4c4;
	cursor: pointer;

	&.disabled {
		opacity: 0.5;
		cursor: not-allowed;
	}

	&:hover:not(.disabled) {
		border: 2px dashed #a4a4a4;
		color: #a4a4a4;
	}

	.icon {
		color: "#c4c4c4";
	}

	&:hover:not(.disabled) .icon {
		color: "#a4a4a4";
	}

	&:hover:not(.disabled) p {
		color: #a4a4a4;
	}
`

export const DropzoneText = styled.p`
	font-size: 16px;
	font-weight: 500;
	color: #c4c4c4;
`
