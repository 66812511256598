import { useTranslation } from "react-i18next"

import SentimentDissatisfiedIcon from "@mui/icons-material/SentimentDissatisfied"

import { ContainerPage } from "../../../../../components/container/container.styles"

export const APIExecuterNoServiceFound = () => {
	const { t } = useTranslation()
	return (
		<ContainerPage
			style={{
				display: "flex",
				flexDirection: "column",
				justifyContent: "center",
				alignItems: "center",
				marginLeft: "0",
				marginTop: "0",
			}}
		>
			<SentimentDissatisfiedIcon style={{ fontSize: "15rem", color: "#666" }} />
			<h1
				style={{
					color: "#666",
					fontSize: "2rem",
					marginBottom: "1rem",
				}}
			>
				{t("lab_api.no_services_title")}
			</h1>
			<p
				style={{
					color: "#999",
					fontSize: "1rem",
					textAlign: "center",
					margin: "0 2rem",
					maxWidth: "600px",
				}}
			>
				{t("lab_api.no_services_subtitle")}
			</p>
		</ContainerPage>
	)
}
