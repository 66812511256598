import { useMemo } from "react"
import { Chart, ChartWrapperOptions } from "react-google-charts"

import { colors } from "../../../utils/graphs"
import { GraphContainer, GraphContent, GraphTitle } from "../graph.styles"

import "./styles.css"
interface SankeyProps {
	data: string[][]
	title?: string
	tootip?: {
		from: string
		to: string
		value: string
	}
}

export function Sankey({ data, title, tootip }: SankeyProps) {
	function tooltipHtml(data: any) {
		const [from, to, value] = data
		return `
			<div>
				<div margin: 0"><b>${tootip?.from ?? "De"}:</b> ${from}</div>
				<div margin: 0"><b>${tootip?.to ?? "Para"}:</b> ${to}</div>
				<div  margin: 0"><b>${tootip?.value ?? "Valor"}:</b> ${value}</div>
			</div>
		`
	}

	const dataset = useMemo(() => {
		const raw = data.map((d: string[]) => [d[0], d[1], d[2], tooltipHtml(d)])
		raw.unshift([
			"From",
			"To",
			"Value",
			{ role: "tooltip", p: { html: true }, type: "string" },
		] as any)
		return raw
	}, [data, tootip])

	const options: ChartWrapperOptions["options"] = {
		tooltip: {
			isHtml: true,
			textStyle: {
				fontSize: 12,
			},
		},
		sankey: {
			link: {
				colors: ["#AAB4BE"],
				fillOpacity: 0.1,
				colorMode: "gradient",
			},
			node: {
				colors,
				width: 8,
				nodePadding: 30,
				label: {
					fontSize: 12,
					bold: true,
					color: "#333",
					fontName: "Arial, sans-serif",
				},
			},
		},
	}

	return (
		<GraphContainer>
			{title && <GraphTitle>{title}</GraphTitle>}
			<GraphContent>
				<Chart
					chartType="Sankey"
					width="100%"
					height={270}
					data={dataset}
					options={options}
					graphID="Sankey"
				/>
			</GraphContent>
		</GraphContainer>
	)
}
