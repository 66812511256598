import { SnackbarProvider, enqueueSnackbar } from "notistack"

type ResponseError = {
	status: number
	data: {
		messages: string[]
	}
}

const handleTypeError = ({ status, data }: ResponseError) => {
	switch (true) {
		case status >= 199 && status <= 299:
			data?.messages?.forEach((error) => enqueueSnackbar(error, { variant: "success" }))
			break
		case status >= 400 && status <= 499:
			data?.messages?.forEach((error) => enqueueSnackbar(error, { variant: "warning" }))
			break
		default:
			data?.messages?.forEach((error) => enqueueSnackbar(error, { variant: "error" }))
			break
	}
}

export const handleApiExceptions = ({ response }: any) => {
	if (!response) return "Erro Desconhecido"
	handleTypeError(response)
}
