import { useState } from "react"
import { useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"

import { zodResolver } from "@hookform/resolvers/zod"
import dayjs from "dayjs"

import {
	createOrganization,
	listSegments,
	listSpecificOrganization,
	updateOrganization,
} from "../../../apis/organization"
import {
	CreateOrganizationData,
	createOrganizationSchema,
} from "../../../libs/zod/organization/create-organization"
import { pathNames } from "../../../routes/paths"
import { createErrorMessage } from "../../../utils/format"

export const useOrganization = () => {
	const [segments, setSegments] = useState<any>([])
	const [loading, setLoading] = useState<boolean>(false)
	const [dialog, setDialog] = useState<boolean>(false)
	const [dialogTitle, setDialogTitle] = useState<any>("")
	const [dialogDescription, setDialogDescription] = useState<any>("")
	const [isError, setIsError] = useState<boolean>(false)
	const [error, setError] = useState<boolean>(false)
	const { t } = useTranslation()
	const navigate = useNavigate()

	const methods = useForm<CreateOrganizationData>({
		resolver: zodResolver(createOrganizationSchema),
	})

	const fetchSegments = async () => {
		try {
			const {
				data: { data },
			} = await listSegments()
			setSegments(data)
		} catch (error) {}
	}

	const fetchOrganization = async (id: string) => {
		try {
			const {
				data: { data },
			} = await listSpecificOrganization(id)
			data.contractExpiresAt = data.contractExpiresAt
				? dayjs(new Date(data.contractExpiresAt as unknown as string))
				: undefined

			data.services.forEach((service) => {
				if (!service.costRanges) return
				service.costRanges.forEach((costRange: any) => {
					if (costRange.usageEnd === null) {
						costRange.usageEnd = Infinity
					}
				})
			})

			methods.reset(data)
		} catch (error: any) {
			setDialogTitle(t("request_messages.500"))
			if (error?.response?.data?.messages) {
				setDialogDescription(createErrorMessage(error?.response?.data?.messages))
			} else {
				setDialogDescription(t("request_messages.400"))
			}
			setError(true)
		} finally {
			setLoading(false)
		}
	}

	const onUpdateOrganizationSubmit = async (data: CreateOrganizationData) => {
		setLoading(true)
		setIsError(false)

		data.services.forEach((service) => {
			if (service?.deletedCostRanges && service?.costRanges) {
				service.costRanges = [...service.costRanges, ...service.deletedCostRanges]
				delete service?.deletedCostRanges
			}
		})

		try {
			await updateOrganization(data.id, data)
			setDialogTitle(t("request_messages.edit_success_title", { name: "Organização" }))
			setDialogDescription(
				t("request_messages.edit_success_description", {
					name: "Organização",
				}),
			)
		} catch (error: any) {
			setIsError(true)
			setDialogTitle(t("request_messages.500"))
			if (error?.response?.data?.messages) {
				setDialogDescription(createErrorMessage(error?.response?.data?.messages))
			} else {
				setDialogDescription(t("request_messages.400"))
			}
		} finally {
			setDialog(true)
			setLoading(false)
		}
	}

	const onCreateOrganizationSubmit = async (data: CreateOrganizationData) => {
		setLoading(true)
		setIsError(false)

		try {
			await createOrganization(data)
			setDialogTitle(t("request_messages.create_success_title", { name: "Organização" }))
			setDialogDescription(t("request_messages.success_description", { name: "organização" }))
		} catch (error: any) {
			setIsError(true)
			setDialogTitle(t("request_messages.500"))
			if (error?.response?.data?.messages) {
				setDialogDescription(createErrorMessage(error?.response?.data?.messages))
			} else {
				setDialogDescription(t("request_messages.400"))
			}
		} finally {
			setDialog(true)
			setLoading(false)
		}
	}

	const handleSubmitionAccept = () => {
		setDialog(false)
		if (!isError) {
			navigate(pathNames.organization.list)
			methods.reset()
		}
	}

	return {
		methods,
		segments,
		loading,
		dialog,
		dialogTitle,
		dialogDescription,
		isError,
		error,
		handleSubmitionAccept,
		setSegments,
		fetchSegments,
		t,
		setLoading,
		setDialog,
		setDialogTitle,
		setDialogDescription,
		setIsError,
		setError,
		fetchOrganization,
		navigate,
		handleUpdateOrganizationSubmit: methods.handleSubmit(onUpdateOrganizationSubmit),
		handleCreateOrganizationSubmit: methods.handleSubmit(onCreateOrganizationSubmit),
	}
}
