import JSONPretty from "react-json-pretty"

import ContentCopyIcon from "@mui/icons-material/ContentCopy"
import { Chip, IconButton } from "@mui/material"
import { grey } from "@mui/material/colors"
import { light } from "@mui/material/styles/createPalette"

import { Code, ContainerActions } from "./styles"

const codeStyle = require("./code.css")

interface ResponseViewerCodeBlockProps {
	code: any
}

export const ResponseViewerCodeBlock = ({ code }: ResponseViewerCodeBlockProps) => {
	const copyCode = async () => {
		await navigator.clipboard.writeText(JSON.stringify(code))
	}

	return (
		<Code>
			<ContainerActions>
				<Chip
					label="JSON"
					style={{ background: light ? grey[100] : grey[800] }}
					variant={"filled"}
				/>
				<IconButton onClick={copyCode} style={{ color: grey[50] }}>
					<ContentCopyIcon />
				</IconButton>
			</ContainerActions>
			<JSONPretty theme={codeStyle} id="json-pretty" data={code}></JSONPretty>
		</Code>
	)
}
