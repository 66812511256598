/**
 * This function creates a blob and downloads it as a file
 * using the Blob API and the download attribute of the anchor tag,
 * clicking the anchor tag and removing it from the DOM after the download
 * (all of this is done in background with no visual impact).
 *
 * @param content - The content to be downloaded
 * @param fileName - The name of the file to be downloaded
 */
export const createBlobAndDownload = (content: BlobPart[], fileName: string) => {
	const href = window.URL.createObjectURL(new Blob(content))
	const link = document.createElement("a")
	link.href = href
	link.setAttribute("download", fileName)
	document.body.appendChild(link)
	link.click()
	document.body.removeChild(link)
}
