import { Box, Typography } from "@mui/material"
import styled from "styled-components"

export const Container = styled(Box)`
	margin: 15px 0;
	display: flex;
	flex-direction: column;
	gap: 15px;
`

export const Title = styled(Typography)`
	font-size: 16px !important;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
	gap: 5px;
	font-weight: 500 !important;
`

export const SubTitle = styled(Typography)`
	font-size: 14px !important;
`
export const ContainerWidget = styled(Box)`
	width: 100%;
	height: 100%;
	min-height: 60px;
	box-sizing: border-box;
	padding: 4px 12px;
	border-radius: 6px;
	border: solid 1px #ddd;
	border-left: solid 3px #187063;
	color: ${({ theme: { palette } }) => (palette.mode === "dark" ? "#fff" : "#000")};
`
