import { ReactElement } from "react"
import { Navigate, Outlet } from "react-router-dom"

import Header from "../components/header/header"
import { Menu } from "../components/menu/main/menu"
import { AuthStorage } from "../storage/auth-type"
import { getItem } from "../storage/local-storage"
import { Container } from "./routes.styles"

type PrivateRouteProps = {
	children?: ReactElement
	isAuthenticated: boolean
	showMenu?: boolean
}

const PrivateRoute = ({ children, isAuthenticated, showMenu = true }: PrivateRouteProps) => {
	const item = getItem(AuthStorage.userToken)

	return item || isAuthenticated ? (
		<>
			<Header isLogged={true} />
			<Container>
				{showMenu && <Menu />}
				{children ?? <Outlet />}
			</Container>
		</>
	) : (
		<Navigate to="/" />
	)
}

export default PrivateRoute
