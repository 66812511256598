import { Roles } from "../../models/Client"
import { Policies } from "../../models/Police"
import { rootPathByRole } from "../../routes/paths"
import { AuthStorage } from "../../storage/auth-type"
import { getItem } from "../../storage/local-storage"

export const useAuthentication = () => {
	const policies = getItem<Policies>(AuthStorage.userPolicy)
	const user = getItem(AuthStorage.userInformation)
	const token = getItem(AuthStorage.userToken)
	const apiKey = getItem(AuthStorage.userApiKey)

	const rootPath = rootPathByRole[user?.role as Roles]

	const isAdmin = user?.role === Roles.Admin
	const isUser = user?.role === Roles.User
	const isClient = user?.role === Roles.Client

	return {
		policies,
		user,
		token,
		isAuthenticated: !!token,
		apiKey,
		rootPath,
		isAdmin,
		isUser,
		isClient,
	}
}
