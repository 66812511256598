import { useCallback, useMemo, useState } from "react"
import { Doughnut as DoughnutChartJS } from "react-chartjs-2"

import { GraphDatasetProps } from "../../../@types/graphs/dataset"
import { mountGraphOptions } from "../../../utils/graphs"
import {
	ContainerLegend,
	GraphContainer,
	GraphContent,
	GraphTitle,
	LegendContent,
	LegendDescription,
	LegendName,
} from "../graph.styles"

interface DoughnutProps {
	data: GraphDatasetProps
	title?: string
}

export function Doughnut({ data, title }: DoughnutProps) {
	const [filterLegend, setFilterLegend] = useState<string[]>([])

	function handleLegendFilter(label: string) {
		setFilterLegend((prev) => {
			if (prev.includes(label)) return prev.filter((f) => f !== label)
			return [...prev, label]
		})
	}
	const graphStyleOptions = useMemo(
		() => ({
			...mountGraphOptions(),
			cutout: "70%",
		}),
		[],
	)

	const sumDataset = useMemo(
		() => data?.datasets?.[0].data?.reduce((acc, crr) => acc + crr, 0),
		[data],
	)

	const handleLegendColor = useCallback(
		(crrIndex: number) => data?.datasets?.[0].backgroundColor?.[crrIndex % 4],
		[data],
	)

	function handleLegendDescriptionText(crrIndex: number) {
		const text = data?.datasets?.[0].data?.[crrIndex]
		if (!text) return ""
		const regex = /(\d{1,3})(?=(\d{3})+(?!\d))/g
		return `${text.toString().replace(regex, "$1 ")} (${((text / sumDataset) * 100).toFixed(1)}%)`
	}

	const dataset = useMemo(() => {
		if (!filterLegend.length) return data
		return {
			labels: filterLegend.map((f) => data?.labels[data.labels.indexOf(f)]),
			datasets: [
				{
					...data?.datasets?.[0],
					data: filterLegend.map((f) => data?.datasets?.[0].data[data.labels.indexOf(f)]),
				},
			],
		}
	}, [data, filterLegend])

	return (
		<GraphContainer>
			{title && <GraphTitle>{title}</GraphTitle>}
			<GraphContent>
				<DoughnutChartJS data={dataset} options={graphStyleOptions} />
			</GraphContent>
			<ContainerLegend>
				{data.labels.map((label, index) => (
					<LegendContent
						isInactive={Boolean(filterLegend.length) && !filterLegend.includes(label)}
						onClick={() => handleLegendFilter(label)}
						color={handleLegendColor(index)}
						key={label}
					>
						<LegendName>{label}</LegendName>
						<LegendDescription>{handleLegendDescriptionText(index)}</LegendDescription>
					</LegendContent>
				))}
			</ContainerLegend>
		</GraphContainer>
	)
}
