import { initReactI18next } from "react-i18next"

import i18next from "i18next"

import es_translation from "./es/translations.json"
import pt_translation from "./pt/translations.json"

export const resources = {
	pt: {
		translation: pt_translation,
	},
	es: {
		translation: es_translation,
	},
}

i18next.use(initReactI18next).init({
	lng: "pt",
	resources,
})
