import { Dispatch, SetStateAction } from "react"
import { Trans, useTranslation } from "react-i18next"

import { Typography } from "@mui/material"

import { InvoicingData, approveStatusInvoice } from "../../../../../apis/invoicing"
import DialogAlert from "../../../../../components/dialog/dialog"
import { AuthStorage } from "../../../../../storage/auth-type"
import { getItem } from "../../../../../storage/local-storage"
import { handleApiExceptions } from "../../../../../utils/handle-errors"
import { Container } from "./dialog-approve.styles"

interface DialogApproveProps {
	dialogDispatch: [boolean, Dispatch<SetStateAction<boolean>>]
	invoicing: InvoicingData | null
	onSucces: () => void
}

export function DialogApprove({
	dialogDispatch: [isOpen, setIsOpen],
	invoicing,
	onSucces,
}: DialogApproveProps) {
	const { t } = useTranslation()

	const user = getItem(AuthStorage.userInformation)

	const declineDialog = () => setIsOpen(false)

	const role = invoicing?.operationalApproverId
		? t("consumption.invoicing.headerActions.financial")
		: t("consumption.invoicing.headerActions.operational")
	async function tryApproveStatusInvoice() {
		try {
			if (invoicing?.id) await approveStatusInvoice(invoicing.id)
			setIsOpen(false)
			onSucces()
		} catch (error) {
			handleApiExceptions(error)
		}
	}

	return (
		<DialogAlert
			accept={tryApproveStatusInvoice}
			title={t("consumption.invoicing.headerActions.dialog.title")}
			decline={declineDialog}
			description=""
			show={isOpen}
		>
			<Container width={1}>
				<Typography>
					<Trans
						i18nKey="consumption.invoicing.headerActions.dialog.confirm"
						values={{ role, user: user.userName }}
					/>
				</Typography>
			</Container>
		</DialogAlert>
	)
}
