import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Paper,
	Table,
	TableBody,
	TableContainer,
	TableHead,
	TableRow,
} from "@mui/material"
import { t } from "i18next"

import { StyledTableCell, StyledTableRow } from "./dialog-historic.styles"

type DialogScrollProps = {
	open: boolean
	handleClose: () => void
	headers: string[]
	rows: any[][]
}

export const GenericDialogHistory = ({ open, handleClose, headers, rows }: DialogScrollProps) => {
	return (
		<Dialog
			open={open}
			onClose={handleClose}
			scroll={"paper"}
			aria-labelledby="scroll-dialog-title"
			aria-describedby="scroll-dialog-description"
			maxWidth={"lg"}
			fullWidth={true}
		>
			<DialogTitle id="scroll-dialog-title">{t("product_select.historic_title")}</DialogTitle>
			<DialogContent dividers={true}>
				<TableContainer component={Paper}>
					<Table aria-label="customized table">
						<TableHead>
							<TableRow>
								{headers.map((header, index) => (
									<StyledTableCell key={index}>{header}</StyledTableCell>
								))}
							</TableRow>
						</TableHead>
						<TableBody>
							{rows.map((row, index) => (
								<StyledTableRow key={index}>
									{row.map((cell, index) => (
										<StyledTableCell key={index}>{cell}</StyledTableCell>
									))}
								</StyledTableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			</DialogContent>
			<DialogActions>
				<Button color={"secondary"} onClick={handleClose}>
					{t("actions.close")}
				</Button>
			</DialogActions>
		</Dialog>
	)
}
