import { z } from "zod"

export const serviceCostRangeSchema = z
	.object({
		id: z.string().optional(),
		serviceLimitId: z.string().optional(),
		deleted: z.boolean().optional(),
		usageStart: z.number().min(0),
		usageEnd: z.number().min(0),
		cost: z
			.string()
			.min(0)
			.transform((value) => {
				return parseFloat(value.replace(",", "."))
			}),
	})
	.superRefine((data, ctx) => {
		if (data.usageStart > data.usageEnd) {
			ctx.addIssue({
				code: "custom",
				message: "usageStart cannot be greater than usageEnd",
				path: ["usageEnd"],
			})
		}
	})

export const serviceSchema = z.object({
	serviceId: z.string().optional(),
	serviceLimitId: z.string().optional(),
	name: z.string().min(3).max(255),
	unlimited: z.boolean(),
	usageLimit: z.number(),
	deletedCostRanges: z.array(z.any()).optional(),
	deleted: z.boolean().optional(),
})

export const successBillingOption = z.object({
	id: z.string(),
	title: z.string(),
})

export const serviceBillingParamsSchema = z.object({
	billingType: z.enum(["success", "request"]),
	costRangeModel: z.enum(["fixed", "request"]),
	successBillingOptions: z.array(successBillingOption).optional().nullable(),
	costRanges: z.array(serviceCostRangeSchema).superRefine((costRanges: any[], ctx) => {
		costRanges.forEach((costRange: any, index: number) => {
			const lastCostRange = costRanges[index - 1]
			if (lastCostRange && lastCostRange.usageStart >= costRange.usageEnd) {
				ctx.addIssue({
					code: "custom",
					message: "aaasdasdas",
					path: [index, "usageStart"],
				} as any)
			}
		})
	}),
})

export type ServiceData = z.infer<typeof serviceSchema>
export type ServiceCostRange = z.infer<typeof serviceCostRangeSchema>
export type SuccessBillingOption = z.infer<typeof successBillingOption>
