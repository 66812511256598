import { ElementType } from "react"

import { Container } from "./styles"

interface ResponseViewerCloseIconProps {
	icon: ElementType
	onClose: () => void
}

export const ResponseViewerCloseIcon = ({ icon: Icon, onClose }: ResponseViewerCloseIconProps) => {
	return (
		<Container>
			<Icon onClick={onClose} color={"primary"} fontSize={"large"} />
		</Container>
	)
}
