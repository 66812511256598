import { AxiosError } from "axios"
import { enqueueSnackbar } from "notistack"

import { ErrorStatus } from "../models/Error"
import { pathNames } from "../routes/paths"
import { clearAllItems } from "../storage/local-storage"
import { removeChatbot } from "../utils/helper"

const handleError = (error: AxiosError) => {
	const { code, response } = error

	if (response?.status === ErrorStatus.UNAUTHORIZED) {
		clearAllItems()
		window.location.href = pathNames.home
		enqueueSnackbar("Sessão expirada, faça login novamente", { variant: "error" })
	}

	if (response?.status === ErrorStatus.FORBIDDEN) {
		enqueueSnackbar("Você não tem permissão para acessar essa página", { variant: "error" })
	}

	if (code === ErrorStatus.ERR_NETWORK) {
		removeChatbot()
		clearAllItems()
		window.location.href = pathNames.home
	} else {
		throw error
	}
}

export default handleError
