import styled from "styled-components"

import { maxDeviceSizeForMediaQueries } from "../../../../../styles/devices-medias"

export const Container = styled.div`
	width: 50%;
	padding: 32px;
	height: 100%;
	box-sizing: border-box;
	@media ${maxDeviceSizeForMediaQueries.tablet} {
		width: 100%;
		padding: 16px;
	}
`
