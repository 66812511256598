import { useTranslation } from "react-i18next"

import { Typography } from "@mui/material"

import { Container } from "./not-found.styles"

export const NotFound = () => {
	const { t } = useTranslation()
	return (
		<Container>
			<Typography color={"#626262"} variant={"button"}>
				{t("table.no_results")}
			</Typography>
		</Container>
	)
}
