import { Grid } from "@mui/material"

interface APIExecuterFormBodyProps {
	children: React.ReactNode
}

export const APIExecuterFormBody = ({ children }: APIExecuterFormBodyProps) => {
	return (
		<div>
			<Grid container rowSpacing={1} columnSpacing={{ xs: 1 }}>
				{children}
			</Grid>
		</div>
	)
}
