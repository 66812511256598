import { z } from "zod"

import { serviceSchema } from "./shared"

export const leadSchema = z.object({
	id: z.string().uuid().optional(),
	name: z.string().min(3, { message: "Nome inválido" }),
	email: z.union([z.string().email(), z.literal("")]).optional(),
	phone: z
		.string()
		.refine(
			(value) => {
				const phone = value.replace(/\D/g, "")
				return phone.length === 11 || phone.length === 10
			},
			{ message: "Número inválido" },
		)
		.optional(),
	company: z.string().max(255).optional(),
	role: z.string().max(255).optional(),
	utm: z.string().max(255).optional(),
	observation: z.string().max(255).optional(),
})

export const apiKeySchema = z.object({
	id: z.string().uuid().optional(),
	key: z.string().optional(),
	description: z.string().min(3, { message: "Descrição muito curta" }).max(255),
	trial: z.boolean(),
	active: z.boolean().optional(),
	lead: leadSchema.optional(),
	services: z.array(serviceSchema),
})

export type ApiKeyData = z.infer<typeof apiKeySchema>
