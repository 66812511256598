export type Client = {
	id: string
	name: string
	email: string
	phone: string
	role: string
	teamId: string
	active: boolean
	organizationId: string
	team: string
	organization: string
	apiKey: string
}

export enum Roles {
	Admin = "admin",
	Client = "client",
	User = "user",
}
