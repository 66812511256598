import { useCallback, useContext, useState } from "react"
import { DropzoneOptions, useDropzone } from "react-dropzone"
import { useTranslation } from "react-i18next"

import ArticleIcon from "@mui/icons-material/Article"
import UploadFileIcon from "@mui/icons-material/CloudUpload"

import { GlobalContext } from "../../contexts/global-provider"
import { DropzoneContainer, DropzoneText } from "./dropzone.styles"

type DropzoneProps = DropzoneOptions & {
	onDrop?: (files: File[]) => void
	styles?: any
	extensions?: string[]
}

export function Dropzone(props: DropzoneProps) {
	const [files, setFiles] = useState<File[]>([])

	const onDrop = useCallback((acceptedFiles: File[]) => {
		setFiles(acceptedFiles)
		if (props.onDrop) props.onDrop(acceptedFiles)
	}, [])

	const serializeExtension = (extensions: string[]) => {
		const accept: { [key: string]: string[] } = {}
		extensions.forEach((extension: string) => {
			accept[extension] = []
		})
		return accept
	}

	const { light } = useContext(GlobalContext)

	const { t } = useTranslation()

	const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
		onDrop,
		accept: serializeExtension(props.extensions || []),
		maxFiles: props.maxFiles || 1,
		onDropRejected: props.onDropRejected,
		disabled: props.disabled || false,
	})

	function convertBytesToMegaBytesOrKiloBytes(bytes: number) {
		const sizes = ["Bytes", "KB", "MB", "GB", "TB"]

		if (bytes === 0) {
			return "0 Byte"
		}

		const i = Math.floor(Math.log(bytes) / Math.log(1024))

		return `${Math.round(bytes / Math.pow(1024, i))} ${sizes[i]}`
	}

	const FilesList: any = files.map((file: any) => (
		<div key={file.path}>
			<h3 style={{ color: light ? "#309587" : "#fff" }}>
				{file.path} ({convertBytesToMegaBytesOrKiloBytes(file.size)})
			</h3>
		</div>
	))

	return (
		<DropzoneContainer
			color="secondary"
			{...getRootProps({ className: "dropzone" })}
			style={props.styles}
			className={props.disabled ? "disabled" : ""}
		>
			<input {...getInputProps()} disabled={props.disabled} />

			{files.length === 0 && (
				<aside>
					<UploadFileIcon
						className="icon"
						style={{
							fontSize: "50px",
						}}
					/>
				</aside>
			)}

			{files.length === 0 && <DropzoneText>{t("dropzone.text")}</DropzoneText>}

			{files.length > 0 && (
				<aside>
					<ArticleIcon
						className="icon"
						style={{
							fontSize: "50px",
							color: light ? "#309587" : "#fff",
						}}
					/>
				</aside>
			)}
			{FilesList}
		</DropzoneContainer>
	)
}
