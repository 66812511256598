import { grey } from "@mui/material/colors"
import styled from "styled-components"

export const Wrapper = styled.div`
	color: ${grey["600"]};
	display: flex;
	justify-content: start;
	align-items: center;
`

export const Content = styled.i`
	font-size: 0.8rem;
	margin-right: 1rem;
	margin-left: 0.2rem;
	color: ${grey["600"]};
	margin-left: 0.2rem;
`
