import { Controller } from "react-hook-form"
import InputMask from "react-input-mask"

import { ReactJSXElement } from "@emotion/react/types/jsx-namespace"
import { IconButton, InputAdornment, TextField } from "@mui/material"

type ControlledTextFieldProps = {
	label: string
	control: any
	placeholder?: string
	required?: boolean
	type?: string
	disabled?: boolean
	id: string
	style?: any
	validChars?: any | RegExp
	mask?: string
	maskPlaceholder?: string | null
	rules?: Rules
	readOnly?: boolean
	endIcon?: ReactJSXElement
	handleClickEndIcon?: () => void
	errors?: any
	helperText?: string | null
	beforeChange?: (value: string) => any
	alwaysShowMask?: boolean
}

type Rules = {
	required?: boolean
	minLength?: number
	maxLength?: number
}

export const ControlledTextField = ({
	id,
	label,
	control,
	placeholder = "",
	type,
	disabled = false,
	style,
	validChars,
	mask = "",
	maskPlaceholder = "_",
	rules,
	readOnly = false,
	endIcon,
	errors,
	helperText,
	beforeChange,
	handleClickEndIcon,
	alwaysShowMask,
}: ControlledTextFieldProps) => {
	const fieldLabel = rules?.required ? label + "*" : label

	return (
		<Controller
			control={control}
			defaultValue=""
			name={id}
			rules={rules}
			render={({ field: { name, value, onChange, ref } }: any) => (
				<InputMask
					disabled={disabled}
					alwaysShowMask={alwaysShowMask}
					value={value === Infinity ? "∞" : value}
					mask={mask}
					maskPlaceholder={maskPlaceholder}
					onChange={(event: any) =>
						beforeChange ? onChange(beforeChange(event.target.value)) : onChange(event)
					}
				>
					<TextField
						style={style}
						type={value === Infinity ? "text" : type}
						ref={ref}
						name={name}
						color="secondary"
						size="small"
						id="filled-basic"
						label={fieldLabel}
						placeholder={placeholder}
						variant="filled"
						helperText={helperText}
						error={(!validateChars(validChars, value) && value !== "") || errors ? true : false}
						InputProps={{
							readOnly,
							endAdornment: endIcon && (
								<InputAdornment position="end">
									<IconButton
										aria-label="toggle password visibility"
										onClick={handleClickEndIcon}
										edge="end"
									>
										{endIcon}
									</IconButton>
								</InputAdornment>
							),
						}}
						// @ts-ignore
						// helperText={value === '' && required ? t('common.required_field') : undefined}
					/>
				</InputMask>
			)}
		/>
	)
}

const validateChars = (validChars: RegExp, value: any) => {
	if (validChars) {
		return String(value).toLowerCase().match(validChars)
	}
	return true
}
