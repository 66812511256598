import axios from "./config/axios"

export const listAllIpsApi = async ({ currentPage, pageSize, filter, active }: any) =>
	await axios.post("/organizationIpRanges/search", { currentPage, pageSize, filter, active })

export const createIp = async (ip: any) => await axios.post("/organizationIpRanges", ip)

export const searchIp = async (id: any) => await axios.get(`/organizationIpRanges/${id}`)

export const updateIp = async (id: any, ipObject: any) =>
	await axios.put(`/organizationIpRanges/${id}`, ipObject)

export const deleteIp = async (id: any) => await axios.delete(`/organizationIpRanges/${id}`)
