import { useMemo, useState } from "react"
import { Line as LineChartJS } from "react-chartjs-2"

import { handleDatasetColor, mountLineGraphOptions } from "../../../utils/graphs"
import {
	ContainerLegend,
	GraphContainer,
	GraphContent,
	GraphTitle,
	LegendContent,
	LegendDescription,
	LegendName,
} from "../graph.styles"

export type GraphLineProps = {
	label: string
	data: string[][]
}

interface LineProps {
	maintainAspectRatio?: boolean
	data: GraphLineProps[]
	title?: string
	legend?: string[][]
}

export function Line({ data, title, maintainAspectRatio = false, legend }: LineProps) {
	const [filterLegend, setFilterLegend] = useState<string[]>([])

	const labels = useMemo(() => data?.[0]?.data?.map(([key]) => key), [data])

	function handleLegendFilter(label: string) {
		setFilterLegend((prev) => {
			if (prev.includes(label)) return prev.filter((f) => f !== label)
			return [...prev, label]
		})
	}

	const mountDataset = (data: GraphLineProps[]) =>
		data.map((dataset, index) => {
			const color = handleDatasetColor(index)
			return {
				data: dataset.data.map(([_, value]) => value),
				backgroundColor: color,
				label: dataset.label,
				borderColor: color,
				borderWidth: 1.5,
				pointRadius: 2.5,
			}
		})

	const datasets = useMemo(() => {
		if (!filterLegend.length) return mountDataset(data)
		return mountDataset(data.filter((f) => filterLegend.includes(f.label)))
	}, [data, filterLegend])

	const options = mountLineGraphOptions()
	options.plugins = {
		...options.plugins,
		datalabels: {
			align: "top",
			color: "#333",
		},
	} as any

	if (!data.length) return <></>
	return (
		<GraphContainer gap="0px">
			{title && <GraphTitle align="left">{title}</GraphTitle>}
			<ContainerLegend justifyContent="flex-start">
				{legend?.map(([label, value], index) => (
					<LegendContent
						isInactive={Boolean(filterLegend.length) && !filterLegend.includes(label)}
						onClick={() => handleLegendFilter(label)}
						color={handleDatasetColor(index)}
						key={label}
					>
						<LegendName>{label}</LegendName>
						<LegendDescription>{value ?? ""}</LegendDescription>
					</LegendContent>
				))}
			</ContainerLegend>
			<GraphContent>
				<LineChartJS
					options={{
						...options,
						maintainAspectRatio,
					}}
					data={{
						datasets,
						labels,
					}}
				/>
			</GraphContent>
		</GraphContainer>
	)
}
