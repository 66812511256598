import { useContext } from "react"

import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import { Typography } from "@mui/material"

import { GlobalContext } from "../../contexts/global-provider"
import { AccordingSummaryDS } from "./styles"

export interface AccordionSummaryProps {
	id: string
	error?: boolean
	title: string
	icon: React.ReactNode
}

export const AccordionSummary = ({ error, title, icon, id }: AccordionSummaryProps) => {
	const { light } = useContext(GlobalContext)
	return (
		<AccordingSummaryDS
			id={`${id}-header`}
			aria-controls={`${id}-content`}
			expandIcon={<ExpandMoreIcon color={"secondary"} />}
			style={{
				backgroundColor: light ? "#f5f5f5" : "#424242",
				border: error ? "1px solid #da1e28" : "1px solid #e0e0e0",
			}}
		>
			{icon}
			<Typography fontSize={16} letterSpacing={1} variant={"subtitle1"} color={"secondary"}>
				{title}
			</Typography>
		</AccordingSummaryDS>
	)
}
