import { useTranslation } from "react-i18next"

import {
	Container,
	Information,
	Title,
	Description,
	Button,
	ContainerImage,
	SvgJulia,
} from "./banner.styles"

export const Banner = () => {
	const { t } = useTranslation()

	return (
		<Container>
			<Information>
				<Title>{t("home.title")}</Title>
				<Description>{t("home.description")}</Description>
				<Button>{t("home.explore_plans")}</Button>
			</Information>
			<ContainerImage>
				<SvgJulia />
			</ContainerImage>
		</Container>
	)
}
