export const setItem = (key: string, item: object) => {
	localStorage.setItem(key, JSON.stringify(item))
}

export const getItem = <T = any>(key: string): T => {
	const item = localStorage.getItem(key)

	if (item) return JSON.parse(item)
	else return null as T
}

export const clearItem = (key: string) => {
	localStorage.removeItem(key)
}

export const clearAllItems = () => {
	localStorage.clear()
}
