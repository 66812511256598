import { ComponentProps } from "react"

import { Box } from "@mui/material"
import { DataGrid as DataGridMUI, GridColDef } from "@mui/x-data-grid"
import { t } from "i18next"

type DataGridProps = {
	columns: GridColDef[]
	rows: any[]
	keys: any[]
	onDelete: (team: any) => void
	onEdit: (team: any) => void
	isDeleteVisible?: boolean
	isEditVisible?: boolean
	isReadVisible?: boolean
	page: number
	changePage: (page: number) => void
	changeRowsPerPage: (value: number) => void
	rowsPerPage: number
	onClickLink?: (id: string, value: string) => void
	isSelectable?: boolean
	onDisableAll?: (selected: Array<string>) => void
	pageCount?: number
	errors?: any[]
	paginationModel: {
		pageSize: number
		page: number
	}
	isLoading: boolean
	setPaginationModel: any
	className?: string
	localeText?: object
	idKey?: string | number
}

const styles = {
	tablePagination: {
		alignItems: "center",
		display: "flex",
		justifyContent: "flex-start",
		height: "10%",
	},
	tableCell: { fontWeight: "bold", color: "#484848" },
	tableContainer: { maxHeight: "90%", minHeight: "90%" },
}

export const DataGrid = ({
	rows = [],
	columns = [],
	paginationModel,
	setPaginationModel,
	isLoading,
	className,
	localeText,
	idKey,
	sx,
}: DataGridProps & Partial<typeof DataGridMUI> & Partial<ComponentProps<typeof Box>>) => {
	return (
		<Box sx={sx}>
			<DataGridMUI
				className={className}
				sx={{ width: "100%", tableLayout: "auto" }}
				rows={rows}
				paginationMode="server"
				columns={columns}
				pageSizeOptions={[10, 25, 100]}
				rowCount={rows?.length * 10}
				paginationModel={paginationModel}
				loading={isLoading}
				getRowId={idKey ? (row) => row[idKey] : undefined}
				onPaginationModelChange={(pagination) => {
					setPaginationModel((prevState: any) => ({ ...prevState, ...pagination }))
				}}
				localeText={{
					...localeText,
					footerRowSelected: (count) => {
						return `${count} ite${count > 1 ? "ns" : "m"} selecionado${count > 1 ? "s" : ""}`
					},
				}}
				hideFooterPagination={isLoading}
				slotProps={{
					pagination: {
						labelRowsPerPage: t("table.rows_per_page"),
						labelDisplayedRows: ({ from, to, count }) => {
							return `${from}-${to} de ${count !== -1 ? count : `${t("table.more_than")} ${to}`}`
						},
					},
				}}
			/>
		</Box>
	)
}
