import { useContext } from "react"

import { ThemeProvider as ThemeProviderMUI } from "@mui/system"
import { ThemeProvider as ThemeProviderStyled } from "styled-components"

import { GlobalContext } from "./contexts/global-provider"
import Routes from "./routes/routes"
import GlobalStyle from "./styles/global"
import { darkTheme, primaryTheme } from "./styles/theme"

function Wrap() {
	const { light } = useContext(GlobalContext)

	return (
		<ThemeProviderMUI theme={light ? primaryTheme : darkTheme}>
			<ThemeProviderStyled theme={light ? primaryTheme : darkTheme}>
				<GlobalStyle />
				<Routes />
			</ThemeProviderStyled>
		</ThemeProviderMUI>
	)
}

export default Wrap
