import axios from "./config/axios"

export const listAllTeams = async ({ currentPage, pageSize, filter, active = true }: any) =>
	await axios.post("/teams/search", { currentPage, pageSize, filter, active })

export const createTeam = async (team: any) => await axios.post("/teams", team)

export const searchSpecificTeam = async (teamId: any) => await axios.get(`/teams/${teamId}`)

export const updateTeam = async (team: any) => await axios.put(`/teams/${team?.id}`, team)

export const deleteTeam = async (teamId: string) => await axios.delete(`/teams/${teamId}`)

export const searchTeamByOrganization = async (organizationId: string) =>
	await axios.get(`/organizations/${organizationId}/teams`)

export const disableTeams = async (teams: Array<string>) =>
	await axios.put(`/teams/disable`, { teamIds: teams })

export const getSelfTeam = async () => await axios.get(`/teams/self`)
