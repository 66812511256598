import { ExpandLess, ExpandMore } from "@mui/icons-material"
import { Collapse, List, ListItemButton, ListItemIcon, ListItemText } from "@mui/material"

interface MenuItemProps {
	icon?: React.ReactNode
	label: string
	onClick: () => void
	expanded?: boolean
	children?: React.ReactNode
	allowedRoles?: string[]
	role?: string
	selected?: boolean
	show?: boolean
}

export const MenuItem = ({
	role,
	allowedRoles,
	children,
	expanded,
	onClick,
	icon,
	selected,
	label,
	show,
}: MenuItemProps) => {
	const isVisible = () => {
		return allowedRoles && role ? allowedRoles.includes(role) : show
	}

	return isVisible() ? (
		<>
			<ListItemButton onClick={onClick} selected={selected}>
				{icon && <ListItemIcon>{icon}</ListItemIcon>}
				<ListItemText secondary={label} />
				{children ? expanded ? <ExpandLess color={"action"} /> : <ExpandMore color="action" /> : ""}
			</ListItemButton>
			{children && (
				<Collapse in={expanded} timeout="auto" unmountOnExit>
					<List component="div" disablePadding sx={{ pl: 2 }}>
						{children}
					</List>
				</Collapse>
			)}
		</>
	) : (
		<> </>
	)
}
