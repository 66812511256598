import { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"

import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Paper,
	Table,
	TableBody,
	TableContainer,
	TableHead,
	TableRow,
} from "@mui/material"

import { listHistoricLimit } from "../../apis/audit-log"
import { StyledTableCell, StyledTableRow } from "./dialog-historic.styles"

type DialogScrollProps = {
	open: boolean
	handleClose: () => void
	id: string | undefined
}

type Historic = {
	userName: string
	date: string
	usageLimit: string
	serviceName: string
	unlimited: boolean
}

export const DialogHistoric = ({ open, handleClose, id }: DialogScrollProps) => {
	const descriptionElementRef = useRef(null)

	const [rows, setRow] = useState<[]>([])

	const { t } = useTranslation()

	useEffect(() => {
		if (open) {
			const { current: descriptionElement } = descriptionElementRef
			if (descriptionElement !== null) {
				/* @ts-ignore */
				descriptionElement?.focus()
			}
		}
	}, [open])

	useEffect(() => {
		fetchData()
		// eslint-disable-next-line
	}, [])

	const fetchData = async () => {
		try {
			const {
				data: { data },
			} = await listHistoricLimit(id)
			setRow(data)
		} catch (error) {}
	}

	return (
		<Dialog
			open={open}
			onClose={handleClose}
			scroll={"paper"}
			aria-labelledby="scroll-dialog-title"
			aria-describedby="scroll-dialog-description"
			maxWidth={"md"}
			fullWidth={true}
		>
			<DialogTitle id="scroll-dialog-title">{t("product_select.historic_title")}</DialogTitle>
			<DialogContent dividers={true}>
				<TableContainer component={Paper}>
					<Table aria-label="customized table">
						<TableHead>
							<TableRow>
								<StyledTableCell color="primary">{t("columns.date")}</StyledTableCell>
								<StyledTableCell>{t("columns.user")}</StyledTableCell>
								<StyledTableCell>{t("columns.service")}</StyledTableCell>
								<StyledTableCell align="right">{t("columns.new_limit")}</StyledTableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{rows.map((row: Historic) => (
								<StyledTableRow key={row.userName}>
									<StyledTableCell>
										{new Date(row.date).toLocaleDateString("pt-br", {
											hour: "numeric",
											minute: "numeric",
											second: "numeric",
										})}
									</StyledTableCell>
									<StyledTableCell>{row.userName}</StyledTableCell>
									<StyledTableCell>{row.serviceName}</StyledTableCell>
									<StyledTableCell align="right">
										{row.unlimited ? <b>ILIMITADO</b> : row.usageLimit}
									</StyledTableCell>
								</StyledTableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			</DialogContent>
			<DialogActions>
				<Button color={"secondary"} onClick={handleClose}>
					{t("actions.close")}
				</Button>
			</DialogActions>
		</Dialog>
	)
}
