import { CircularProgress } from "@mui/material"

import { Container, ContainerTable } from "./loading.styles"

export const Loading = () => (
	<Container>
		<CircularProgress />
	</Container>
)

export const LoadingTable = () => (
	<ContainerTable>
		<CircularProgress />
	</ContainerTable>
)
