import React from "react"

import Box from "@mui/material/Box"
import Divider from "@mui/material/Divider"
import Drawer from "@mui/material/Drawer"
// import AppsIcon from '@mui/icons-material/Apps';

import { MenuList } from "./components/menu-list"

type Anchor = "top" | "left" | "bottom" | "right"

type DrawerProps = {
	openDrawer: boolean
	callbackDrawer: (show: boolean) => void
}

export default function TemporaryDrawer({ openDrawer = false, callbackDrawer }: DrawerProps) {
	const toggleDrawer =
		(anchor: Anchor, open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
			if (
				event.type === "keydown" &&
				((event as React.KeyboardEvent).key === "Tab" ||
					(event as React.KeyboardEvent).key === "Shift")
			) {
				return
			}

			callbackDrawer(!openDrawer)
		}

	const list = (anchor: Anchor) => (
		<Box sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }} role="presentation">
			<Divider />
			<MenuList onClick={() => callbackDrawer(false)} />
		</Box>
	)

	return (
		<Drawer anchor={"left"} open={openDrawer} onClose={toggleDrawer("left", false)}>
			{list("left")}
		</Drawer>
	)
}
