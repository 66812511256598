import HeaderHome from "./home/header-home"
import HeaderLogged from "./logged/header-logged"

type HeaderProps = {
	isLogged: boolean
}

const Header = ({ isLogged }: HeaderProps) => {
	if (isLogged) return <HeaderLogged />
	else return <HeaderHome />
}

export default Header
