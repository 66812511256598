import {
	Checkbox,
	FormControl,
	InputLabel,
	ListItemText,
	MenuItem,
	OutlinedInput,
	Select,
} from "@mui/material"

type MultiSelectionFieldProps = {
	id: string
	label: string
	options: string[]
	optionsSelected: string[]
	onChange: (value: []) => void
	checkedValidation: (item: any, index: number) => boolean
}

export function MultiSelectionField({
	id,
	label,
	options,
	optionsSelected,
	onChange,
	checkedValidation,
}: MultiSelectionFieldProps) {
	return (
		<FormControl sx={{ width: "100%" }}>
			<InputLabel id={id} color="secondary">
				{label}
			</InputLabel>
			<Select
				labelId={id}
				id={id}
				multiple
				value={optionsSelected}
				onChange={(event) => onChange(event.target.value as [])}
				input={<OutlinedInput label={label} color="secondary" />}
				renderValue={(selected) => selected.join(", ")}
				color="secondary"
			>
				{options?.map((option, index) => (
					<MenuItem key={index} value={option}>
						<Checkbox checked={checkedValidation(option, index)} />
						<ListItemText primary={option} />
					</MenuItem>
				))}
			</Select>
		</FormControl>
	)
}
