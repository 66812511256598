import { useEffect } from "react"
import { FormProvider } from "react-hook-form"
import { useParams } from "react-router-dom"

import { Alert, AlertTitle } from "@mui/material"

import { BodyPage, FormContainerPage } from "../../components/container/container.styles"
import DialogAlert from "../../components/dialog/dialog"
import { HeaderPage } from "../../components/header/page/header-page"
import { Loading } from "../../components/loading/loading"
import { useOrganization } from "../../hooks/app/organization/use-organization"
import { useQuery } from "../../hooks/app/useQuery"
import { pathNames } from "../../routes/paths"
import { BillingDataSection } from "./components/billing-data-section"
import { OrganizationDataSection } from "./components/organization-data-section"
import { ServicesSection } from "./components/services-section"

export const EditOrganization = () => {
	const { organizationId } = useParams()
	const backPath = useQuery("backPath")

	const {
		methods,
		segments,
		dialog,
		dialogTitle,
		dialogDescription,
		loading,
		fetchSegments,
		fetchOrganization,
		t,
		navigate,
		handleUpdateOrganizationSubmit,
		handleSubmitionAccept,
	} = useOrganization()

	useEffect(() => {
		fetchSegments()
		fetchOrganization(organizationId as string)
	}, [])

	if (loading) return <Loading />

	return (
		<FormContainerPage onSubmit={handleUpdateOrganizationSubmit}>
			<HeaderPage
				onClickTopButton={() => navigate(backPath ?? pathNames.organization.list)}
				description={t("organizations.edit_organization_description")}
				isEdit={true}
				title={t("organizations.edit_organization_title")}
			/>
			{!methods.formState.isValid && methods.formState.isSubmitted && (
				<Alert severity="error" style={{ marginBottom: 16 }}>
					<AlertTitle>Campo(s) invalido</AlertTitle>
					Corrija os campos inválidos antes de finalizar.
				</Alert>
			)}
			<BodyPage>
				<FormProvider {...methods}>
					<OrganizationDataSection segments={segments} />
					<BillingDataSection />
					<ServicesSection isHistoricVisible organizationId={organizationId} />
				</FormProvider>
			</BodyPage>
			<DialogAlert
				onlyConfirm={true}
				show={dialog}
				accept={handleSubmitionAccept}
				title={dialogTitle}
				description={dialogDescription}
			/>
		</FormContainerPage>
	)
}
