import { ReactNode } from "react"

import { TableCell, TableRow } from "@mui/material"

interface ResponseViewerTableRowProps {
	label: ReactNode
	value: ReactNode
}

export const ResponseViewerTableRow = ({ label, value }: ResponseViewerTableRowProps) => {
	return (
		<TableRow sx={{ width: "100%" }}>
			<TableCell sx={{ fontWeight: "600" }} variant={"head"}>
				{label}
			</TableCell>
			<TableCell align="right">{value}</TableCell>
		</TableRow>
	)
}
