import { CircularProgress } from "@mui/material"

import { ContainerPage } from "./loading.styles"

type PageLoadingProps = {
	isLoading: boolean
}

export function PageLoading({ isLoading }: PageLoadingProps) {
	if (!isLoading) return <></>
	return (
		<ContainerPage>
			<CircularProgress />
		</ContainerPage>
	)
}
