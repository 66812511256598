import { ResponseViewerCloseIcon } from "./close-icon"
import { ResponseViewerCodeBlock } from "./code-block"
import { ResponseViewerRoot } from "./root"
import { ResponseViewerSwipeableViews } from "./swipeable-views"
import { ResponseViewerTable } from "./table"
import { ResponseViewerTableRow } from "./table/row"
import { ResponseViewerTabs } from "./tabs"
import { ResponseViewerTabsPanel } from "./tabs/panel"
import { ResponseViewerTabsTab } from "./tabs/tab"

export const ResponseViewer = {
	Root: ResponseViewerRoot,
	CloseIcon: ResponseViewerCloseIcon,
	Tabs: ResponseViewerTabs,
	TabsTab: ResponseViewerTabsTab,
	TabsPanel: ResponseViewerTabsPanel,
	SwipeableViews: ResponseViewerSwipeableViews,
	CodeBlock: ResponseViewerCodeBlock,
	Table: ResponseViewerTable,
	TableRow: ResponseViewerTableRow,
}
