import { useEffect, useState } from "react"
import { Controller, useFieldArray, useFormContext } from "react-hook-form"

import { Add as PlusOneIcon, Remove as RemoveIcon } from "@mui/icons-material"
import {
	Button,
	FormControl,
	Grid,
	InputAdornment,
	InputLabel,
	MenuItem,
	Select,
	TextField,
} from "@mui/material"
import { t } from "i18next"

import { searchService } from "../../../apis/services"
import DialogAlert from "../../../components/dialog/dialog"
import { ContainerList } from "../../../components/product-select/product-select.styles"
import { ControlledTextField } from "../../../components/text-field/controlled-text-field"
import { CreateOrganizationData } from "../../../libs/zod/organization/create-organization"
import { ServiceBillingType } from "../../../models/Organization"
import SeccessOptionsSelector from "./success-options-selector"

export type CostRangeTableProps = {
	open: boolean
	serviceIndex: number
	handleAccept: () => void
}

export const CostRangeTable = ({ open, serviceIndex, handleAccept }: CostRangeTableProps) => {
	const [successBillingOptions, setSuccessBillingOptions] = useState<Array<{
		title: string
		id: string
	}> | null>(null)
	const {
		formState: { errors },
		getValues,
		setValue,
		control,
		watch,
	} = useFormContext<CreateOrganizationData>()

	const services = getValues("services")

	const { fields, append, remove } = useFieldArray({
		control,
		name: `services.${serviceIndex}.costRanges`,
	})

	const { append: appendDeleted } = useFieldArray({
		control,
		name: `services.${serviceIndex}.deletedCostRanges`,
	})

	const handleAdd = () => {
		const service = getValues(`services.${serviceIndex}`)
		const lastElement = service.costRanges[fields.length - 1]

		setValue(
			`services.${serviceIndex}.costRanges.${fields.length - 1}.usageEnd`,
			lastElement.usageStart + 1,
		)

		append({
			usageStart: lastElement.usageEnd + 1,
			usageEnd: service.unlimited ? Infinity : service.usageLimit,
			cost: lastElement.cost,
		})
	}

	const handleRemove = async (index: number) => {
		const service = getValues(`services.${serviceIndex}`)

		setValue(
			`services.${serviceIndex}.costRanges.${index - 1}.usageEnd`,
			service.unlimited ? Infinity : service.usageLimit,
		)

		if (service.costRanges[index]?.id)
			appendDeleted({
				...service.costRanges[index],
				deleted: true,
			})
		remove(index)
	}

	const showRemoveButton = (index: number) => {
		return index === fields.length - 1 && index !== 0
	}

	const showAddButton = (index: number) => {
		return index === fields.length - 1
	}

	const fetchSeviceDetails = async (serviceId: string) => {
		try {
			const {
				data: { data },
			} = await searchService(serviceId)
			setSuccessBillingOptions(data?.successBillingOptions || [])
		} catch (error) {
			console.log(error)
		}
	}

	useEffect(() => {
		if (services[serviceIndex]?.serviceId) {
			fetchSeviceDetails(services[serviceIndex]?.serviceId as string)
		}
	}, [serviceIndex])

	return (
		<DialogAlert
			onlyConfirm={true}
			show={open && serviceIndex !== undefined}
			accept={handleAccept}
			title={t("product_select.tier_prices_title", { service: services[serviceIndex]?.name })}
			description={t("product_select.tier_prices_description")}
		>
			<ContainerList>
				<Grid container spacing={2} style={{ width: "100%" }}>
					<Grid item xs={12} lg={6}>
						<FormControl color={"secondary"} variant="standard" style={{ width: "100%" }}>
							<Controller
								control={control}
								name={`services.${serviceIndex}.billingType`}
								render={({ field }) => (
									<>
										<InputLabel id="billing-type-label">
											{t("product_select.tier_prices_billing_type")}
										</InputLabel>
										<Select
											{...field}
											id="billing-type"
											labelId="billing-type-label"
											size={"small"}
										>
											<MenuItem value={"success"}>
												{t("product_select.tier_prices_billing_type_success")}
											</MenuItem>
											<MenuItem value={"request"}>
												{t("product_select.tier_prices_billing_type_request")}
											</MenuItem>
										</Select>
									</>
								)}
							/>
						</FormControl>
					</Grid>

					<Grid item xs={12} lg={6}>
						<FormControl color={"secondary"} variant="standard" style={{ width: "100%" }}>
							<Controller
								control={control}
								name={`services.${serviceIndex}.costRangeModel`}
								render={({ field }) => (
									<>
										<InputLabel id="cost-range-model-label">
											{t("product_select.tier_prices_type")}
										</InputLabel>
										<Select
											{...field}
											id="cost-range-model"
											labelId="cost-range-model-label"
											size={"small"}
										>
											<MenuItem value={"fixed"}>
												{t("product_select.tier_prices_type_fixed")}
											</MenuItem>
											<MenuItem value={"request"}>
												{t("product_select.tier_prices_type_processing")}
											</MenuItem>
										</Select>
									</>
								)}
							/>
						</FormControl>
					</Grid>

					{watch(`services.${serviceIndex}.billingType`) === ServiceBillingType.SUCCESS && (
						<Grid item xs={12} lg={12}>
							<SeccessOptionsSelector options={successBillingOptions} serviceIndex={serviceIndex} />
						</Grid>
					)}
				</Grid>
			</ContainerList>

			{fields.map((_, index) => (
				<Grid container style={{ margin: "16px 0", gap: 4 }} key={index}>
					<Grid item xs={2.5}>
						<ControlledTextField
							id={`services.${serviceIndex}.costRanges.${index}.usageStart`}
							control={control}
							label={t("product_select.tier_start")}
							disabled
							type={"number"}
							errors={(errors?.services as any)?.[serviceIndex]?.costRanges?.[index]?.usageStart}
						/>
					</Grid>
					<Grid item xs={2.5}>
						<ControlledTextField
							id={`services.${serviceIndex}.costRanges.${index}.usageEnd`}
							control={control}
							label={t("product_select.tier_end")}
							type={"number"}
							errors={(errors?.services as any)?.[serviceIndex]?.costRanges?.[index]?.usageEnd}
							disabled={index === fields.length - 1}
							beforeChange={(value: string) => {
								const nextIndex = index + 1
								if (fields[nextIndex]) {
									setValue(
										`services.${serviceIndex}.costRanges.${nextIndex}.usageStart`,
										+value + 1,
									)
								}
								return +value
							}}
						/>
					</Grid>
					<Grid item xs={3}>
						<Controller
							control={control}
							name={`services.${serviceIndex}.costRanges.${index}.cost`}
							render={({ field }) => (
								<TextField
									{...field}
									color="secondary"
									size="small"
									id="filled-basic"
									variant="filled"
									label={t("product_select.tier_price")}
									type={"number"}
									inputProps={{ step: "0.01" }}
									error={!!errors?.services?.[serviceIndex]?.costRanges?.[index]?.cost}
									helperText={errors?.services?.[serviceIndex]?.costRanges?.[index]?.cost?.message}
									onChange={(event) => {
										field.onChange(parseFloat(event.target.value).toFixed(2))
									}}
									InputProps={{
										startAdornment: <InputAdornment position="start">R$</InputAdornment>,
									}}
								/>
							)}
						/>
					</Grid>

					{showAddButton(index) && (
						<Grid item>
							<Button style={{ height: "100%" }} variant={"text"} onClick={handleAdd}>
								<PlusOneIcon />
							</Button>
						</Grid>
					)}

					{showRemoveButton(index) && (
						<Grid item>
							<Button
								style={{ height: "100%" }}
								variant={"text"}
								color={"error"}
								onClick={() => handleRemove(index)}
							>
								<RemoveIcon />
							</Button>
						</Grid>
					)}
				</Grid>
			))}
		</DialogAlert>
	)
}
