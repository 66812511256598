import styled from "styled-components"

import { maxDeviceSizeForMediaQueries } from "../../../styles/devices-medias"

export const Container = styled.div`
	width: 280px;
	height: calc(100% - 80px);
	overflow-y: scroll;
	scrollbar-width: none;
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	padding: 16px 0;
	box-sizing: border-box;
	flex-direction: column;
	background-color: ${(props) => props.theme.palette.background.default};
	border-right: 1px solid rgba(203, 203, 203, 0.3);
	position: fixed;
	margin-top: 80px;
	@media ${maxDeviceSizeForMediaQueries.tablet} {
		display: none;
	}
`
