import styled from "styled-components"

import { maxDeviceSizeForMediaQueries } from "../../../../../../styles/devices-medias"

export const Container = styled.div`
	width: 50%;
	background: ${(props) => props.theme.palette.primary.contrastText};
	border-left: 1px solid #e6e6e684;
	padding: 0px;
	box-sizing: border-box;

	@media ${maxDeviceSizeForMediaQueries.tablet} {
		width: 100%;
		position: absolute;
		right: 0;
		z-index: 100;
		height: 100%;
	}
`
