import { getItem } from "../../storage/local-storage"
import { Flags } from "./flags"
import flags from "./flags-value.json"

export const getFeatureFlag = (flagName: Flags) => {
	const flagValue = getItem(flagName)

	if (flagValue) return Boolean(flagValue)
	else return flags[flagName]
}
