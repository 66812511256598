export const ipStringToInt = (ip: string): number => {
	const parts = ip?.split(".")

	if (!parts) return 0

	return (
		parseInt(parts[0], 10) * 16777216 +
		parseInt(parts[1], 10) * 65536 +
		parseInt(parts[2], 10) * 256 +
		parseInt(parts[3], 10)
	)
}

export const isValidIPRange = (startIp: string, endIp: string): boolean => {
	const endIpValue: number = ipStringToInt(endIp)
	const starIpValue: number = ipStringToInt(startIp)

	return starIpValue <= endIpValue
}

export const isValidIP = (ip: string): boolean => {
	if (ip === "") return false
	const ipArray = ip.split(".")
	if (ipArray.length !== 4) return false
	return ipArray.every((octet) => {
		if (octet === "") return false
		const octetNumber = Number(octet)
		return octetNumber >= 0 && octetNumber <= 255
	})
}
