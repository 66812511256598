import { useContext } from "react"
import { useTranslation } from "react-i18next"
import Carousel from "react-material-ui-carousel"

import { Button, CardActions, CardContent, CardMedia, Typography } from "@mui/material"

import { GlobalContext } from "../../../contexts/global-provider"
import { ACTIONS_TYPES } from "../../../contexts/reducer"
import { getServicesDataByLanguage } from "../../../data/services"
import { buildMatrizArray } from "../../../utils/format"
import {
	Container,
	CardsServices,
	Header,
	Title,
	Line,
	ContainerCards,
	Card,
} from "./services-banner.styles"

export const ServicesBanner = () => {
	const { t, i18n } = useTranslation()

	const { dispatch } = useContext(GlobalContext)

	const selectProductMenu = (id: string) => {
		dispatch({ type: ACTIONS_TYPES.SET_HOME_PRODUCT_SHOW, value: id })
		handleBackToTop()
	}

	const handleBackToTop = () => {
		window.scrollTo({ top: 0, behavior: "smooth" })
	}

	const formatToCarrousel = () => {
		const service_language = getServicesDataByLanguage(i18n.language)
		return buildMatrizArray(service_language)
	}

	return (
		<Container>
			<Header>
				<Line />
				<Title>{t("home.we_solutions")}</Title>
				<Line />
			</Header>
			<CardsServices>
				<Carousel>
					{formatToCarrousel().map((e, index) => (
						<ContainerCards key={index}>
							<Card key={e[0].id}>
								{e[0].image ? (
									<CardMedia sx={{ minHeight: 200, maxHeight: 200 }} image={e[0].image} />
								) : (
									<></>
								)}
								<CardContent>
									<Typography color={"secondary"} gutterBottom variant="h5" component="div">
										{e[0].name}
									</Typography>
									<Typography variant="body2" color="text.secondary">
										{e[0].description}
									</Typography>
								</CardContent>
								<CardActions>
									<Button
										onClick={() => selectProductMenu(e[0].id)}
										color={"secondary"}
										size="small"
									>
										{t("home.more_information")}
									</Button>
								</CardActions>
							</Card>
							{e[1] ? (
								<Card key={e[1].id}>
									{e[1].image ? (
										<CardMedia sx={{ minHeight: 200, maxHeight: 200 }} image={e[1].image} />
									) : (
										<></>
									)}
									<CardContent>
										<Typography color={"secondary"} gutterBottom variant="h5" component="div">
											{e[1].name}
										</Typography>
										<Typography variant="body2" color="text.secondary">
											{e[1].description}
										</Typography>
									</CardContent>
									<CardActions>
										<Button
											onClick={() => selectProductMenu(e[1].id)}
											color={"secondary"}
											size="small"
										>
											{t("home.more_information")}
										</Button>
									</CardActions>
								</Card>
							) : (
								<></>
							)}
						</ContainerCards>
					))}
				</Carousel>
			</CardsServices>
		</Container>
	)
}
