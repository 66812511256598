import { ListItemText } from "@mui/material"
import { grey } from "@mui/material/colors"

interface SideMenuItemProps {
	text: string
}

export const SideMenuItemContent = ({ text }: SideMenuItemProps) => {
	return (
		<ListItemText
			primaryTypographyProps={{
				color: grey["800"],
			}}
			secondary={text}
		/>
	)
}
