import styled from "styled-components"

import { ReactComponent as ResolvOneLogo } from "../../assets/images/resolvone.svg"
import { maxDeviceSizeForMediaQueries } from "../../styles/devices-medias"

export const Container = styled.div`
	width: 100%;
	background: #dee3e3;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	padding: 64px 128px;
	box-sizing: border-box;

	@media ${maxDeviceSizeForMediaQueries.tablet} {
		flex-direction: column;
		justify-content: center;
		padding: 48px 16px;
	}
`

export const Information = styled.div`
	@media ${maxDeviceSizeForMediaQueries.tablet} {
		text-align: center;
	}
`

export const SocialMedia = styled.div``

export const Description = styled.p`
	color: #636364;
	width: 400px;
	margin: 16px 0 0 0;
	padding: 0;
	@media ${maxDeviceSizeForMediaQueries.tablet} {
		width: 300px;
	}
`

export const Logo = styled(ResolvOneLogo)`
	width: 150px;
`

export const IconsContainer = styled.div`
	display: flex;
	gap: 5px;
	cursor: pointer;
`

export const Title = styled.p`
	color: #636364;
	margin: 0 0 16px 0;
	@media ${maxDeviceSizeForMediaQueries.tablet} {
		margin-top: 32px;
		text-align: center;
	}
`
