import { Tab } from "@mui/material"

interface ResponseViewerTabsTabProps {
	value: string
	children: React.ReactNode
}

const ResponseViewerTabsTab = ({ children, ...props }: ResponseViewerTabsTabProps) => {
	return <Tab label={children} {...props} />
}

export { ResponseViewerTabsTab }
