import { Typography } from "@mui/material"
import styled from "styled-components"

type Props = {
	direction?: "row" | "column"
	flex?: number
	margin?: string
}

export const Container = styled.div`
	width: 100%;
	margin: 0px 0px;
`

export const ContainerAddiction = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
`

export const ContainerList = styled.div`
	display: flex;
	width: 100%;
	margin: 24px 8px 16px 0;
	padding: 16px 0;
	box-sizing: border-box;
	flex-direction: column;
	border-radius: 4px;
	padding: 24px;
	border: 1px solid #cfcfcf;
`

export const ListContainer = styled.div`
	border: 1px solid #cfcfcf;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin: 8px 0;
	padding: 8px 16px;
	border-radius: 5px;
`

export const ContainerSlider = styled.div`
	width: 95%;
	display: flex;
	flex-direction: row;
	background-color: ${(props) => props.theme.palette.background.default};
	padding: 10px;
	margin-top: 6px;
	align-items: center;
	justify-content: space-around;
	border-bottom: 1px solid #8c8c8c;
`

export const Grid = styled.div`
	display: flex;
	width: 100%;
	flex-direction: ${(props: Props) => (props.direction ? props.direction : "column")};
	flex: ${(props) => props.flex};
	margin: ${(props) => props.margin};
`

export const Link = styled(Typography)(({ theme }) => ({
	cursor: "pointer",
	"&:hover": {
		textDecoration: "underline",
		color: theme.palette.secondary.main,
	},
}))

export const RoundDelete = styled.div`
	justify-content: center;
	border-radius: 50px;
	padding: 3px 3px 0px 3px;
	margin-left: 5%;
	transition: background-color 0.3s ease-in-out;

	&:hover {
		background-color: #ff000024;
	}
`
