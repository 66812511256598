export const validateEmail = (email: string): boolean => {
	const emailRgx = /^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/
	return emailRgx.test(email)
}

export const validateUrl = (url: string): boolean => {
	const urlRgx =
		/^(https?:\/\/)?(www\.)?([a-zA-Z\d-]+\.){0,}[a-zA-Z]{2,}(\S*)?$|^(\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3})$/
	return urlRgx.test(url)
}

export const removeCharactersByObject = (object: any) => {
	const objectOrganization = {
		name: object.name,
		cnpj: object.cnpj,
		status: true,
		imageUrl: object.imageUrl,
		addressId: 1,
		billingEmail: object.billingEmail,
		address: {
			street: object.address.street,
			city: object.address.city,
			state: object.address.state,
			country: object.address.country,
			number: object.address.number,
			complement: object.address.complement,
			zipCode: object.address.zipCode,
			neighborhood: object.address.neighborhood,
		},
		selectedProducts: object.selectedProducts,
	}
	return objectOrganization
}
