import { useMemo } from "react"
import { useTranslation } from "react-i18next"

import { Divider, Grid } from "@mui/material"

import { GraphDatasetProps } from "../../../../@types/graphs/dataset"
import { GeneratedInvoiceService, InvoicingData } from "../../../../apis/invoicing"
import { GraphLineProps, Line } from "../../../../components/graphs/multi-axis/multi-axis"
import { PolarArea } from "../../../../components/graphs/polar-area/polar-area"
import { LoadingTable } from "../../../../components/loading/loading"
import { month } from "../../../../utils/date"
import { mountDatasetGraph } from "../../../../utils/graphs"
import { Center } from "../invoicing-details.styles"

interface InvoicingClassificationsProps {
	invoicing: InvoicingData | null
	isLoading?: boolean
	isPrinting?: boolean
}

export function InvoicingClassifications({
	invoicing,
	isLoading,
	isPrinting,
}: InvoicingClassificationsProps) {
	const { t } = useTranslation()

	const handleValue = (acc: any, [key, value]: any) => {
		const crr = acc[key as keyof typeof acc]
		if (!crr) return { ...acc, [key as keyof typeof acc]: Number(value) }
		return { ...acc, [key as keyof typeof acc]: Number(crr) + Number(value) }
	}

	const parseDataset = ([legend, value, array]: [legend: string, value: string, array: any[]]) => {
		if (!array.length) return {} as GraphDatasetProps
		const products = array.map((f) => [f[legend], f[value] ?? value])
		const data = products.reduce(handleValue, {})
		return mountDatasetGraph(data, true)
	}

	const consultationData = useMemo(() => {
		const response =
			invoicing?.monthlyData?.services?.map((service: GeneratedInvoiceService) => ({
				consultation_qty: service.total,
				service: service.name,
			})) || []

		return parseDataset(["service", "consultation_qty", response])
	}, [invoicing])

	const consultationFormData = useMemo(() => {
		const data = invoicing?.monthlyData?.totalByProcessingType
		const result = data
			? Object.keys(data).map((key) => ({
					processType: key,
					total: invoicing?.monthlyData?.totalByProcessingType[key],
				}))
			: []
		return parseDataset(["processType", "total", result])
	}, [invoicing])

	const servicesClassifications = useMemo(() => {
		return invoicing?.monthlyData.services.map((service) => {
			const result = service.totalByClassification
				? Object.keys(service.totalByClassification).map((key) => ({
						classification: key,
						total: service.totalByClassification[key],
					}))
				: []

			const datasets = new Map()
			Object.entries(service.dailyUsage).forEach(([date, value], index) => {
				const monthName = month.find(([key]) => key === Number(date?.split("-")?.[1]) - 1)
				Object.keys(value.classifications).forEach((key) => {
					const item = datasets.get(key)
					datasets.set(key, {
						label: key,
						data: [
							...(item?.data ?? []),
							[`${index + 1} - ${monthName?.[1] ?? ""}`, `${value.classifications[key]}`],
						],
					})
				})
			})
			const dailyData = Array.from(datasets.values()) as GraphLineProps[]

			return {
				service: service.name,
				data: parseDataset(["classification", "total", result]),
				dailyData,
			}
		})
	}, [invoicing])

	if (!isLoading && !consultationData.datasets && !consultationFormData.datasets) return <></>
	return (
		<>
			{servicesClassifications?.map(({ data, service, dailyData }, index) => (
				<Grid container columns={isPrinting ? 1 : 12} mb={4} key={index} component={"div"}>
					<Grid item xs={12} md={12} lg={5} key={index}>
						{!isLoading && Boolean(consultationData.datasets) ? (
							<PolarArea title={service} data={data} />
						) : (
							<Center>
								<LoadingTable />
							</Center>
						)}
					</Grid>
					<Grid item xs={12} md={12} lg={7}>
						<Line
							legend={dailyData.map(({ label, data }) => [
								label,
								`${data.reduce((acc, crr) => acc + Number(crr?.[1]), 0)} consultas`,
							])}
							title={t("consumption.invoicing.classifications.daily") as string}
							data={dailyData}
						/>
					</Grid>
					{index < servicesClassifications.length - 1 && (
						<Grid xs={12} my={4}>
							<Divider variant="middle" flexItem />
						</Grid>
					)}
				</Grid>
			))}
		</>
	)
}
