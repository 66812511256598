import { useContext, useEffect } from "react"
import { useNavigate } from "react-router-dom"

import { ContentBanner } from "../../components/banners/content-banner/content-banner"
import { Banner } from "../../components/banners/default/banner"
import { ServicesBanner } from "../../components/banners/services-banner/services-banner"
import { GlobalContext } from "../../contexts/global-provider"
import { useAuthentication } from "../../hooks/app/useAuthentication"
import { Container } from "./home.styles"

const Home = () => {
	const { productIdShow } = useContext(GlobalContext)
	const navigate = useNavigate()
	const { isAuthenticated, rootPath } = useAuthentication()

	useEffect(() => {
		if (isAuthenticated) navigate(rootPath)
	}, [])

	return (
		<Container>
			{productIdShow === 0 ? <Banner /> : <ContentBanner />}
			<ServicesBanner />
		</Container>
	)
}

export default Home
